#Home{


    #Stats{
        text-align: center;
        position:relative;
        z-index:1;
        background-color:$brand-gray-bg;
        padding-top:20px;
        h3{
            color:#8b8675;
            font-size:	1.500em;
            
            //font-family:$font-family-serif;
            //font-style:italic;
        }
    }

    .stats{
        display:flex;
        justify-content:center;
        flex-wrap: wrap;
        //font-size:12px;
        margin:20px auto 30px;
        .stat{

            display:flex;
            justify-content:flex-start;
            flex-wrap: wrap;
            align-items: baseline;

            padding: 3px 10px;
            width:49%;

            h4{
                font-size:2.75em;
                //font-family: $font-title-serif;
                //font-weight: 400;
                text-align: center;
                width: 100%;
                margin: 0;

                //@include fixFontNumbers();
            }
            p, a{
                font-size:1.0em;
                color:#8b8675;
                text-align: center;
                width: 100%;
            }
            a{
                &:hover{
                    color:#B3A062;
                }
            }

        }
        
        // 576 -> 767
        @include media-breakpoint-up(md) {
            display:flex;

            .stat{
                width:auto;
                padding:6px 10px;
                &:not(:last-child){
                    border-right:1px solid #d1c8b0;
                }
                &:first-child{
                    padding-left: 0;
                }
                h4{
                    margin-top: 11.5px;
                    margin-bottom: 11.5px;
                    width: auto;
                    text-align: left;
                    &#RanchCountUp{
                        width: 3.5em;
                    }
                    &#HomesCountUp{
                        width: 1.4em;
                    }
                    &#GlampingCountUp{
                        width: 1.4em;
                    }
                    &#BedsCountUp{
                        width: 1.8em;
                    }
                    &#ActivitiesCountUp{
                        width: 2em;
                        span{
                            //width:52px;
                        }
                    }
                    &#DaysCountUp{
                        width: 1.9em;
                    }
                }
                p, a{
                    text-align: left;
                    width:auto;
                }
            }


        }
        
        // 768 -> 991
        @include media-breakpoint-up(lg) {


        }

        // 992px -> 1200px
        @include media-breakpoint-up(xl) {

        }

        // 1440px +
        @include media-breakpoint-up(xl1440) {

            .stat{
                h4{
                    font-size:2.571em;
                }
            }

        }

    }

}