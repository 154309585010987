// TODO: this is using an old inline-block method. Once this is moved over to Bootstrap 4 let's just use inline helper classes and make it d-flex flex-wrap
#Resort{
	.inline-block-list{
		list-style:none;
		padding:0;
		margin:20px 0;
		li{
			display:inline-block;
			margin-right: 10px;
			margin-bottom: 8px;
			img{
				display:block;
				width:129px; height:auto;
				border: 4px solid #fff;
			}
		}
	}
	
	.pullquote{
		margin-left:auto;
		margin-right:auto;
		max-width:1170px;
	}
}

.group-app-btns{
	text-align: center;
	a{
		display:block;
		margin:20px 0;

		img{
			width:auto; height:auto;
			max-width:100%;
		}
	}

}



// 768 -> 991
//@media (min-width: $screen-sm-min) {
@include media-breakpoint-up(md) {
	.group-app-btns{

		a{
			display:inline-block;
			margin:0 30px;

			img{
				width:195px; height:59px;
			}
		}

	}


}