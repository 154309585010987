#HealthAndSafety{
    
    #ContentWrapper{
        section#Top{
            @include media-breakpoint-up(xl) {
                width: 1170px;
                padding-right:180px;
                padding-left:180px;

            }
            .main{
                color:$brand-gold;
                font-family:$headings-font-family;
                font-weight:$headings-font-weight;
            }
            aside{
                display:none;  
            }
        }
    }
    
    .gray-section-wood{
        padding:50px 0;
        .container, .standard-container.copy-block{

            @include media-breakpoint-up(xl) {
                padding-right:180px;
                padding-left:180px;

            }
        }
    }
    
    #Hero{
        .titleblock{
            text-align: center;
            width: 100%;
            .main-titles{
                &:after{
                   margin:18px auto; 
                }
            }
        }
    }
    
    .titleblock{
        text-align: center;
        
        h2:not(.no-bar):after{
            margin:18px auto;
        }

        h2 + h3:not(.no-bar):after{
            margin:18px auto;
        }
        
        h2 + h3{
            font-weight: 400;
        }
    }
    
    
    .standard-container.copy-block{
        
        p{
            font-size:13px; 
        }
        @include media-breakpoint-up(md) {
            padding-top:0;
        }
        @include media-breakpoint-up(xl) {
            width: 1170px;
            
            padding-right:240px;
            padding-left:240px;
            
        }
    }
}