// not to be confused with .titleblock
.block-title  {
/*    margin:15px 0;
    padding:15px 0;*/
    padding:30px 0;
    
    @include media-breakpoint-up(md) {
        
    }

    .titleblock{
        
    }
    
    + .block-copy{
        margin-top:-50px;
    }

    &.centered{
        text-align: center;

        h2,h3{
            margin-left:auto;
            margin-right:auto;
        }

        .titleblock{
            text-align: center;
        }
        
        .main-titles{
            @include titleUnderBar(50px,2px,true);
        }
    }
}