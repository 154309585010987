.new-design-2019{


    .breadcrumb-social  {
        padding-top: 10px;
        padding-bottom: 5px;



        .content{
            display: flex;
            flex-direction:column;
            justify-content: space-between;
            border-bottom:1px solid #ebe5d7;
            padding-bottom: 6px;
        }
        
        .crumbs{
            font-size: 0.857em;
            padding-bottom:10px;
            i,svg{
                margin:0 10px;
                color:$brand-gold;
            }
            
            span{
                color: #bfbbb2;
            }
            
        }

        .social-share{
            display: flex;
            align-items: flex-start;
            margin: 0 -4px;
            .addthis_inline_share_toolbox{
                margin-left: 0;
                float:none;
                width:auto;
            }
            .sharePage{
                float: none;
                width: auto;
                font-family:$font-family-sans-serif;
                border-radius: 2px;
                padding: 0 8px;
                background-color: #40525d;
                color: #fff;
                display: block;
                text-align: center;
                font-size:	0.714em;
                height: 20px;
                margin-left: 3px;
                &:hover, &:active{
                    background-color:lighten(#40525d,10%);
                    color: #fff;
                    div{
                        color:#fff;
                    }
                }
                i,svg{
                    font-size:inherit;
                    display:inline;
                    margin-right:3px;
                }


            }
        }

        @include media-breakpoint-up(lg) {
            .content{
                flex-direction:row;
                justify-content: space-between;
            }
            
            .crumbs{
                padding-bottom:0;
            }

            .social-share {
                margin: 0;
            }
        }


    }



}