.new-design-2019{

    // Used on /dining/outdoor-dining in the guests section
    .guest-group{
        padding-top:50px;
        padding-bottom:50px;
        padding-left: 0;
        padding-right:0;
        max-width: 1280px;
        margin:0 auto;

        .titleblock{
            margin-top:0;
            margin-bottom:2rem;
        }
        .guests{
            display:flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 0 2.5px;
            @include media-breakpoint-up(md) {
                padding: 0 5px;
            }
            @include media-breakpoint-up(xl1440) {
                padding:0;
            }
        }

        .guest{
            width:calc(50% - 5px);
            margin:2.5px;
            position:relative;
            border-radius: 2px;
            overflow: hidden;


            @include media-breakpoint-up(md) {
                width:calc(25% - 10px);
                margin:5px;
            }
            @include media-breakpoint-up(xl1440) {
                width:calc(25% - 10px);
            }

            .meta{
                padding:15px;
                text-align: center;
            }
            
            h4{
                font-size:0.857em;
                margin:0 0 5px;

                @include media-breakpoint-up(lg) {
                    font-size:1.0em;
                    margin:0 0 15px;
                }
                @include media-breakpoint-up(xl1440) {
                    font-size:1.143em;
                }
            }
            
            p{
                font-size: 11px;
                @include media-breakpoint-up(lg) {
                    font-size:1.0em;
                }
                @include media-breakpoint-up(xl1440) {
                    font-size:1.143em;
                }
            }
        }
    }
    
    
}