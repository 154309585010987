#Home{

    #Happenings{
        background-color:$body-bg;
        position: relative;
        .bins{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            max-width:1170px;
        }
        

        
        .bin{

            margin: 5px;
            max-width:calc(50% - 10px);
            
            @include media-breakpoint-up(sm) {
                max-width:270px;
            }
            
            .meta{
                height:auto;
                padding:10px;
                
                @include media-breakpoint-up(sm) {
                    height:125px;
                    padding: 30px 20px 10px;
                }
                
                h3{
                    font-size: 0.8em;
                    @include media-breakpoint-up(sm) {
                        font-size: 1em;
                    }
                }
                p{
                    font-size: 0.7em;
                    @include media-breakpoint-up(sm) {
                        font-size: 0.857em;
                    }
                }
            }
            .imgWrap{
                height: 90px;
                @include media-breakpoint-up(sm) {
                    height: 150px;
                }
                > img{
                    top:-12px;
                    @include media-breakpoint-up(sm) {
                        top:0;
                    }
                }
            }

        }

        
        

    }

    
    // 768 -> 991
    @include media-breakpoint-up(lg) {
        
        #Happenings{
            font-size:12px;
        }

    }
    
    // 992px -> 1200px
    @include media-breakpoint-up(xl) {


        #Happenings{
            font-size:14px;
            padding-top:70px;    
            padding-bottom:50px;    

            .pullquote{
                //margin:70px 0 0;
            }
        }
        
    }

    // 1440px +
    @include media-breakpoint-up(xl1440) {

        
    }
}