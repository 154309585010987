.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
	
	&.modal-dark{
		.close-btn{
			color:#fff;
		}

		.modal-container {
			background-color: #000;
		}
	}
	
	&.modal-full-screen{

		.modal-container {
			max-width: none;
			height: 100%;
		}
		
		.modal-body{
			height: 100%;
			width:100vw;
		}
		
	}

	.close-btn{
		position:absolute;
		top: -2px;
		right: 1px;
		background:none;
		padding: 1px 5px;
		font-size: 22px;
	}
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	max-width: 600px;
	margin: 0 auto;
	//padding: 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
	transition: all .3s ease;
	position: relative;
}

.modal-header {

	padding: 30px 15px 15px;
}

.modal-default-button {
	float: right;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}






#BookingPopUp{
	svg.logo{
		width:200px; height:48px;
		margin-bottom: 54px;
		.stlg0{
			fill:$brand-gold;
		}
	}
	padding:10px 15px 15px 30px;
	img{
		display:block;
		max-width:100%;
		margin-bottom:30px;
	}
	a{
		&.tel{
			font-size:34px;
			display:block;
			margin: 20px 0;
		}
		
		text-transform: none;
		&.btn{
			font-size:24px;
			
		}
	}

	&.schedule{
		h3{
			font-size: 20px;
			font-weight: 400;
			color: rgba(255,255,255,0.4);
		}
		ul{
			padding-left: 20px;
			li{
				font-size: 12px;
			}
		}
	}
}

// todo: let's organize this better maybe
#SubpagePopUp{
	
	padding:0 10px 0 0;
	@include media-breakpoint-up(lg) {
		
	}
	.titleblock{
		margin:0 0 30px;
		
		h1{
			font-size:2em;
		}

		h2{
			font-size:1.2em;
			color: #dad2be;
		}
		
		h3{
			color: #dad2be;
		}
	}
	
	p{
		line-height:1.3;
		font-size:1.000em;
		color: #dad2be;
	}
	
	svg.logo{
		width:200px; height:48px;
		margin-bottom: 23px;
		.stlg0{
			fill:$brand-gold;
		}
	}
	
	img{
		display:block;
		max-width:100%;
		margin-bottom:30px;
		margin-top:15px;
	}
	a{
		//font-size:42px;
		font-family: $font-title-sans;
		color:$brand-gold;
		&.btn{
			font-size:24px;
		}
	}

	&.schedule{
		h3{
			font-size: 20px;
			font-weight: 400;
			color: rgba(255,255,255,0.4);
		}
		ul{
			padding-left: 20px;
			li{
				font-size: 12px;
			}
		}
	}
}

#STFPopUp{
	padding:0 15px 15px 0;

	svg.logo{
		width:200px; height:48px;
		margin-bottom: 23px;
		.stlg0{
			fill:$brand-gold;
		}
	}
	
	#ThisIsPage{
		//background-color:rgba(255,255,255,0.04);
		//padding:4px 6px;
		font-family:$font-family-sans-serif;
		font-size:12px;
		color:#556b78
	}
	.content{
		.btn{
			margin-top: 30px;
		}
	}
	form{
		margin-top:20px;
	}
	.form-group {
		margin-bottom: 4px;
	}
	label{
		color:rgba(white,0.7);
		margin-bottom:1px;
		font-family: $font-title-sans;
		font-size: 1.2em;
		font-weight: 400;
	}

	hr{
		margin-top:15px; margin-bottom:15px;
	}

	.form-control{
		background-color:rgba(255,255,255,0.1);
		transition: all ease-in-out 0.15s;
		border:none;
		&:focus{
			background-color:rgba(255,255,255,1);
			//border-color: #fff;
			//box-shadow: 0 0 32px rgba(255, 255, 255, 1);
		}
	}

	#SendError{
		background-color: rgba(255, 68, 28, 0.7);
		color:#fff;
		padding:20px;
		margin-top:20px;
		display:none;

		#ErrorMsg{
			margin-top:20px;
			font-size:11px;
		}
	}

	#SuccessMsg{
		margin-top:20px;
		display:none;
		button{
			margin-top:30px;
		}
		h2{
			font-family:$font-title-sans;
			font-weight:700;
		}
	}

	p.disclaimer{
		font-size:11px;
		color:rgba(255,255,255,0.6);
		font-style:italic;
		margin-top:14px;
	}
}

// todo: new popup modal for the RESERVE global nav button
.bookNowModal{

	&__wrap{
		> img {
			width:50%;
		}
		> div{
			width:50%;
			padding:2rem;
		}
	}

	p{
		font-size:0.875rem;
	}

}