
.inclusions-block {
    padding-top:50px;
    padding-bottom:50px;
/*    .titleblock{
        margin-top:0;
        h2{
            margin-top:0;
        }
    }*/

    .caption{

        color:$brand-gold;
        text-align: center;
        margin:15px 0 0;

        p{
            margin-bottom:0;
        }
    }

    hr{
        border-top: 1px solid #2b281d;
    }
    .rate-card{
        > img{
            max-width:300px;
        }
        .meta{
            .right{
                > img{
                    max-width:130px;
                }
            }
        }
        .disclaimer{
            hr{
                margin-top:5px;
                margin-bottom:5px;
                border-color:#e7e5dc;
            }
        }
    }
    &.advanced{


        ul{
            margin-left: 0;
            padding-left: 0;
        }

        hr{
            border-top:none;
            height:1px;
            background: linear-gradient(to right,
                    rgba($brand-gold,0.0) 0%,
                    rgba($brand-gold,0.2) 20%,
                    rgba($brand-gold,0.2) 80%,
                    rgba($brand-gold,0.0) 100%);
        }

        .rate-card{
            margin-top: 50px;
        }

        .inclusions-list{

            //display:flex;

            text-align: center;


            i,svg{
                font-size: 14px;
                //margin-right:5px;
                display:block;
                margin: 0 auto 5px;
                color:rgba($brand-gold,80%);
            }

            .fa-check{

            }

            > li{
                position: relative;
                &:before{
                    content:"";
                    position:absolute;
                    top:0;
                    left:50%;
                    transform:translateX(-50%);
                    display:block;
                    width:1px;
                    height:calc(100% + 40px);
                    background: linear-gradient(0deg, rgba($brand-gold,0.0) 0%, rgba($brand-gold,0.2) 90%, rgba($brand-gold,0.0) 100%);
                    margin:0 auto;
                }

                > ul{
                    margin-top:0;
                    /*position: relative;
                    &:before{
                        content:"";
                        position:absolute;
                        top:-30px;
                        left:50%;
                        transform:translateX(-50%);
                        display:block;
                        width:1px;
                        height:calc(100% + 90px);
                        background: linear-gradient(0deg, rgba($brand-gold,0.0) 0%, rgba($brand-gold,0.2) 90%, rgba($brand-gold,0.0) 100%);
                        margin:0 auto;
                    }*/

                    /* li{
                         &:before{
                             content:"";
                             display:block;
                             width:1px;
                             height:20px;
                             background-color:rgba($brand-gold,0.2);
                             margin:0 auto;
                         }
                         
                         &:last-child{
                             &:after{
                                 content:"";
                                 display:block;
                                 width:1px;
                                 height:10px;
                                 background: linear-gradient(0deg, rgba($brand-gold,0.0) 0%, rgba($brand-gold,0.2) 100%);
                                 margin:0 auto;
                             }
                         }
                     } */

                }
            }

            @include media-breakpoint-up(lg) {

                margin-top: 20px;
                padding:0 20px;
                //flex-wrap: wrap;

                > li{
                    //width:calc(50% - 10px);

                    &:not(:last-child){
                        margin-bottom:10px;
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                font-size:1.286em;
                margin-bottom: 20px;
                padding:0 60px;
                > li{
                    &:not(:last-child){
                        margin-bottom:30px;
                    }
                }
            }
        }
    }
}

.inclusions-list{

    font-size:1.0em;
    line-height: 1.2;
    margin-left: 1.6em;
    margin-top: 20px;
    margin-bottom: 50px;

    > li{

        &:not(:last-child){
            margin-bottom:15px;
        }
        i,svg{
            font-size:12px;
        }

        p{
            font-size:18px;
            margin-bottom:0;
            a{
                color: #8c8363;
                text-decoration: underline;
                &:hover{
                    color: #d0c8a7;
                }
            }
        }

        > ul{
            margin-top:15px;
            list-style: none;

            > li{
                font-size:75%;
                margin-top: 20px;
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-up(lg) {

        margin-top: 40px;

        > li{
            &:not(:last-child){
                margin-bottom:20px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        font-size:1.286em;
        margin-bottom: 20px;

        > li{
            &:not(:last-child){
                margin-bottom:40px;
            }
        }
    }

}