#Activities{

    #LeftRightSchedulesBg{
        h2{

            @include media-breakpoint-up(xl1440) {
                font-size:1.675em;
            }
        }
    }
    
    .kids-corp-schedule-glance{
        display:flex;
        flex-wrap: wrap;
        margin:20px -10px 0;
    
        
        .item{
            width:calc(33% - 20px);
            margin:10px;
            border:1px solid rgba($brand-gold, 0.33);
            background-color:rgba(white,0.33);
            padding:15px;
            border-radius: 3px;
            display:flex;
            align-items: center;
            transition:all 0.3s;
            
            i,svg{
                font-size:30px;
                margin-right:10px;
            }
            
            div.title{
                font-size:16px;
            }
            
            &:hover{
                background-color:rgba(white,0.65);
                border:1px solid rgba($brand-gold, 0.6);
            }
        }
    }
    
}