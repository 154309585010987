#Home{
    
    #WelcomeMsg{
        //background:white url('/storage/static/home/m-welcome-msg-bg.jpg') no-repeat bottom center;
        background-size: cover;
        background-position:bottom center;
        text-align: center;
        position: relative;
        padding:70px 0 36px;
        font-size: 10px;
        @include media-breakpoint-up(md) {
            padding:20px 0 36px;
        }
        @include media-breakpoint-up(lg) {
            //background:white url('/storage/static/home/welcome-msg-bg-1920.jpg') no-repeat bottom center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color:white;
            padding:30px 15px 60px;
            font-size: 0.875rem;

            &.winter{
                /*
                background:white url('/storage/static/home/welcome-msg-winter-bg.jpg') no-repeat bottom center;
                */
                background-size: cover;
            }
        }
        
        &.spring{
            background-position:top center;
            h2{
                color:#223348;
                span{
                    color: #223348;
                }
            }
            p{
                color: #182d47;
            }
        }


        img{
            width:34px; height:37px;
        }

        h2{
            text-align: center;
            font-size: 1.875rem;
            line-height:1;
            margin-top:10px; margin-bottom: 10px;
            position: relative;
            letter-spacing: 0.4px;
            color:#42555e;
            
            @include media-breakpoint-up(lg) {
                font-size: 1.5rem;
            }
            
            @include media-breakpoint-up(lg) {
                font-size: 2.625rem;
            }
            
            span{
                font-size:  0.375rem;
                color: #8b98a3;
                letter-spacing: 1px;
                margin:10px 0;
                display:block;
                @include media-breakpoint-up(lg) {
                    font-size: 0.5625rem;
                }

                /*@include media-breakpoint-up(lg) {
                    font-size:  0.375rem;
                }*/
            }
        }
        p{
            color:#2a373e;
            font-size:1.143em;
            margin-top:10px;
            @include media-breakpoint-up(lg) {
                padding:0 15%;
                margin-top:30px;
                color: #4f6874;
            }
        }

        #Abe{
            text-align: center;
            padding-top: 250px;

            @include media-breakpoint-up(lg) {
                padding-top: 360px;
            }
            
            p{
                font-family:$headings-font-family;
                font-weight: $headings-font-weight;
                font-size:1.125rem;
                color:#fff;
                letter-spacing:0.5px;
                line-height:1;
                position:relative;
                display:inline;
                padding:0;
                @include media-breakpoint-up(lg) {
                    font-size: 0.75rem;
                }
                
                span{
                    color:rgba(255,255,255,0.9);
                    font-size:0.750em;
                    position:absolute;
                    bottom:-20px;
                    //left: 50%;
                    transform: translateX(-50%);
                    @include media-breakpoint-up(lg) {
                        left:auto;
                        right:0;
                        transform:none;
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }

        

    }


    // 576 -> 767
    @include media-breakpoint-up(md) {

    }

    // 768 -> 991
    @include media-breakpoint-up(lg) {

        #WelcomeMsg {

            h2 {
                font-size: 2.1em;
                span {
                    position: absolute;
                    font-size: 0.30em;
                    margin:0;
                    bottom: 13px;
                    right: -46px;
                }

            }

        }

    }

    // 992px -> 1200px
    @include media-breakpoint-up(xl) {
        
        #WelcomeMsg {

            h2 {

                span {
                    bottom: -16px;
                    right: 150px;
                }
            }

        }
        
    }

    // 1440px +
    @include media-breakpoint-up(xl1440) {


    }
}