.new-design-2019{

    // todo: this looks dead but is referenced in the new rates overview page.......
    .standard-container{
        max-width: 1440px;
        @include media-breakpoint-up(md) {
            padding: 30px 30px;
        }
        @include media-breakpoint-up(lg) {
            padding: 40px 30px;
        }
        @include media-breakpoint-up(xl) {
            padding: 50px 80px;
        }
        @include media-breakpoint-up(xl1440) {

            width: 1440px;
            margin:0 auto;
        }
    }
    
    
    
    
    .package-rate-block{
        padding:30px 0;
        article{
            margin-top:0;
        }
    }
    
    


    
    .callout{
        padding: 12px 20px;
        .flex-center-up{
            display:flex;
            justify-content: center;
            align-items: center;
            max-width: 1200px;
            margin: 0 auto;
            line-height: 1.2;
        }
        
        p{
            margin-bottom:0;
        }
        
        i,svg{
            position:relative;
            font-size: 1.6rem;
        }
        
    }

    .block-divider{
        
        &.bottom-edge{
            position:relative;
            margin-top: -20px;
            hr{
                margin-top:0;
                margin-bottom:0;
                height:20px;
                position:relative;
                background-color:white;
                z-index: 1;
            }
            .container{
                position:relative;
                background-color:white;
                z-index: 1;
            }
            &:after{
                content:"";
                display:block;
                position:relative;
                top:-20px; left:0;
                width:100%;
                height:40px;
                background: radial-gradient(ellipse at center, rgba(34, 30, 22, 0.05) 0%, rgba(34, 30, 22, 0) 80%, rgba(34, 30, 22, 0) 100%);
            }
        }
    }
    
    
    

}