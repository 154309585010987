/*@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500,600,700&display=swap');*/
//@import 'https://cloud.typography.com/6741734/7240552/css/fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@import "variablesB4";
//@import "variables";

// Mixins
@import "_mixins/mixinsb4";

// Vendors
@import "~bootstrap/scss/bootstrap";
//@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~slick-carousel/slick/slick.scss";
@import "vendor/slick-carousel/slick-theme";
@import "~photoswipe/src/css/main.scss";
@import "~photoswipe/src/css/default-skin/default-skin.scss";
@import "vendor/bootstrap-offcanvas/bootstrap.offcanvas";
/*@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/brands";*/
//@import "~@fortawesome/fontawesome-pro/scss/duotone";


// SPECIAL REMOVE LATER:
//@import "sections/new-design-2019/events/black-friday/black-friday";


@import "base";

@import "_partials/shared-components"; // shared with admin tools
@import "_partials/components";
@import "_partials/bins";
@import "_partials/popups";
@import "_partials/header";
@import "_partials/footer";
@import "_partials/mobile-menu";

//@import "_partials/events";
//@import "_partials/rightnav";
//@import "_partials/standard";
//@import "_partials/wide";
//@import "_partials/categorypage";
//@import "_partials/mobile-utility-footer";


@import "_partials/nav-drawers";

@import "_partials/takeovers";

@import "_partials/related-bins";

@import "_partials/breadcrumbs";

@import "_partials/hero-slider";


/*
---------------------------------------
PAGES
---------------------------------------
 */

@import "sections/home/home";


/*
---------------------------------------
VERIFIED
---------------------------------------
 */

@import "sections/friends-of-the-ranch/base";
@import "sections/press/press-awards";
@import "sections/groups/groups";
@import "sections/the-resort";
@import "sections/new-design-2019/templates/health-and-safety";
@import "sections/rates";
@import "sections/new-design-2019/events/itinerary";
@import "sections/wallpapers";



/*
---------------------------------------
VERIFIED WITH ACTION NEEDED WHEN BOOTSTRAP 4
---------------------------------------
 */
/*
---------------------------------------
DEPRECATED
---------------------------------------
 */
//@import "sections/spa";
//@import "sections/activities";
//@import "sections/the-ranch";
//@import "sections/events/event-page";
//@import "sections/groups";
//@import "_partials/thegreeno";
//@import "_partials/insiders";
//@import "sections/groups2/home";
//@import "sections/groups2/weddings/gallery";
//@import "sections/new-design-2019/events/guests";
//@import "sections/new-design-2019/events/gallery";
//@import "_deprecated/sections/new-design-2019/settings/blocks/page-nav-block";

/*
---------------------------------------
NEED TO VERIFY
---------------------------------------
*/

@import "sections/new-design-2019/new-design-2019";
@import "sections/new-design-2019/partials/reservations";
@import "sections/new-design-2019/partials/breadcrumb-social";
@import "sections/new-design-2019/partials/rate-card";
@import "sections/new-design-2019/partials/downloads";
@import "sections/new-design-2019/partials/guest-groups";


@import "sections/new-design-2019/events/overviews";
@import "sections/new-design-2019/events/wheres";
@import "sections/new-design-2019/accommodations/accommodations";
@import "sections/new-design-2019/activities/filtering";
@import "sections/new-design-2019/activities/descriptions";
@import "sections/new-design-2019/activities/special";

@import "sections/new-design-2019/rates/special-offers";
@import "sections/new-design-2019/resort/the-resort";
@import "sections/new-design-2019/templates/category";


// BLOCKS
@import "sections/new-design-2019/partials/blocks";
@import "sections/new-design-2019/partials/blocks/title-block";
@import "sections/new-design-2019/partials/blocks/copy-block";
@import "sections/new-design-2019/partials/blocks/html-block";
@import "sections/new-design-2019/partials/blocks/video-block";
@import "sections/new-design-2019/partials/blocks/super-gallery";
@import "sections/new-design-2019/partials/blocks/rate-table";
@import "sections/new-design-2019/partials/blocks/image-block";
@import "sections/new-design-2019/partials/blocks/itinerary-block";
@import "sections/new-design-2019/partials/blocks/inclusions-block";
@import "sections/new-design-2019/partials/blocks/popup-nav-block";








@import "_partials/global-modal";




// 5px : 0.3125rem
// 6px : 0.375rem
// 8px:  0.5rem
// 9px:  0.5625rem
// 10px: 0.625rem
// 11px: 0.6875rem
// 12px: 0.75rem
// 13px: 0.8125rem
// 14px: 0.875rem
// 15px: 0.9375rem
// 16px: 1rem
// 17px: 1.0625rem
// 18px: 1.125rem
// 19px: 1.1875rem
// 20px: 1.25rem
// 21px: 1.3125rem
// 22px: 1.375rem
// 23px: 1.4375rem
// 24px: 1.5rem
// 26px: 1.625rem
// 28px: 1.75rem
// 29px: 1.8125rem
// 30px: 1.875rem
// 32px: 2rem
// 34px: 2.125rem
// 35px: 2.1875rem
// 36px: 2.25rem
// 38px: 2.375rem
// 39px: 2.4375rem
// 40px: 2.5rem
// 41px: 2.5625
// 42px: 2.625rem
// 46px: 2.875rem
// 48px: 3rem
// 50px: 3.125rem
// 55px: 3.4375rem
// 60px: 3.75rem
// 76px: 4.75rem
// 80px: 5rem
