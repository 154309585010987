section.descriptor{

    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    display:flex;
    align-items: center;
    flex-direction: column;
    padding-top:20px;
    padding-bottom:20px;
    
    &.right{
        justify-content: flex-end;
        background-position: right top;

        @include media-breakpoint-up(xl) {
            background-position: left top;
        }
    }
    
    .meta{

        
        h3{
            
        }
        
        @include media-breakpoint-up(md) {

            max-width:67%;
            
        }

        @include media-breakpoint-up(lg) { //992

            max-width:50%;
            
        }
        @include media-breakpoint-up(xl) { //1200
            max-width:40%;
        }
    }
    @include media-breakpoint-up(md) { //768
        flex-direction: row;
        padding-top:50px;
        padding-bottom:50px;
        height: 380px;
    }
    @include media-breakpoint-up(lg) { //992

        padding-top:30px;
        padding-bottom:30px;
        height: 380px;
    }
    @include media-breakpoint-up(xl) { //1200
        height: 430px;
    }
}