#Wallpapers {

    h2{
        color: #818181;
        font-family: $font-family-base;
        font-weight: 400;
        font-size:1.25rem;
        text-align: center;
        max-width: 180px;
        margin:0 auto 4rem;
    }

    a[download]{
        text-align: center;
        p{

        }
    }

    img{
        width: auto;
        height:420px;
    }

}