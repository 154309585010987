.navbar{
	border:none;
}
.offcanvas-stop-scrolling{
	#chat-widget-container{
		display:none;
	}
}

// b4
button.navbar-toggle{
	position:fixed;
	right:0; top:0;
	margin-right:0;
	margin-top: 0.5rem;
	margin-bottom: 5px;
	z-index:12;
	background-color: transparent;
	padding: 0.5rem 1rem;
	border: 1px solid transparent;

	.icon-bar{
		display: block;
		background: #fff;
		transition: all .25s ease-in-out;
		width: 30px;
		height: 2px;
		border-radius: 1px;

		+ .icon-bar {
			margin-top: 4px;
		}
	}
	&.active{
		background-color: #181610;
		border-radius: 0.5rem;
	}
}
$stickyNavTranSpeed: 0.1s;
$stickyNavColorBase: #6e613f;
nav{
	a.tele{
		color:#e1c88b;
		font-size:1rem;
		margin-top:0.5rem;
		white-space: nowrap;
		@include headlineFont();
		@include media-breakpoint-up(xl) {
			margin-top:0;
			margin-right:1rem;
			font-size:1.0rem;
		}
		@media (min-width: 1500px) {
			margin-right:2rem;
		}
	}

	a.check-availability{
		color: #e1c88b;
		padding:1rem 1.5rem;
		background: none;
		letter-spacing: 0;
		font-size:0.75rem;
		font-weight: 700;
		border: 2px solid #e1c88b;
		border-radius:0.25rem;
		text-transform: uppercase;
		white-space: nowrap;
		transition:all $stickyNavTranSpeed ease;



		@include media-breakpoint-up(xl) {
			padding: 0.5rem 0.75rem;
			font-size: 0.825rem;
			letter-spacing: 0.05rem;

		}
		@include media-breakpoint-up(xl1440) {
			padding: 0.5rem 1.125rem;


		}
		@media (min-width: 1500px) {
			padding: 0.5rem 1.25rem;
		}
		&:hover{
			color:#fff;
			background-color:$stickyNavColorBase;
			border-color:$stickyNavColorBase;

		}
	}
}



header{
	
	font-family: $font-family-sans-serif;
	position: fixed;
	z-index: 9;
	width:100%;
	top:0; left:0;

	@include media-breakpoint-up(xl) {
		padding-bottom: 0;
		position:absolute;
		top:0; left:0; right:0;
		z-index: 1;
	}

	&:before{
		content:"";
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:150px;

		//background:linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);

		@include scrimGradient( 'to bottom', black );
		opacity:0.75;

		@include media-breakpoint-up(xl1440) {
			height:280px;
		}
	}

	nav.navbar {
		display:block;
		background-color: transparent;
		height: 100vh;
		transition: all $stickyNavTranSpeed ease;

		@include media-breakpoint-up(xl){
			position: relative;
			padding: 0.875rem 1rem 0;
			height: auto;
			z-index: 1;
		}
		@include media-breakpoint-up(xl1440) {
			padding: 2rem 1rem 0;
		}

		.main-nav-wrapper{
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			max-width: calc(1600px + 0.5rem);
			margin:0 auto;
			transition: all $stickyNavTranSpeed ease;

			@include media-breakpoint-up(xl) {
				align-items: center;
				border-radius: 3rem;
				padding: 0.25rem;

			}
		}

		.navbar-right{
			display: flex;
			justify-content: flex-end;
			flex-direction: column-reverse;
			align-items: center;
			margin-left:1rem;
			@include media-breakpoint-up(xl) {
				margin-top:0;
				flex-direction: row;
				justify-content: flex-end;
			}
			@include media-breakpoint-up(xl1440) {

			}

		}


		.logo{
			display: flex;
			//margin-right:0.5rem;
			width:calc(117px + 0.5rem);
			transition: all $stickyNavTranSpeed ease;
			@media (min-width: 1500px) {
				//margin-right:2rem;
				width:calc(138px + 2rem);
			}

			a{
				position: relative;
				height:auto;
				padding:0;
				display:inline-block;
				margin-right:0;

				svg {
					display:block;
					width: auto; height:38px;
					transition:all $stickyNavTranSpeed ease;

					@include media-breakpoint-up(xl1440) {
						height:38px;
					}
					@media (min-width: 1500px) {
						height:45px;
					}
					&:first-child{
						fill:#fff;

					}

					&:last-child{
						fill: #9f8d62;
						position: absolute;
						top:0;
						left:0;
						opacity: 0;
					}
				}

				svg:first-child {

					/*.stlg0{
						fill:#9F8D62;
						transition:fill 0.2s;
					}*/
				}


			}

		}

	}

	&.scrolling{
		//padding-top:40px;
	}


	.sticky, .vue-position-sticky{

		nav.navbar{
			//box-shadow: 0 2px 10px rgba(black,0.05);

			@include media-breakpoint-up(xl){
				//background-color: rgba(#1b1812,0.97);
				//background-color: rgba(#fff,1);
				padding: 1rem 1rem 0.5rem;
			}
			@include media-breakpoint-up(xl1440) {
				padding: 1.5rem 1rem 0.5rem;
			}

			a.tele {
				color: $stickyNavColorBase;
			}

			a.check-availability {
				color: $stickyNavColorBase;
				border-radius: 3rem;
				//border: 2px solid $stickyNavColorBase;
				background-color:#f5f2eb;
				border-color:transparent;

				&:hover{
					color: white;
					background-color:#4a4535;
				}

				@include media-breakpoint-up(xl) {
					padding: 0.25rem 0.75rem;
					height:38px;
				}
				@include media-breakpoint-up(xl1440) {
					padding: 0.25rem 1.125rem;

				}
				@media (min-width: 1500px) {
					padding: 0.25rem 1.25rem;
				}
			}

			.logo{

				@include media-breakpoint-up(xl) {
					width:38px;
					//margin-right:0;
				}
				@include media-breakpoint-up(xl1440) {

				}

				a svg{


					&:first-child{
						//fill: $stickyNavColorBase;
						opacity: 0;
					}

					&:last-child{
						opacity: 1;
					}

					@include media-breakpoint-up(xl) {
						height:38px;
					}
					@include media-breakpoint-up(xl1440) {

					}
				}
			}

			.main-nav-wrapper{

				@include media-breakpoint-up(xl) {

					max-width: calc(1340px + 0.5rem);

					box-shadow: 0 2px 10px rgb(0 0 0 / 15%), 0 0 30px rgb(159 141 98 / 30%);
					background-color: rgba(#fff,1);


					width:100%;
					top:0; left:0;
					z-index: 10;

					border-color: #000000;


				}
				@include media-breakpoint-up(xl1440) {

				}



				/*.request-brochure, .link{
                    display:none;
                }

                .collapsed-nav-content-left{
                    display: flex;

                }

                .crumbs{
                    display: block;
                }*/
			}
		}

		#Nav{
			.dropdown-navigation {
				@include media-breakpoint-up(xl) {
					&:hover {
						&:not(.active){
							> a{
								color:$brand-gold;
							}

						}
						.navigation-submenu {
							&:before {
								height: 2rem;
								top: -2rem;
							}
						}
					}
				}

				> a {
					color:$stickyNavColorBase;
				}

				&:hover{

					> a {
						color:$stickyNavColorBase;

					}

					.navigation-submenu{

						margin-top: 5.0rem;

					}
				}

			}

		}



	}

	
	/*button.navbar-toggle{
		z-index: 1;
	}*/

	#MobileToolbar{
		height:48px;
		width:100%;
		padding:0.5rem 1rem;
		text-align: center;
		display: flex;
		justify-content: flex-start;
		align-items:center;
		position: relative;
		@include media-breakpoint-up(md) {

		}
		a{
			svg{
				width:auto; height:28px;
				fill:#fff;

				@include media-breakpoint-up(md) {
					height:45px;
					margin-top:0.5rem;
				}

			}
		}
	}

	/*.collapsed-nav-content-left{
		display: none;
		align-items: center;
		flex-basis: 45%; //35%
		.collapsed-nav-logo{
			margin-right:15px;
			svg{
				width:40px; height:40px;
			}
		}
		.crumbs{
			display: block;
			font-size: 	0.857em;
			color:#6b644f;
			line-height: 1;
			@include media-breakpoint-up(lg) {
				font-size: 1.071em;
			}
			a{
				white-space: nowrap;
				color:#b8a67d;
				&:hover{
					color:lighten(#b8a67d,30%);
				}
			}
			i,.slash{
				margin:0 5px;
			}

			span{
				//color: #bfbbb2;
				white-space: nowrap;
			}
		}

	}
	*/
	.crumbs{
		display: none;
	}
	
	/*#GlobalTools{

		display:flex;
		flex-direction: column;
		//border-bottom:1px solid #eee;
		transition:all 0.2s;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
			justify-content: space-between;
			//background-color:white;
			height: 40px;
		}
		@include media-breakpoint-up(lg) {
			padding-bottom: 0;
			
		}
		@include media-breakpoint-up(xl) {
			
		}
		a{
			line-height: 1;
			//text-transform: uppercase;
		}

		.links{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			border-bottom: 1px solid #eee;
			padding: 8px 10px;
			margin-top: 0;
			margin-bottom: 0;

			@include media-breakpoint-up(md) {
				flex-direction: row;
				align-items: center;
				display:block;
				border-bottom: none;
				padding: 0;
				background-color: transparent;
				margin-bottom: 0;
			}

			li{
				text-align: center;
				display:block;
				margin: 4px 0;
				@include media-breakpoint-up(lg) {
					margin: 0;
					display: inline-flex;
					align-items: center;
					justify-content: flex-start;
					height: 100%;
					&:not(:last-child){
						a{
							border-right:1px solid rgba(black, 0.1);
						}
					}
				}
			}

			a{
				font-size:0.6875rem;
				//letter-spacing:0.11em;
				padding:6px 6px;
				display:block;
				color:$brand-gold;
				
				@include buttonFont();
				
				i,svg{
					color:$brand-gold;
					margin-right:0.25rem;
				}
				@include media-breakpoint-up(lg) {
					//color:#697980;
					//font-size:0.6875rem;
					transition:color 0.2s;
					padding:2px 4px;
					&:hover{
						color: darken($brand-gold, 10%);
					}
				}

				@include media-breakpoint-up(xl) {
					padding:2px 12px;
				}
			}

		}
		
		.contacts{
			display: flex;
			flex-direction: column;
			text-align: center;
			margin:0;
			padding: 8px 10px;
			list-style: none;
			@include media-breakpoint-up(md) {
				flex-direction: row;
				align-items: center;
				padding: 0 2px;
				margin-bottom: 0;
			}
			@include media-breakpoint-up(xl) {
				margin-top: 0;
			}
			li{
				position: relative;
				display: inline-block;
				text-align: center;
				margin: 4px 0;
				@include media-breakpoint-up(md) {
					margin: 0;
					&.chat, &.phone{
						a{
							color:$brand-gold;
							font-family: $font-family-sans-cond;
							font-weight: $font-family-sans-cond-weight;
							position: relative;
							top: -1px;
						}
					}
				}
				@include media-breakpoint-up(lg) {
					display:inline-block;
				}
				@include media-breakpoint-up(xl) {
					float: left;
					display: block;
				}
				
				> a{
					position: relative;
					display: block;
				}

				&.book-now{
					display:block;
					a{
						@include buttonFont();
						font-size:1.071em;
						padding:15px 24px;
						color:#fff;
						background: $brand-gold;
						i,svg{
							top:0;
						}
					}

					&:hover{
						a{
							//background: linear-gradient(to right, rgba(77,97,109,1) 0%,rgba(79,104,114,1) 100%);
							background:$brand-brown;
						}
					}
					@include media-breakpoint-up(md) {
						display: inline-block;
						padding: 6px 2px;
						a{
							padding: 8px 12px;
						}
					}
					@include media-breakpoint-up(lg) {
						a{
							padding: 8px 38px;
						}
					}
				}

				&.social{
					display:none;
					a{
						padding:6px 7px;
					}
					@include media-breakpoint-up(lg) {
						display:inline-block;
					}
				}
			}

			a{
				font-size:1.675em;
				font-family: $font-title-sans;
				font-weight: 400;
				padding:10px;
				letter-spacing:0.043em;
				color: #394957;
				i,svg{
					font-size:0.900em;
					margin-right:0.300em;
					position:relative;
					top:-2px;
				}
				&:hover{
					color:$brand-gold;
				}
				@include media-breakpoint-up(md) {
					font-size:1.286em;
					padding: 10px 14px;
					color:rgb(105, 121, 128);
					transition:color 0.2s;

					i,svg{
						font-size:0.900em;
						margin-right:0.300em;
						position:relative;
						top:-2px;
					}

					&:hover{
						color:$brand-gold;
					}
				}
				@include media-breakpoint-up(lg) {
					padding:6px 14px;
					i,svg{
						top:-2px;
					}
				}
				@include media-breakpoint-up(xl) {
					padding: 6px 18px;
				}
			}
		}
		
		.dropdown-toggle{
			
			> img{
				width: 30px;
				height: auto;
			}
			
		}
		
		.dropdown{

			&.open{
				.navigation-submenu{
					max-height: none;
					display:block;
				}
			}
		}
		
		.navigation-submenu{
			background-color:#fff;
			border: 1px solid #ddd;
			box-shadow: 0 6px 12px rgba(0,0,0,.175);
			
			display:none;
			li{
				float: none;
				a{
					color:#42555e;
					padding: 16px 18px;
					font-size: 1.125em;
				}
			}
		}
	}*/

	/*.navbar-header{
		//float:none;
		//height:auto;
		text-align: center;
		padding: 20px 0;
		margin-bottom: -7px;
		@include media-breakpoint-up(md) {
			padding-bottom:0;
			margin-bottom: -40px;
		}
	}
*/

	#Nav{
		//border-bottom:1px solid transparent;
		padding-left: 0;
		padding-bottom: 60px;
		margin-bottom:0;
		list-style: none;
		text-align: center;

		// 576 -> 767
		@include media-breakpoint-up(sm) {}

		// 768 -> 991
		@include media-breakpoint-up(xl) {
			//background-color: #fff;
			transition:background-color $stickyNavTranSpeed;
			float:none;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			//padding-top: 40px;
			padding-left: 0;//30px;
			padding-bottom: 0;
			&.no-animation{
				.navigation-submenu{
					transition: none;
				}
			}
		}

		// 992px -> 1200px
		@include media-breakpoint-up(lg) {

		}

		.dropdown-navigation {
			//the nav label
			border-bottom: 1px solid #f0f0f0;

			> a{
				display: block;
				font-size:18px;
				font-weight: 700;
				font-family: $headings-font-family;
				text-align: center;
				padding:1rem 0 1rem;
				line-height: 1;
				color: #fff; //#5b5445;
				text-transform: uppercase;
				@include media-breakpoint-up(md) {
					position: relative;
					padding:0.625rem 0.5rem;
					font-size:0.6875rem;
				}
				@include media-breakpoint-up(lg) {
					padding:0.625rem 0.625rem;
					font-size: 0.6875rem;
				}
				@include media-breakpoint-up(xl) {
					padding:0.625rem 0.875rem;
					letter-spacing: 0.05rem;
				}
				@include media-breakpoint-up(xl1440) {
					padding:0.625rem 1rem;
					font-size: 0.75rem;
				}
				span{
					pointer-events: none;
				}
				&:hover{
					color:$brand-gold;
				}

			}

			&.active:not(.open){
				//border-bottom: 1px solid #c7bda5;
				background-color:#f0f0f0;
				@include media-breakpoint-up(md) {
					background-color:transparent;
				}
				> a{
					background-color:transparent;
					//color: inherit;
					//font-weight: 700;
					span{
						padding-bottom: 13px;
						@include media-breakpoint-up(md) {
							border-bottom:2px solid  #a1916a; //#597582;
						}
					}

				}
			}

			// 768 -> 991
			@include media-breakpoint-up(md) {

				position:static;
				display:inline-block;
				//padding-top: 40px;
				padding:0 ;
				border-bottom:none;

				&:hover:not(.active){
					//background-color: #221e16;
					&.active:not(.open){
						//	background-color: #221e16;
					}
					> a{
						color: #fff;
					}
					&.active{
						> a{
							color: #fff;
							span{
								border-bottom:none;
							}
						}
					}

					svg.arrow{

						@include media-breakpoint-up(md) {
							display: block;
						}
					}

					.navigation-submenu {
						max-height: none;
						padding: 12px 0 5px;
						overflow-y: auto;
						-webkit-overflow-scrolling:touch;
						//background-color: rgba($brand-brown, 1);
						position: fixed;
						top: 0;
						width: 100%;
						//height:auto;
						left:0;
						z-index:1001;

						&:before{
							content:"";
							background-color:transparent;
							height:3rem;
							width:100%;
							position: absolute;
							left:0;
							top:-3rem;
						}

						@include media-breakpoint-up(sm) {
							display:block;
							//max-height: 790px;
							position: absolute;
							//top: 7rem ;
							width: 100%; height:auto;
							left: 50%;
							//overflow-y: visible;
							overflow: visible;
							margin-top:7rem;

						}
						@include media-breakpoint-up(md) {
							//height: 520px;
							//max-height: 790px ;
							//left:50%;
							//transform:translateX(0) ;

						}
					}
				}
			}

			// 992px -> 1200px
			@include media-breakpoint-up(xl) {
				padding:0 ;

			}

			svg.arrow{
				width:60px;
				display: none;
				position: absolute;
				bottom: -2.7rem;
				left: 50%;
				transform: translateX(-50%);
			}


			&.open{
				svg.arrow{

					@include media-breakpoint-up(md) {
						display: block;
					}
				}
				> a{
					&:hover{
						color:#fff;
					}
				}
				.navigation-submenu {

					max-height: none;
					overflow-y: auto;
					-webkit-overflow-scrolling:touch;
					background-color: rgba(#12100c, 1);
					position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					transform:translateX(0);
				}
			}
		}

		.navigation-submenu{
			display:none;
			position: absolute ;
			overflow: hidden;
			transition: all .15s ease;

			transform:translateX(-50%) ;
			top: auto;
			left: 50%;
			min-width: 10rem;
			max-width:1600px;
			width:100%;
			margin: 0 0 0;
			font-size: 0.875rem;
			color: #212529;
			text-align: left;
			padding: 0;
			box-shadow:none;
			border: none;
			list-style: none;
			background-clip: padding-box;
			background-color: rgba(#12100c, 1);
			background-size:cover;
			background-repeat: no-repeat;
			background-position:top left;
			border-radius: 0.25rem;


			@include media-breakpoint-up(md) {



			}

			/*.pullquote{
				font-family: $font-family-base;
				font-size:0.875em;
				letter-spacing: 0.05em;
				color:#6b5e45;
				padding-left:0;
				margin:40px 0 10px 0;
				&:before{
					opacity:0.1;
					font-size: 104px;
					top: 5px;
					left: -42px;
				}

				&.centered{
					margin: 30px 30px 10px;
					max-width: 730px;
					@include media-breakpoint-up(md) {
						margin: 30px auto 10px auto;
					}
				}

				span{
					font-size:0.85em;
					margin-top: 5px;
					display: block;
					color:#524835;
				}

				@include media-breakpoint-up(md) {
					font-size:1em;
				}
			}*/
		}







	}
	
	/*.mobile-navs{
		position: relative;
		
		.mobile-menu{
			width:calc(100% - 54px);
			position: fixed;
			top:0; left:0;
			z-index: 1000;
			
			overflow: hidden;
			transition: all .15s ease;
			
			background-color: rgba($brand-brown, 1);
			padding:0 0 80px;
			
			overflow-y: scroll;
			height: 100vh;

			transform:translateX(-100%);
		}

		.pullquote{
			font-family: $font-family-base;
			font-size:0.875rem;
			letter-spacing: 0.05em;
			color:#6b5e45;
			padding-left:0;
			margin:40px 0 10px 0;
			&:before{
				opacity:0.1;
				font-size: 104px;
				top: 5px;
				left: -42px;
			}

			&.centered{
				margin: 30px 30px 10px;
				max-width: 730px;
				@include media-breakpoint-up(md) {
					margin: 30px auto 10px auto;
				}
			}

			span{
				font-size:0.85em;
				margin-top: 5px;
				display: block;
				color:#524835;
			}

			@include media-breakpoint-up(md) {
				font-size:1rem;
			}
		}
		
		.navigation-submenu{
			width:calc(100% - 50px);
			
			// 414 -> 767
			@media (min-width: 414px) {
				//width:332px;
			}
		}
		
		.active{
			transform:translateX(0);
		}

		.close-menu{
			color:#fff;
			font-size:1.2em;
			font-family:$font-title-sans;
			//margin:0 0 30px;
			display:flex;
			align-items:center;
			position: relative;
			z-index: 2000;
			height: 48px;
			border-bottom: 1px solid #352f23;
			background-color: #28231a;
			i,svg{
				width:20px;
				text-align: center;
			}
			!*@include media-breakpoint-up(md) {
				display:none;
			}*!
		}
		
		
		
		@include media-breakpoint-up(lg){
			display:none;
		}
	}*/
}

