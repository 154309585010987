#Groups2{
    
    .pricing{
        background-color:$brand-gray-bg;
        padding-bottom:30px;

        @include media-breakpoint-up(md) {
            background-color: transparent;
        }
        
        .content{
            display:flex;
            flex-direction: column;
            margin:0 -5px;
            //padding:30px 0;
            align-items: stretch;
            
            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }
            
            .info{
                margin:0 5px;
                box-shadow:0 0 30px rgba(black,0.05);
                background:white;
                
                @include media-breakpoint-up(lg) {
                    width: calc(50% - 10px);
                }
                
                .header{
                    background-color:hsl(41, 17%, 83%);
                    font-size:1.714em;
                    font-weight:500;
                    padding:20px 25px;
                    line-height: 1;
                    font-family: $headings-font-family;
                }
                
                .body{
                    
                    padding:25px;

                    p{
                        line-height: 1.2;
                    }

                    h3{
                        font-size:	1.429em;
                        margin-top:0;
                    }
                }

                table.table{
                    margin:0 0 15px ;
                    border-bottom: 1px solid #ddd;
                    th{
                        font-family: $headings-font-family;
                        font-size:	1.429em;
                        font-weight:500;
                    }
                    th,td{
                        padding:8px 25px;
                    }
                    td{
                        > div{
                            display:flex;
                            align-items: center;
                            span{
                                font-size:	1.143em;
                                line-height: 1.2;
                            }
                            i,svg{
                                margin:10px 12px 10px 0;
                                font-size:1.571em;
                            }
                        }
                    }

                }
            }

     
            
        }
        
    }
    
}