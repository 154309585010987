.new-design-2019{
    
    #Downloads{
        background-color:#fff;
    }
    .downloads{
    
        max-width: none;  
        padding-top:30px;
        
        h3{
            font-size:1.714em;
            text-align: center;
            margin-bottom:30px;
        }

        .content{
            display:flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding:0 0 60px;

            @include media-breakpoint-up(md) {

            }

            @include media-breakpoint-up(lg) {

            }
        }

        .download{
            margin:0 5px 10px;
            color:$brand-text-brown;
            display:flex;
            background:white;
            box-shadow:0 0 30px rgba(black,0.05);
            height: 130px;
            width:300px;

            @include media-breakpoint-up(lg) {
                margin:0 5px 10px;
                flex-direction:column;
                max-width:200px;
                height: auto;
                width: auto;

            }
            
            img{
                width: 55%;
                height: 100%;
                @include media-breakpoint-up(lg) {
                    width: 100%;
                    height: auto;
                }
            }
            
            &.landscape{
                @include media-breakpoint-up(lg) {
                    .meta{
                        min-width:0;
                        min-height:0;
                    }
                }
            }

            &:hover{
                color:$brand-gold;
            }
            .meta{
                background:white;
                padding:15px;
                display:flex;
                flex-direction: column;
                justify-content: center;
                @include media-breakpoint-up(lg) {
                    min-width:175px;
                    min-height:126px;
                    justify-content: space-between;
                }
            }
            h5{
                font-size:0.857em;
                @include media-breakpoint-up(lg) {
                    font-size:1.0em;
                }
            }
            p{
                font-size:0.75em;
                line-height:1;
                margin-top:-11px;
            }
            i,svg{
                font-size:0.857em;
                @include media-breakpoint-up(lg) {
                    font-size:1.714em;
                }
            }
            &.reverse-label{
                .cta{
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                }
            }
            .cta{
                display:flex;
                align-items: center;
                span{
                    margin-left:5px;
                    font-size: 0.8em;
                
                }
            }

        }
        
        
    }



}