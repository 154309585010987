.new-design-2019{
 
    .rate-card{
    
        display:flex;
        justify-content: center;
        
        @include media-breakpoint-up(lg) {
            max-width: 944px;
            margin: 0 auto;
        }
        
        .imgWrap{
            width:400px;
            max-width: 400px;
            overflow: hidden;
            background: white;
        }
        
        img{

        }

        
        .meta{
            background-color:white;
            color:$brand-text-brown;
            padding:15px;
    
            display:flex;
            flex-direction:column;
            justify-content: center;
            
            .content{
                display:flex;
                align-items: center;
            }
            
            .left{
                max-width: 400px;
            }
            
            h3{
                font-size:1.429em;
                line-height: 1;
                margin-top:0;
                margin-bottom:-2px;
                
                &.smaller{
                    font-size: 1.129em;
                    @include media-breakpoint-up(lg) {
                        font-size:1.786em;
                    }
                }
    
                @include media-breakpoint-up(lg) {
                    font-size:2.286em;
                }
            }
    
            .date{
                font-size:0.714em;   
                margin-bottom:15px;
            }
            
            a.btn{
                i,svg{
                    margin-left:5px;
                }
            }
            
            .rate{
                
                .pre{
                    font-size: 0.786em;
                }
                .pricing{
                    display:flex;
                    
                    flex-direction: column;
                    margin-bottom:15px;
                    @include media-breakpoint-up(lg) {
                        flex-direction: row;
                        align-items: center;
                    }
                    .p{
                        font-size:2em;
                        font-family:$headings-font-family;
                        line-height: 1;
                        margin-right:4px;
                    }
                    .d{
                        font-size:0.786em;
                        color:$brand-gold;
                        line-height: 1;
                    }
                }
            }
            
            hr{
                border-top: 1px solid #f6f3e7;
            }
            
            @include media-breakpoint-up(lg) {
    
                width:calc(100% - 400px);
                
                padding:30px 30px 5px;

    
    
                .date{
                    font-size:1em;
                }
                
            }
        }
        
        .right{
            margin-left:30px;
            img{
                max-width: 130px;
            }
        }
    
        .disclaimer{
            font-size:	0.714em;
            color:$brand-gold;
            margin-top:10px;
            line-height: 1;
    
            @include media-breakpoint-up(lg) {
                font-size:0.786em;
            }
        }
        
        
        
        
    }

}