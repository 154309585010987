
.breadcrumb {

    font-size:12px;
    font-family: $font-family-sans-serif;
    margin-left: 0;
    padding:0;
    border-bottom:1px solid #f7f1e2;
    color:#bfbbb2;

    display:flex;
    align-items: center;
    justify-content: space-between;
    
    > div{
        display:flex;
        align-items: center;
    }
    
    .desktop{
        display:none;
    }
    
    a:not(.leftNavBtn):not(.rightNavBtn){
        color:#54472b;
        text-decoration: none;

        &:hover{
            color:$link-hover-color;
        }
    }

    i,svg{
        margin:0 10px;
        color: $brand-gold;
    }
    .crumbs{
        //width: calc(100vw - 90px);
        padding-right: 10px;
        a,span{
            white-space: nowrap;
            display:none;
            &:first-child{
                display:inline;

            }
        }
        i,svg{
            display:none;
        }
    }

    + .titleblock{
        margin-top:44px;
    }

    .leftNavBtn{
        text-decoration: none;
        border:1px solid #ebe5d7;
        border-bottom:none;
        background-color:#fff;
        height:44px;
        width:90px;
        display:block;
        box-sizing: border-box;
        transition:background-color 0.3s linear;
        margin-right: 10px;

        svg{
            position:relative;
            top:9px;
            left:13px;
            width:6px; height:24px;
            .cls-1-menu-dots{
                fill:#9F8D62;
            }
        }
        span{
            color:$brand-gold;
            font-size:13px;
            font-weight: 700;
            position:relative;
            left: 20px;
        }

        &:hover{
            background-color:#e1eef7;

            svg{
                .cls-1-menu-dots{
                    fill:#54472b
                }
            }
            span{
                color:#54472b
            }

        }
    }


    @include media-breakpoint-up(md) {

        margin-left: -38px;
        align-items: center;
        .crumbs{
            width: auto;
            padding-right: 0;
            
            a,span{
                display:inline;

            }
            i,svg{
                display:inline-block;
            }
        }
        
        
    }

    @include media-breakpoint-up(xl) {
        
        
        .rightNavBtn{
            display:none;
        }
        .desktop{
            display:block;
        }
        .mobile{
            display:none;
        }
    }
}