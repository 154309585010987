.titleblock{
    //margin-top:20px;
    //margin-bottom:40px;
    
    @include media-breakpoint-up(xl) {

        text-align:left;
        //margin-top:50px;
        //margin-bottom:40px;

    }
    &:not(.no-bar){

        .main-titles{
            @include titleUnderBar(50px,2px,false);
        }

    }
    &.centered{
        text-align: center;

        h2,h3{
            margin-left:auto;
            margin-right:auto;
        }

        .main-titles{
            @include titleUnderBar(50px,2px,true);
        }
    }
    
    

    &.subTitleMode{
        margin-top: -10px;
    }

    // todo:this is probably not used....
    /*.left{
        float:left;
        width:556px;
        padding-right:25px;

        @include media-breakpoint-up(xl) {
            float:left;
            width:556px;
            padding-right:25px;
        }
    }*/
    
    .date{
        margin-top: 10px;
    }
    
    .date-marker{
        float:right;
    }
    
    h1,h2,h3,h4,h5,h6{
        margin-top:0;
    }
   
    
    h1{
        font-size:1.5em;
        
        @include media-breakpoint-up(xl) {
            font-size: 2.25rem;
        }

        sup{
            font-size: 0.35em;
            position: relative;
            top: -1.172em;

            @include media-breakpoint-up(xl) {
                font-size: 0.35em;
                top: -1.172em;
            }
        }

        /*&.title{
            margin:1.429em 0;
        }*/
        
        + h2{
            
            //margin-top:0;
            //margin-bottom:0.500em;
            font-size:1.0em;
            
            @include media-breakpoint-up(xl) {
                font-size:	1.143em;
                //margin-bottom:0.500em;
            }
            
            + h3{
                //margin-top:-0.286em;
                //margin-bottom:2.143em;
                //font-family: $font-family-sans-serif;
                font-size:1.0em;
               // font-weight: 400;
                color:#4a4535;
                //@include titleUnderBar();

                @include media-breakpoint-up(xl) {
                  //  margin-top:-0.286em;
                   // margin-bottom:2.143em;
                }
                
                &.location{
                    color: #b0a180;
                    text-transform: uppercase;
                    font-size: 0.367em;
                    font-family: $font-title-sans;

                    @include media-breakpoint-up(xl) {
                        font-size: 0.367em;
                    }
                }
            }
        }
    }

    h2{

        font-size:1.75em;
        margin-bottom:0.200em;
/*        &:not(.no-bar){
            @include titleUnderBar();
        }*/

        
        + h3{
            margin-top:0;
            margin-bottom:0.500em;
           // font-family: $font-family-sans-serif;
            font-size:1.143em;
            //font-weight:700;
            
            @include media-breakpoint-up(xl) {
                font-size:1.188em
            }
            
            /*&:not(.no-bar){
                @include titleUnderBar();
            }*/
            
            + h4{

                font-family: $font-family-sans-serif;
                font-weight:400;
                font-size:0.9em;
/*                &:not(.no-bar){
                    @include titleUnderBar();
                }*/
            }
        }
        
        

    }

    .category-title{
        font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 0.9em;
        color: $brand-gold;
        margin-top:0;

        @include media-breakpoint-up(xl) {
            font-size: 1.0em;
        }
        
        a{
            
        }
        
        sup{
            font-size: 0.318em;
            position: relative;
            top: -1.745em;

            @include media-breakpoint-up(xl) {
                font-size: 0.318em;
                top: -1.745em;
            }
        }

        + h1{
            @include media-breakpoint-up(xl) {
                //margin-top:-0.257em; 
            }
        }
        
        +h1, +h2{
            //margin-top:-0.257em;
        }

        
    }

    h4{
        font-family: $font-family-serif;
        font-size:1.286em;
        font-weight:400;
        color:#4a4535;
        margin-bottom:0.571em;
        //@include fixFontNumbers();

        @include media-breakpoint-up(xl) {
            font-size:1.286em;
            margin-bottom:0.571em;
        }
    }

    .extMessage{
        display: inline-block;
        font-size:1.0em; // 18px
        font-family:$font-title-sans;
        font-weight: 400;
        padding:3px 10px 6px;
        margin-top:0;
        line-height:1;
        border: 1px solid #d0d8e1;
        border-radius:2px;
        background-color:#e8f1f6;
        color:#42413f;
    }

    
}


#Preview .date, .titleblock .date{
    display: inline-block;
    font-size:1.0em; // 18px
    //font-family: $font-family-sans-cond; 
    //font-weight: 400;
    //text-transform:uppercase;
    padding: 3px 10px 6px;
    margin-top: 0;
    line-height: 1;
    /* letter-spacing: 1px; */
    border-radius: 2px;
    /* background-color: #42413f; */
    color: #2b2a29;
    border: 1px solid #e6e3da;
}

.collateralBin{
    text-align: left;
    text-decoration: none;
    display: block;
    margin: 20px 0;
    background-color: #fff;
    border-radius: 2px;
    overflow: hidden;
    border-bottom: 1px solid #e6e3db;
    img{
        width: 100%;
        height: auto;
        max-width: 100%;
        display: block;
    }
    .meta{
        padding: 30px 30px 10px;
        height: 125px;
        position: relative;
        h3{
            font-size: 1.286em;
            font-weight: 700;
            font-family: "rift", "Helvetica Neue", Helvetica, Arial, sans-serif;
            margin: 0 0 10px;
            color: #444a4f;
            line-height: 1;
        }
        i,svg{
            display:block;
            font-size:24px;
        }
    }
}


.rate-table{
    margin: 60px 0;
    border-radius: 2px;
    box-shadow: 0 0 80px 0 rgba(162, 145, 106, 0.25);

    thead{
        background-size:cover;
        background-color: $brand-brown-bg;
        color: #dad2be;
        position: relative;
        line-height: 2;
        img{
            position: absolute;
            left: 0;
            top: -46px;
            width: 100%;
            height: auto;
            opacity: 0.6;
        }
    }
    td{
        p{
            font-size:1em;
            margin-bottom:0;
        }
    }
    th.head{
        font-size:1.5em;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        position:relative;
        overflow: hidden;
        span{
            position:relative;
            z-index:2;
        }
    }
}

$binMobileThumbWidth:85px; //140
$binMobileLandscapeThumbWidth: 124px;
$binMobilePortraitThumbWidth: 64px;

.bin{
    
    .footer{
        //color: #62605e;
        color:rgba(#4a4535,0.6);
        position:absolute;
        width: calc(100% - #{$binMobileThumbWidth });

        left: $binMobileThumbWidth;
        font-family:$headings-font-family;
        font-weight:$headings-font-weight;
        font-size:9px;
        line-height:1;
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 10px;
        bottom: 0;
        border-top: 1px solid rgba(#b3aa8f,0.15);

        &.no-content{
            border-top-color:transparent;

        }


        .icons{
            display:none;
            font-size:	1em;
            color:rgba(#4a4535,0.6);
            i,svg{
                margin-left:0;
                &:hover{
                    color:$brand-text-brown;
                }
            }

            @include media-breakpoint-up(lg) {
                //display:block;
            }

        }

        .age{
            color: #4a4535;
        }

        @include media-breakpoint-up(lg) {
            //position: relative;
            width: 100%;
            font-size:11px;
            padding: 10px 20px;
            left: 0;
            //bottom: 0;
        }

        @include media-breakpoint-up(xl) {

        }
    }
}