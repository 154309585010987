.new-design-2019{
    
    #Itinerary{
        
        &.compact{
            .heading, .grouping .heading{
                font-size:	1.125em;
                padding: 7px 10px;

                @include media-breakpoint-up(lg) {
                    padding:8px 20px;
                    font-size:	1.0em;
                }
            }
            .row{
                margin-right:-5px;
                margin-left:-5px;
            }
            .col-md-4,.col-lg-2{
                padding-left:5px;
                padding-right:5px;
                
                margin-bottom:10px;
            }
            .col-lg-12{
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        
        padding-bottom:50px;
        
        .day{
            background-color:white;
        
            box-shadow:0 0 10px rgba(black,0.05);
            
            margin-bottom:10px;

            @include media-breakpoint-up(lg) {
                margin-bottom:0;
            }
        }
        
        .heading{
            //background-color:#cfc8b9;
            background-color:#fff;
            font-size:	1.125em;
            color:#1e1c15;
            font-family: $headings-font-family;
            padding: 7px 10px;
            border-bottom:1px solid #f2f1ef;

            @include media-breakpoint-up(lg) {
                padding:15px 20px;
                font-size:	1.286em;
            }
        }
        
        .grouping{
            .heading{
                background-color:#f5f4f1;
                font-size:	1.3em;
                @include media-breakpoint-up(lg) {
                    font-size:	1.286em;
                }
            }
            
            .item{
                padding: 7px 10px;
                display:flex;
                line-height: 1.2;
                
                &:not(:last-child){
                    border-bottom:1px solid #f2f1ef;
                }
                i,svg{
                    margin-right:7px;
                    color:$brand-gold;
                    padding-top: 4px;
                }

                @include media-breakpoint-up(lg) {
                    padding:15px 20px;
                }
            }
        }
        
    }

    #ItineraryCalendar{
        background-size:contain;
        background-position:top center;
        background-repeat: no-repeat;
        background-color: #faf8f2;
        padding-bottom:50px;
        
        
        .calendar-wrap{
            display:flex;
            flex-wrap:wrap;
        }

        

        .titleblock{
            margin-top: 40px;
        }
        
        .day{
            
            &.blank{
                background-color: rgba(white,0.3);
               // border:1px solid rgba($brand-gold,0.2);
                box-shadow: none;
                
                .heading{
                    color: rgba($brand-gold,0.6);
                    border-bottom-color:transparent;
                }
            }
            
            &.week-day{
                background-color:transparent;
                border:none;
                box-shadow: none;
                
                .heading{
                    color: rgba(darken($brand-gold,30%),1);
                    font-size:1em;
                    padding-left:0;
                }


            }
            
            &.fade25{
                opacity:0.25;
                display:none;
                @include media-breakpoint-up(lg) {
                    display:block;
                }
            }

            &.fade50{
                opacity:0.5;
                display:none;
                @include media-breakpoint-up(lg) {
                    display:block;
                }
            }

            &.fade75{
                opacity:0.75;
                display:none;
                @include media-breakpoint-up(lg) {
                    display:block; 
                }
            }
            
            &.fade100{
                display:none;
                @include media-breakpoint-up(lg) {
                    display:block;
                }
            }
            
            background-color:white;
            box-shadow:0 0 10px rgba(black,0.05);
            margin:0 2px 10px;
            width:100%;

            @include media-breakpoint-up(md) {
                margin:0 2px 4px;
                width:calc((100% / 3) - 4px);
            }
            
            @include media-breakpoint-up(lg) {
                margin:0 2px 4px;
                width:calc((100% / 7) - 4px);
            }
        }

        .heading{
            //background-color:#cfc8b9;
            font-size:	1.0em;
            color:#4a4535;
            font-family: $headings-font-family;
            font-weight: 500;
            display:flex;
            align-items: center;
            justify-content: space-between;
            padding: 7px 5px;
            //border-bottom:1px solid rgba($brand-gold,0.2);
            
            .special{
                background-color:#d5edf9;
                color: #546f7d;
                border-radius: 4px;
                padding:2px 6px;
                font-family: $font-family-sans-cond;
                font-weight: 400;
                font-size:12px;
                display:inline-block;
            }
            
            @include media-breakpoint-up(lg) {
                padding:5px 8px;
                font-size:	1.5em;
                span{
                    display:none;
                }
            }
        }

        .grouping{
            border-top:1px solid rgba($brand-gold,0.15);
            .heading{
               // background-color:#f5f4f1;
               // font-size:	1.3em;
                font-size:	0.75em;
                font-family: $font-family-sans-cond;
                font-weight: 400;
                color: #A2916A;
                border:none;
                @include media-breakpoint-up(lg) {
                    //font-size:	1.286em;
                }
            }

            .item{
                display:flex;
                line-height: 1.2;
                font-size:	0.75rem;
                font-family: $font-family-sans-cond;
                font-weight: 400;
                margin: 4px;
                padding: 4px;
                
                background-color: rgba(162, 145, 106, 0.1);
                border-radius: 12px;
                
                &:not(:last-child){
                    //border-bottom:1px solid #f2f1ef;
                }
                a{
                    color: #4a4535;
                    i,svg{
                        color: #8c8363;
                        font-size:0.75em;
                    }    
                }
                
                i,svg{
                    margin-right:7px;
                    color:$brand-gold;
                    padding-top: 2px;
                }

                @include media-breakpoint-up(lg) {
                    padding: 6px;
                    font-size:	0.875rem;
                }
            }
        }

    }
}