
.bin{
	text-align: left;
	text-decoration: none;
	display:block;
	margin:20px 0;
	background-color:#fff;
	border-radius: 2px;
	overflow: hidden;
	border-bottom: 1px solid #e6e3db;
	
	.imgWrap{
		
		overflow:hidden;

		
		> img {

			transition:transform 2s ease, opacity 1s;

			&.lazy-hidden, &.lazyload, &.lazy {
				opacity: 0;
				//background: #eee url('/css/loading.gif') no-repeat 50% 50%;
			}

			&.lazyloading {
				background: #eee url('/css/loading.gif') no-repeat 50% 50%;
			}
			
			&.lazy-loaded, &.lazyloaded {
				//transition: opacity 0.3s;
				opacity: 1;
			}

		}
	}
	
	img{
		width:100%; height:auto;
		max-width: 100%;
		display: block;
	}
	
	.meta{
		//padding:30px 30px 10px;
		padding:15px;
		height:125px;
		position: relative;

		> i:not(.static),svg:not(.static){
			position:absolute;
			bottom:10px; right:10px;
			opacity:0;
			//color:darken($brand-primary,10%);
			font-size:1.429em
		}
		
		i.static,svg.static{
			opacity: 1;
			color: #4a4535;
			font-size:0.857em;
			@include media-breakpoint-up(lg) {
				position:absolute;
				font-size:1.714em;
				bottom:20px;
				left:20px;
			}
		}

		a.tele{
			font-family:$font-title-sans;
			font-size:1.286em;
			font-weight: 700;
		}
		a.email{
			font-family:$font-title-sans;
			font-size:1.286em;
			font-weight: 700;
		}

		&.auto-height{
			height:auto;
		}
		
		@include media-breakpoint-up(xl) {
			padding:30px 20px 10px;
		}
	}

	.footer{
		
		// NOTE: REMOVED FOR UNIFIED EVENT/BIN WITH NEW ADMIN BLOCKS. MIGHT
		// CAUSE ISSUES WITH OLDER BINS......
		
		//color:#787878;
		//padding:9px 30px;
		
    }
    
	h3{
		font-size: 12px;
		margin: 0 0 10px;
		color:$brand-text-brown;
		line-height:1.1;
		
		@include media-breakpoint-up(lg) {
			font-size: 14px;
			margin: 0 0 13px;
		}
		
		+ h4{
			margin-top:-6px;
			font-size: 9.5px;
			color:rgba(#4a4535,0.6);

			@include media-breakpoint-up(lg) {
				font-size: 11px;
			}
			
		}
		+ h5{
			margin-top:-10px;
		}

		i,svg{
			margin-right:6px;
		}

		+ p{
			margin-top:-8px;
			font-size:0.857em;
		}

		
	}

	h5{
		font-family:$font-family-sans-serif;
		font-size: 7px;
		letter-spacing: 0.7px;

		&.series{
			margin: 0 0 4px;
			text-transform: uppercase;
			font-weight: 700;
			
			@include media-breakpoint-up(lg) {
				margin: -3px 0 4px;
			}
			
			+ h3{
				@include media-breakpoint-up(lg) {
					margin-top:0;
				}
			}
			
			span{
				display: inline-block;
				
				color: #5b523c;
				border-radius: 0 0 2px 2px;
				padding: 6px 9px 4px;
				background-color: rgba(159, 148, 95, 0.1);
				line-height:1;
				text-transform:uppercase;
				@include media-breakpoint-up(lg) {
					border-radius: 2px;
					padding: 4px 9px;
				}
			}
			
			
		}

	}

	p{
		font-family:$font-family-sans-serif;
		font-size:11px;
		line-height:1.2;

		&.disclaimer{
			font-style:italic;
			font-size:0.9em;
			color:#aaa38b;
		}
	}

	/*&.landscape{

		h3 + h5{
			margin-top: -5px;
		}
		
		h5{
			font-family:$font-family-sans-serif;
			font-size: 0.9em;
			font-weight: 400;
		}
		
	}
	
	&.square{

		h5{
			font-family:$font-title-sans;
			font-size:1.5em;
			font-weight: 700;
		}

	}*/
	
	&.portrait{
		.meta{
			height: 110px;
			padding: 15px 15px 10px;
			> i,svg{
				font-size:1.143em;
			}
		}
		h3{
			font-size: 	1.143em;
			margin: 0 0 7px;

			&.smaller{
				font-size: 1em;
			}
			+ h5{
				margin-top:0;
			}
			+ p{
				margin-top:-5px;
			}

		}
		h5{
			font-size:0.95em;
			font-weight:400;
			+ p{
				margin-top:-6px;
				font-size:0.75em;
			}
		}

		p{
			font-size:0.857em;
			&.disclaimer{
				font-size:0.9em;
			}
		}

		@include media-breakpoint-up(lg) {

			.meta{
				height: 125px;
				padding: 30px 30px 10px;
			}
		}
		
	}
	
	&.xs{
		h3{
			font-size: 	1.00em;
			margin: 0 0 7px;
		}
		.meta{
			padding:30px 15px 10px;
			> i,svg{
				font-size:1.00em;
			}
		}
		p{
			font-size:0.857em;
		}
	}

	&.sm{
		h3{
			font-size: 	1.0em;
			margin: 0 0 7px;

			&.smaller{
				font-size: 1em;
			}
			+ h5{
				margin-top:0;
			}
			+ p{
				margin-top:-5px;
			}

		}
		h5{
			font-size:0.95em;
			font-weight:400;
			+ p{
				margin-top:-6px;
				font-size:0.75em;
			}
		}
		.meta{
			//padding:6px 10px;
			> i,svg{
				font-size:1.143em;
			}
		}
		p{
			font-size:0.857em;
			&.disclaimer{
				font-size:0.9em;
			}
		}
	}

	&.large{
		.meta{

			> i,svg{
				font-size:1.714em;
			}
			&:not(.auto-height){
				height:145px;
			}
		}
		h3 {
			font-size: 1.714em;
		}
		p{
			font-size:1.143em;
			&.disclaimer{
				font-size:0.9em;
			}
		}
	}

	&.extra-large{
		.meta{
			> i,svg{
				font-size:1.714em;
			}
			&:not(.auto-height){
				height:165px;
			}
		}
		h3 {
			font-size: 2.286em;
		}
		p{
			font-size:1.143em;
			&.disclaimer{
				font-size:0.9em;
			}
		}
	}

	&:hover{
		h3, h4, p{
			color:$brand-gold;
		}

		.meta{
			> i:not(.static),svg:not(.static){
				animation:linkArrow 1.5s ease;
				animation-fill-mode: forwards;
			}
		}
		.imgWrap {
			> img {
				transform: scale(1.1);
			}
		}
	}

	&.simple{
		display:flex;
		height:140px;
		&:hover{
			h3,p{
				color:inherit;
			}
		}
		img{
			width:140px;height:140px;
			display:block;
		}
		.meta{
			padding: 30px 30px 10px 15px;
			height: 140px;
			p{
				font-size:11px;
				font-family:$font-family-sans-serif;
			}
		}
	}

	&.active{
		.meta{
			border-bottom: 4px solid $brand-text-brown;
		}
	}

	&.tall-meta-bin{
		.meta{
			height:auto;
		}
	}

	&.horizontal{
		img{
			max-width:200px;height:auto;
			margin:0 auto;


		}
		.meta{
			height:auto;
			padding:0 20px 20px;
		}
	}

	&.horizontal-flex{
		display:flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		flex-direction: column;
		border-top: 1px solid #e6e3db;

		img{
			width: 100%;
			height: auto;
			//max-width: 300px;
			//max-height: 400px;
			display: block;
			align-self: start;
			margin: 0 auto;
		}
		.meta{
			height:auto;
			width:100%;
			padding:20px 20px 10px;
			color:white;
			&.teal{
				background: linear-gradient(135deg, rgba(116,169,159,1) 0%,rgba(125,185,232,1) 100%);
			}
			h3{
				font-family: $font-title-sans;
				font-size:2.4em;
				margin-top:0;
				color:#d7fbff;
				
				+ h4{
					font-size: 1.0em;
					font-family:$font-family-sans-serif;
					color:#d7fbff;
				}
			}
			h5{
				font-size: 1.0em;
				font-family:$font-family-serif;

				a{
					font-size: 1.0em;
					font-family:$font-family-sans-serif;
					color:#d7fbff;
					&:hover{
						color: #4e8a90;
					}
				}
			}
			p{
				font-size:1.1em;
			}
			a{
				font-family: $font-title-sans;
				font-size: 1.4em;
				&.btn-primary{
					background-color: #4e8a90;
					&:hover{
						background-color: darken(#4e8a90,10%);
					}
				}
			}
		}
	}

	&.activityBin {

		.meta{
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include media-breakpoint-up(lg) {
				justify-content: flex-start;
			}
		}
		

	}

	&.event-bin{
		
		//margin: 10px;
		margin: 0 0 10px;
		position:relative;
		display:flex;
		width: 100%;
		height:$binMobileThumbWidth;

		@include media-breakpoint-up(md) {
			margin: 0 2.5px 10px;
			width:calc(50% - 10px);
			
		}
		
		@include media-breakpoint-up(lg) {
			//font-size:14px;
			display:block;
			margin: 0 5px 10px;
			max-width:310px;
			width:100%;
			height:auto;
			flex-direction: column;
		}
		
		@include media-breakpoint-up(xl) {
			max-width:320px;
		}
		
		&.portrait, &.landscape{
			.imgWrap{
				img{
					height:auto;
				}
			}
		}
		
		&.landscape{
			
			.imgWrap{
				width:$binMobileLandscapeThumbWidth;
				@include media-breakpoint-up(lg) {
					width:auto;
				}
				img{
					width:$binMobileLandscapeThumbWidth;
					@include media-breakpoint-up(lg) {
						width:100%; height:auto;
					}
				}
			}
			
			.meta{
				width:calc(100% - #{$binMobileLandscapeThumbWidth});
				@include media-breakpoint-up(lg) {
					width:auto;
				}
			}

			.footer{
				width: calc(100% - #{$binMobileLandscapeThumbWidth });
				left: $binMobileLandscapeThumbWidth;
				@include media-breakpoint-up(lg) {
					width: 100%;
					left: 0;
				}
			}
		}
		
		
		
		.meta{
			/*display: flex;
			flex-direction: column;
			justify-content: center;*/
			padding:5px 10px;
			height: auto;
			width:calc(100% - #{$binMobileThumbWidth});

			@include media-breakpoint-up(lg) {
				height:130px;
				width:auto;
				padding:10px 20px;
			}
			
			&.portrait{
				width:calc(100% - #{$binMobilePortraitThumbWidth});
				@include media-breakpoint-up(lg) {
					width:auto;
				}
			}
		}
		
		.imgWrap{
			width:$binMobileThumbWidth;
			
			@include media-breakpoint-up(lg) {
				width:auto;
			}
			
			&.portrait{
				width: $binMobilePortraitThumbWidth;
				@include media-breakpoint-up(lg) {
					width:auto;
				}
				img{
					width: $binMobilePortraitThumbWidth;
					@include media-breakpoint-up(lg) {
						width: 100%;
					}
				}

			}
			


			img{
				width:$binMobileThumbWidth;
				height:$binMobileThumbWidth;
				display:block;
				@include media-breakpoint-up(lg) {
					width:100%; height:auto;
				}
			}

		}

		h3{
			margin: 0 0 8px;
			line-height: 1.1;

			@include media-breakpoint-up(lg) {
				margin: 14px 0 13px;
			}
		}
		
		/*.footer{
			//color: #62605e;
			color:rgba(#4a4535,0.6);
			position:absolute;
			width: calc(100% - #{$binMobileThumbWidth });
			
			left: $binMobileThumbWidth;
			font-family:$headings-font-family;
			font-weight:$headings-font-weight;
			font-size:9px;
			line-height:1;
			display:flex;
			justify-content: space-between;
			align-items: center;
			padding: 4px 10px;
			bottom: 0;
			border-top: 1px solid rgba(#b3aa8f,0.15);

			&.no-content{
				border-top-color:transparent;
				
			}
			
			
			.icons{
				display:none;
                font-size:	1em;
				color:rgba(#4a4535,0.6);
				i,svg{
					margin-left:0;
					&:hover{
						color:$brand-text-brown;
					}
				}

				@include media-breakpoint-up(lg) {
					//display:block;
				}
				
			}
			
            .age{
				color: #4a4535;
			}
            
			@include media-breakpoint-up(lg) {
				//position: relative;
				width: 100%;
				font-size:11px;
				padding: 10px 20px;
				left: 0;
				//bottom: 0;
			} 
		
			@include media-breakpoint-up(xl) {
				
			}
		}*/
		
		span.date{
			//border-radius: 2px;
			//border: 1px solid rgba(#b3aa8f,0.15);
			//border-right-width: 0;
			//border-bottom-width: 0;
			//border-left-width:0;
			//padding:4px 10px;
			//width: 100%;
			@include media-breakpoint-up(lg) {
				//border-right-width:1px;
				//border-bottom-width:1px;
				//border-left-width:1px;
				//width: auto;
			}
		}
		
		/*.date{
			font-size:1em;
			transition:all 0.3s ease;
			position:absolute;
			bottom: 20px;
			left: 15px;
			right: 15px;
			text-align: center;


		}*/

		.date-group{
			position:absolute;
			bottom: 20px;
			left: 30px;
			.date{
				position:static;
				margin-right:4px;
			}
		}

		.learnmore{
			color:#9b9b9b;
			font-size:0.714em;
			text-transform:uppercase;
			font-family: $font-family-sans-serif;
			position:absolute;
			bottom: 20px;
			right: 15px;
			@include media-breakpoint-up(lg) {
				right:30px;
			}
		}

		&:hover:not(div){
			.learnmore, h5, h3, p{
				color:$brand-gold;
			}
			/*.date{
				background-color:$brand-gold;
				color:#fff;
			}*/
		}



	}

	@include media-breakpoint-up(md) {
		&.tall-meta-bin{
			.meta:not(.auto-height){

				height:260px;
			}
		}
		&.horizontal{
			img{
				width:25%; height:auto;
				float:left;
				padding:10px;

			}
			.meta{
				float:left;
				margin-left:1%;
				width:74%;
				height:auto;
				padding:20px 20px 20px 0;
			}
		}
		&.horizontal-flex{
			flex-direction: row;
			img{
				width: 50%;
			}
			.meta{
				width: 50%;
				padding:60px 20px 10px;
			}
		}
	}

	@include media-breakpoint-up(lg) {

	}

	//1200px +
	@include media-breakpoint-up(xl) {
		&.horizontal-flex{
			flex-direction: row;
			img{
				width: 40%;
			}
			.meta{
				width: 60%;
				padding:80px 20px 10px;
			}
		}

	}
	
}

.event-group{
	
	//padding:15px 0;

	> div{
		display:flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content:center;
		
		width:100%;
		
		@include media-breakpoint-up(md) {
			//margin:20px -5px 0;
			margin:-5px;
		}
	}


	.bin{
		margin:5px;
	}

	
	// todo: removed because we shouldn't be using .event-groups, right?
	/*.titleblock{
		h2{
			font-size:1.75em;
			margin-top:0;
			margin-bottom: 0;
			color: $brand-text-brown;
		}
		
	}*/

	@include media-breakpoint-up(md) {
		
	}
	@include media-breakpoint-up(lg) {
		//padding:40px 0; changed for modern blocks
	}
	@include media-breakpoint-up(xl) {
		//padding:60px 0 40px; changed for modern blocks
	}

}

.past-events{

	$pastEVentBinMobileThumbWidth:120px; //140
	.event-bin{
		height: auto;
		width:calc(50% - 5px);
		margin: 0 2.5px 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include media-breakpoint-up(md) {
			margin: 0 5px 10px;
			width:calc(50% - 10px);
		}

		@include media-breakpoint-up(lg) {
			//display:flex;
			flex-direction: row;
			justify-content: flex-start;
			height:$pastEVentBinMobileThumbWidth;
			width:100%;
			margin:5px;
		}

		&:hover{
			h3,p{
				color:inherit;
			}
		}

		.imgWrap{
			display:none;
			@include media-breakpoint-up(lg) {
				width:$pastEVentBinMobileThumbWidth;
				display:block;
				img{
					width:$pastEVentBinMobileThumbWidth;
					height:$pastEVentBinMobileThumbWidth;
				}
			}
		}

		.meta{
			display: flex;
			flex-direction: column;
			width:100%;
			height:auto;
			//text-align: center;
			padding:10px 10px 5px;
			h5{

				@include media-breakpoint-up(lg) {
					font-size: 8px;
				}
			}

			h3{

				@include media-breakpoint-up(lg) {
					font-size: 12px;
					+ h4{
						font-size: 10px;
					}
				}

				+ h4, + p{
					display:none;
				}
				
				
			}

			@include media-breakpoint-up(lg) {
				width:calc(100% - #{$pastEVentBinMobileThumbWidth});
				height: $pastEVentBinMobileThumbWidth;
				padding: 20px 10px;
				//text-align: left;
			}
		}

		.footer{

			left: 0;
			position: relative;
			width: 100%;

			.icons{
				display:none;
			}

			@include media-breakpoint-up(lg) {
				width: calc(100% - #{$pastEVentBinMobileThumbWidth });
				position: absolute;
				bottom: 0;
				left: $pastEVentBinMobileThumbWidth;
				font-size: 9px;
				padding: 7px 10px 7px 10px;
			}
		}
	}
}

// TODO: What is this for? Well it's when a bin is not an active link
// it would show a coming soon message before. 
div.bin{
	//opacity:0.75;
	//transition:opacity 0.2s;
	&:hover{
		//opacity:1.0;
		
		.imgWrap{
			//position:relative;
			/*&:after{
				content:"Coming Soon";
				position:absolute;
				transform:translate(-50%,-50%);
				top:50%;
				left:50%;
				background-color: #42413f;
				color: #c7c7c7;
				border-radius:2px;
				padding:2px 10px;
			}*/
		}

		/*h3{
			color:inherit;
		}
		p{
			color:inherit;
		}
		.meta{
			> i,svg{
				animation:none;
			}
		}
		.imgWrap {
			> img {
				transform: scale(1.0);
			}
		}*/
	}
}

// TODO: What is this for? vs. .event-group?
.bin-group{
	margin:40px 15px;

	> h4{

		font-size:2.286em;
		margin-bottom:20px;
		&:after{
			content:"";
			display:block;
			width:50px; height: 2px;
			background-color:darken($brand-gold,10%);
			margin: 18px 0;
		}
	}

	.bin{
		h5{
			&.series{
				margin: 10px 0 2px;

				+ h3{
					margin-top:-3px;
				}
			}
		}
	}

	&.two-up{

		.bin{

			.meta{
				height:90px;
			}
		}

	}

	&.three-up{

		.bin{

			.meta{
				height:100px;
			}
		}

	}

}

// TODO: What is this for?
.accommodation-bin-group{
	//.bin{
	//	font-size:12px;
	//	p, .specs{
	//		display:none;
	//	}
	//	.meta{
	//		height: 110px;
	//	}
	//}
}

// TODO: What is this for?
.accommodation-bin{

	//margin:10px 0;

	.meta{
		//height: 150px;

		h3{
			font-size:1.286em;
			
		}
		p{
			font-size:0.857em;
			font-family:$font-family-serif;
			//font-style:italic;
			color:#8b8675;
		}
		.stats{

		}
		.specs{

			.label{
				border-radius: 24px;
				background-color:#829aa3;
				font-size:0.786em;
				font-weight: 400;
				padding:3px 10px;
				margin-bottom: 2px;
				display: inline-block;
				//@include fixFontNumbers();
			}

		}

	}

	.stats{
		div.title{
			font-size:11px;
			color: #bfbfbf;
			font-family:$font-family-sans-serif;
			display:block;
		}
		font-size:12px;
		margin-bottom:30px;
		display:flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		.stat{
			padding:6px 10px;
			h4{
				font-size:3.0em;
				//font-family: $font-title-serif;
				display: inline;
				//@include fixFontNumbers();
				font-weight: 500;
			}
			p{
				font-size:1.0em;
				display: inline;
				color:#8b8675;
			}
			&:not(:last-child){
				border-right:1px solid #e5e2da;
			}
			&:first-child{
				padding-left: 0;
			}
		}

	}
	
	
	&:hover{
		.meta{
			p{
				color:$brand-gold;
			}
		}
	}
	@include media-breakpoint-up(md) {
	//@media (min-width: $screen-sm-min) {
		.meta{

			h3{
				font-size:1.571em;
			}
		}
	}
}

// TODO: What is this for?
.event-detail-bin{
	margin:0 0 10px;
	.meta {
		height: 130px;
		h5.series{
			font-size:0.643em;
			margin-bottom:3px;
		}
		h3{
			font-size:1.714em;
		}
		h4{
			font-family: $font-family-sans-serif;
			font-size:	0.714em;
			margin-top:0;
			line-height: 1.4;
		}
	}
}
