$gradColorBiege: rgb(242,240,233);

.gray-section-wood{
    background-size: 100%;
    background-repeat: repeat-y;
    background-position: top center;
    background-color:transparent;

    @include media-breakpoint-up(lg) {
        background-size: 1200px 606px;
        background-color:#fff;
    }
    @include media-breakpoint-up(xl1440) {
        background-size: 1600px 808px;
    }
    @media (min-width: 1920px) {
        background-size: 1920px 970px;
    }
    
    .bin{
        box-shadow: 0 4px 4px rgba(163,157,133,0.09);
    }
    
    div.edge{
        position:absolute;
        top:-20px; left:0;
        width:100%;
        height:40px;
        background: radial-gradient(ellipse at center, rgba(34, 30, 22, 0.05) 0%, rgba(34, 30, 22, 0) 80%, rgba(34, 30, 22, 0) 100%);

    }
    
}

.new-design-2019{

    background-color: #fff;
    #app{
        background-color: #fff;
        //padding-top: 48px;
        @include media-breakpoint-up(md) {
           // padding-top: 0;
        }

        > div:first-child{
            transition: transform 0.3s ease;
            z-index: 1;
            position:relative;
        }
    }

    // todo: this is probably something that needs to be default. Keep the reservations/chat right-nav in the intro section top aligned...
    &.force-aside-flex-start{
        .top-content{
            align-items: flex-start;
        }
    }
    
    &.show-side-popup{
        overflow: hidden;
        #app > div:first-child{
            transform:translate3d(-400px,0,0);
            //pointer-events: none;
            position:relative;
            @media (min-width: 1024px) {
                transform:translate3d(-640px,0,0);
            }
            &:after{
                content:"";
                display:block;
                position:absolute;
                top:0; left:0; bottom:0; right:0;
                background-color:rgba(white,0.8);
            }
        }
        #SidePopup{
            .closePopupBtn{
                display:block;
            }
        }
    }
  
    // todo: no need for this to be assigned, just dont have hero slides? then handle it
    &.no-slides{
        
        #ContentWrapper{
            border-top:1px solid rgba($brand-gold,0.1);
            background: linear-gradient(to bottom, rgba($brand-gold,0.05) 0%,rgba($brand-gold,0) 33%);
            box-shadow: 0 0 40px rgba(0,0,0,0.06);
            z-index: 1;
            position: relative;
        }
        
        #Top{
            background:transparent;
            
            @include media-breakpoint-up(md) {
                padding-top: 30px;
            }
            @include media-breakpoint-up(lg) {

            }

            aside{
                @include media-breakpoint-up(lg) {
                    margin: 40px 0 0 60px;
                }
            }
        }
    }
    
    &.top-bottom-edge{
        #ContentWrapper{
            #Top{
                
            }
            position:relative;
            //z-index: 1;
            &:after{
                content:"";
                display:block;
                position:absolute;
                bottom:-20px; left:0;
                width:100%;
                height:40px;
                background:transparent url(/images/section-bottom-edge.png) top center no-repeat;
                opacity:0.1;
                background-size:contain;
                /*background: radial-gradient(ellipse at center, rgba(34, 30, 22, 0.05) 0%, rgba(34, 30, 22, 0) 80%, rgba(34, 30, 22, 0) 100%);*/
            }
  
           
        }
    }
    
    // todo: this is probably old.
    .legacy{
        border-top:1px solid #e6e3db;
        padding-top:40px;
        
        p, .titleblock, .image-block, .block-copy, .collage-wrapper-block{
            max-width:992px;
            margin:0 auto;
            padding:0 15px;
            p{
                padding:0;
            }
            .titleblock{
                max-width: none;
                padding:0;
                
            }
            @include media-breakpoint-up(lg){
                padding:0;
            }
        }

        .image-block{
            margin-bottom:15px;
            @include media-breakpoint-up(lg){
                margin-bottom:30px;
            }
        }
        
        .titleblock{
            font-size: 10px;
        }
        
        .wrap-section.gray{
            margin-top: 30px;
        }
        
    }
   

    // todo: some work here to move this to themes for block manifests
    .wrap-section{
        &.brown{
            background-color:$brand-brown-bg;
            color:#dad2be;
            .titleblock{
                h1, h2{
                    color:#dad2be
                }
            }
        }
        &.gray:not(.gray-section-wood){
            background-color:$brand-gray-bg;
        }
    }

    .gray-section-wood{
        .wrap-section.gray{
            background-color:transparent;
            background:none;
        }
    }
    
    // todo: this one is frustrating, and needs to be extracted out
    section,div{
        .pullquote{
            margin-top:70px;
            font-size:	1.125em;
            font-weight:500;
            font-family:$headings-font-family;
            padding-left: 40px;
            line-height:1.2;
            .author{
                font-weight:500;
                font-family:$headings-font-family;
                margin-top: 10px;
            }
            &:before{
                font-size: 5em;
            }
            @include media-breakpoint-up(lg) {
                font-size:	1.429em;
                padding-left: 70px;
                &:before{
                    font-size: 7.571em;
                }
            }
        }
        &.gray{

            background-color:$brand-gray-bg;
            position: relative;

            > .row{

            }

            @include media-breakpoint-up(lg) {

                background: linear-gradient(to right,
                        rgba($brand-gray-bg,0) 0%,
                        rgba($brand-gray-bg,1) 12%,
                        rgba($brand-gray-bg,1) 88%,
                        rgba($brand-gray-bg,0) 100%);

                /*                &:before{
                                    content:"";
                                    display:block;
                                    position:absolute;
                                    top:0;
                                    left:-100px;
                                    width:100px;
                                    height:100%;
                                    background: linear-gradient(to left, rgba(241,239,233,1) 0%,rgba(241,239,233,0) 100%);
                                }
                                &:after{
                                    content:"";
                                    display:block;
                                    position:absolute;
                                    top:0;
                                    right:-100px;
                                    width:100px;
                                    height:100%;
                                    background: linear-gradient(to right, rgba(241,239,233,1) 0%,rgba(241,239,233,0) 100%);
                                }*/
            }


        }
        &.brown{

            background-color:$brand-brown-bg;
            color:#dad2be;

            .titleblock{
                h1, h2{
                    color:#dad2be
                }
            }

        }
    }
    
    section{
       /* padding-left:15px;
        padding-right:15px;
        max-width:1440px;

        
        

        
        
        @include media-breakpoint-up(md) {
            padding-left:30px;
            padding-right:30px;
        }

        @include media-breakpoint-up(xl) {
            padding-left:80px;
            padding-right:80px;
        }*/
    }
    
    // todo: move this to the component block level
    // this does handle the important titleblock area of heroes for the 2019 theme
/*    &.new-titles{
        #Hero{
            .titleblock{
                background-color:#fff;
                text-shadow: none;
                color: inherit;
                bottom:0;
                border-radius: 2px 2px 0 0;
                
                @include media-breakpoint-up(md) {
                    left:40px;

                }
                @include media-breakpoint-up(lg) {
                    left:0;
                    padding:0 40px;
                    //bottom:30px;
                }
                @include media-breakpoint-up(xl) {
                    left: 140px;
                    padding:15px 50px 0;
                    //bottom:0;




                    !*padding: 13px 50px 5px 30px;
                    bottom: 30px;
                    background: rgba(255,255,255,0.9);
                    color: #000;
                    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);*!
                }

                .date{
                    color:inherit;
                    !*position:absolute;
                    right: 190px;
                    width: 220px;
                    bottom:0;
                    margin:0;
                    background:white;
                    padding: 4px 20px;
                    font-size: 1.3em;
                    text-align: center;
                    border-radius: 2px 2px 0 0;*!
                }
            }
            

        }
    }*/
    
    #Hero {
        padding-left: 0;
        padding-right: 0;
        position: relative;
   
        overflow: hidden;
        
        @include media-breakpoint-up(md) {
            background:black;
        }
        
        .slides{

            margin-bottom: -6px;

            li.no-fade{
                picture img {
                    @include media-breakpoint-up(md) {
                        opacity:1;
                    }
                }
            }

            picture img {
              
                @include media-breakpoint-up(md) {
                    opacity:0.8;
                }
            }
        }
        
        // todo: this important class stores the opacity of heroes. consider moving this to a global variable
        .embed-responsive{
            
            @include media-breakpoint-up(md) {
                opacity:0.8;
            }
        }
        
        // todo: this is only used when adding the class to the hero which is not by default. Investigate how this is used.
        .centered{

            .titling{
                display: flex;
                justify-content: center;
                align-items: center;
                position:absolute;
                top:0;left:0;
                width:100%; height:100%;
                background-color: rgba(0,0,0,0.15);
                font-size:18px;
            }
            h1{
                font-size:18px;
                //font-family:$font-title-serif;
                //font-weight:400;
                //letter-spacing: 0.03em;
                //text-transform:uppercase;
                color:#fff;
                position: relative;
                top: 10px;
                letter-spacing: 1px;
                @include media-breakpoint-up(md) {
                    font-size: 40px;
                    top: 10px;
                }
            }
            span.caption-sm{
                font-size:16px;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                color:#fff;
                position: relative;
                letter-spacing: 1px;
                max-width: 640px;
                text-align: center;
                line-height: 1.3;
                background:none;
                bottom:0;
                @include media-breakpoint-up(md) {
                    font-size: 36px;
                    top: 10px;
                }
            }
        }



        .titleblock{
            padding: 15px;
            border-bottom: 1px solid #e8e3d8;
            @include media-breakpoint-up(md) {
                position:absolute;
                left:15px;
                bottom:15px;
                color:#fff;
                margin:0;
                padding: 0;
                text-shadow:0 0 20px rgba(black,0.7);
                border-bottom: none;
            }
            
            h1{
                font-size: 1.125rem;
                
                @include media-breakpoint-up(md) {
                    font-size: 1.25rem;

                }
                @include media-breakpoint-up(lg) {
                    font-size: 1.75rem;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 2.0rem;
                }
            }
            
            h2{
                font-size:1.0em;

                &:after{
                    content:none;
                }

                @include media-breakpoint-up(md) {
                   

                }
                @include media-breakpoint-up(lg) {
                    
                }
                @include media-breakpoint-up(xl) {
                    font-size:1.143em;
                }
            }
            
            h3{
                color:rgba(255,255,255,0.7);
                &:after{
                    content:none;
                }
                
                a{
                    color:rgba(255,255,255,0.7);
                    i,svg{
                        font-size:11px;
                    }
                    &:hover{
                        color:rgba(255,255,255,1); 
                    }
                }
            }
            
            .date{
                display: inline-block;
                
                //padding: 0;
                margin-top: 0;
                line-height: 1;
                //color: rgba(#fff,0.75);
                border: none;
                border-radius: 2px;
                
                padding: 6px 10px;
                
                color: #dad2be;
                background-color: #221e16;
                font-size:12px;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                
                @include media-breakpoint-up(md) {
                    box-shadow: 4px 4px 12px rgba(0,0,0,0.2);
                }
                @include media-breakpoint-up(lg) {
                    font-size:16px;
                    padding: 12px 20px;
                    //margin-top:10px;
                }
            }
            
            .category-title{
                a{
                    color:white;
                }
            }
            
            @include media-breakpoint-up(md) {
                left:40px;
            }
            @include media-breakpoint-up(lg) {
                left:0;
                padding:0 60px;
                bottom:30px;
            }
            @include media-breakpoint-up(xl) {
                padding:0 80px;
                bottom:50px;

            }
        }
    }
    
    // TODO: no more IDs
    #Top{
        padding-top:25px;
        padding-bottom:25px;
        background: white;

        .embed-responsive{
            margin-top:40px;
        }
        
        .date{
            margin-bottom:5px;    
        }
        
        @include media-breakpoint-up(md) {
            padding-bottom:50px;
            padding-top: 50px;
        }
        @include media-breakpoint-up(lg) {
            .date{
                margin-bottom:10px;
            }
        }
    }

    .top-content{

        display:flex;
        flex-direction: column-reverse;
        //align-items: center;

        &.align-top{
            align-items: flex-start;
        }
        
        @include media-breakpoint-up(lg) {
            flex-direction: row;
            justify-content: space-between;
           // align-items: flex-start;
        }

        hr{
            border-color: #f8f7ed; 
        }
    }

    aside{

        display:flex;
        flex-direction: column;
        margin:0 0 30px 0;
        
        

        > div.buttons{

            display:flex;
            justify-content: center;
            flex-wrap: wrap;
            margin:0 -5px;

        }

        .bins{
            display: flex;
            justify-content: center;

        }

        h4{
            text-align: center;
            color:$brand-gold;
            font-weight: 400;
            margin-bottom:20px;
        }

        .wrap-vue-btn{
            width: calc(100% - 10px);
            a.btn{
                margin:0 0 5px;
            }
        }

        a.btn{
            background-color:#9f8d62;
            color:#fff;
            display:block;
            margin:0 5px 5px;
            padding: 12px 12px;
            width:100%;
            @include buttonFont();
            i,svg{
                margin-right:5px;
            }
            &:hover{
                background-color:darken(#9f8d62,30%);
            }
            &.chat{
                background-color:#4a4535;

                &:hover{
                    background-color:darken(#4a4535,30%);
                }
            }
            @include media-breakpoint-up(lg) {
                margin:0 0 5px
            }
        }

        // TODO: this might be deprecated, old attempt to have old right navs in the new intro section right aside
        .rn-module{

            box-shadow:0 0 30px rgba(black,0.05);
            width:300px;
            display: flex;
            flex-direction: row;
            height: 130px;
            img{
                width: 55%;
                height: 100%;
            }
            .meta{
                text-align: left;
                padding: 20px 20px;
            }

            h4{
                font-size:0.857em;
                color:$brand-text-brown;
                text-align: left;
            }

            i,svg{
                font-size:0.857em;
                margin-left: 0;
            }

            &:hover{
                h4{
                    color:$brand-gold;
                }
            }
            @include media-breakpoint-up(lg) {
                flex-direction: column;
                width:auto;
                max-width:220px;
                height: auto;
                img{
                    width: auto;
                    height: auto;
                }
                h4{
                    font-size:1em;
                }

                i,svg{
                    font-size:1.714em;

                }
            }
        }
        
        // TODO: probably legit, but let's examine later if this is good
        .logo{
            margin: 15px 0;
            text-align: center;
            max-width:300px;
            @include media-breakpoint-up(lg) {
                max-width:none;
            }
            
            p{
                line-height: 1.2;
                font-size: 1em;
                color:#7b7666;
            }
        }
        
        
        @include media-breakpoint-up(md) {

            > div.buttons{
                margin:0 ;
                flex-wrap: nowrap;

            }
        }

        @include media-breakpoint-up(lg) {

            margin:0 0 0 60px;
            max-width:220px;
            align-items: flex-end;
            > div{
                width:220px;
                margin:0;
            }
            > div.buttons{

                display:block;

                a{
                    margin:0 0 10px;
                }
            }
            .wrap-vue-btn{
                width: auto;
                a.btn{
                    margin:0 0 10px;
                }
            }
        }

    }

    .target-nav{
        margin: 30px 0 !important; //todo: remove this important
    }

    // TODO:move this to new class
    // EXPANDO COMPONENT
    .overview-slice{

        position:relative;
        display:block;
        margin-bottom:5px;

        @include media-breakpoint-up(md) {
           // min-height:339px;
        }
        @include media-breakpoint-up(lg) {
            //min-height:440px;
            margin-bottom:10px;
        }
        @include media-breakpoint-up(xl1440) {
            //min-height:642px;
        }

        &.reserve{
            margin-top: 20px;
            margin-bottom: 20px;
            @include media-breakpoint-up(xl1440) {
               // margin-top: 40px;
               // margin-bottom: 40px;
            }
        }
        
        &.light{
            .top{
                background-color:black;
                img{
                    opacity: 0.85;
                }
            }
            .meta{
                background-color:rgba(#1e1c15,0.2);
            }
        }

        &.enter{
            //button.gallery{
            //    opacity:1;
            //    transform:translate(-50%,-120%);
            //}
        }

        &:hover{
            @include media-breakpoint-up(lg) {
                button.gallery{
                    opacity:1;
                    transform:translate(-50%,-120%);
                }
                
            }

        }
        
        &.overview{

            overflow: hidden;
            
            .meta{

                @include media-breakpoint-up(md) {
                    padding:20px 40px;
                    transition:transform 0.3s ease;
                    transform:translateY(15px);
                }
                @include media-breakpoint-up(lg) {
                    padding:20px 60px 35px;
                }
                @include media-breakpoint-up(xl) {
                    padding:20px 80px 35px;
                }
            }
            
            &:hover{
                @include media-breakpoint-up(lg) {
                    
                    .data{
                        //color:rgba(white,1);
                    }
                    
                    .meta{
                        transform:translateY(0);
                        
                        h3{
                            //color:rgba(white,1);
                        }
                    }
 
                }

            }
        }

        button.gallery{
            position: absolute;

            top: 28%;
            left:50%;
            transform:translate(-50%,-50%);

            background:rgba(black,0.3);
            color:#fff;
            padding:10px 15px;
            font-size:1.0em;
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            display:flex;
            align-items: center;
            text-transform: none;

            i,svg{
                font-size:18px;
                margin-left:8px;
                transition:transform 0.3s ease;
            }

            &:focus{
                outline:none;
            }
            @include media-breakpoint-up(md) {
                padding:20px 30px;
                font-size:1.286em;
                background:rgba(black,0.6);
                i,svg{
                    font-size:24px;
                    margin-left:10px;
                }
            }
            @include media-breakpoint-up(lg) {
                transition:transform 0.3s ease, opacity 0.3s linear,background 0.3s;
                opacity:0;
                top:50%;
                transform:translate(-50%,-50%);
                &:hover{
                    background:rgba(black,1);
                    i,svg{
                        transform:scale(1.2);
                    }
                }
            }
        }

        .top{
            position:relative;
        }

        .v-collapse-content-end{
            max-height: 990px;
        }

        .gallery{
            //position:absolute;
        }

        
        
        .data{
            //display:none;
            display: flex;
            align-items: center;
            font-size:0.75em;
            line-height: 1.1;
            color:rgba(white,0.5);
            transition:color 0.3s;
            i.fa-circle, svg.fa-circle{
                font-size: 24px;
                padding:0 10px;
                //margin-top:3px;
            }

            @include media-breakpoint-up(md) {
                //display: flex;
                align-items: center;
                font-size:0.857em;

                margin-right:30px;
            }
            @include media-breakpoint-up(lg) {
                font-size:1.0em;
            }
            @include media-breakpoint-up(xl) {
                font-size:1.143em;
            }
        }

        .meta{
            position:absolute;
            left:0;
            bottom:0;
            width:100%;
            display:flex;
            align-items: center;
            justify-content: space-between;
            background-color:rgba(#1e1c15,0.8);
            color:white;
            padding:8px 10px;
            cursor: pointer;

            @include media-breakpoint-up(md) {
                padding:20px 40px;
            }
            @include media-breakpoint-up(lg) {
                padding:20px 60px;
            }
            @include media-breakpoint-up(xl) {
                padding:20px 80px;
            }

            h2{
                margin:0 0 0;
                font-size:1.15em;
                @include media-breakpoint-up(md) {
                    font-size:1.25em;
                    margin:0 0 5px;
                }
                @include media-breakpoint-up(lg) {
                    font-size:1.75em;
                }
                @include media-breakpoint-up(xl) {
                    font-size:2.286em;
                }
            }
            h3{

                font-size:0.75em;
                color:rgba(white,0.4);
                margin:0;
                transition:color 0.3s;
                @include media-breakpoint-up(md) {
                    font-size:0.857em;
                }
                @include media-breakpoint-up(lg) {
                    font-size:1.0em;
                }
                @include media-breakpoint-up(xl) {
                    font-size:1.143em;
                }
            }

            .info{
                display:flex;
                align-items: center;
                justify-content: flex-end;

                div:last-child{
                    //
                    font-size: 19.5px;
                    padding:6px 6px;
                    color: black;
                    
                    span{
                        display:none;
                    }
                    @include media-breakpoint-up(md) {
                        font-size:28px;
                        padding:6px 12px;
                        span{
                            display:inline;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        font-size:38.5px;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size:46px;
                    }
                }

                .btn{
                    &:hover{
                        color:white;
                    }
                }
            }

            .fa-arrow-circle-right{
                color:white;
            }
            
            //&:hover{
            //    .info div:last-child{
            //        transform:translateX(10px);
            //    }
            //}
        }

        a.btn.brown{
            font-size:1.25em;
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            text-transform: none;
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 20px 60px;
            i,svg{
                margin-left:10px;
                font-size:24px;
            }
            @include media-breakpoint-up(lg) {
                justify-content: flex-start;
                font-size:1.375em;
            }
        }

        .room-content{

            padding:30px 15px;

            max-width: 1280px;
            margin:0 auto;
            
            
            h4{
                font-size:2.000em;
            }

            p{
                font-size:1.0em;
                @include media-breakpoint-up(lg) {
                    font-size:1.286em;
                }
            }

            ul{

                padding-left: 20px;
            }

            li{
                font-size:0.875em;
                line-height: 1.4;
                @include media-breakpoint-up(lg) {
                    font-size:1.143em;
                    line-height: 1.7;
                }
            }

            .three-columns{
                display:flex;
                flex-direction: column;
                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                    > div{
                        width:33%;
                    }
                }
            }

            .large-list{
                display:flex;
                // flex-direction: column;
                @include media-breakpoint-up(lg) {
                    //  flex-direction: row;
                    ul{
                        width:33%;
                    }
                    ul+ul{

                        margin-left:30px;

                    }
                    li{

                        @include media-breakpoint-up(lg) {
                            font-size:1.25em;
                        }
                    }
                }
            }



            .ammeneties-rates{
                display:flex;
                flex-direction: column;
                margin-top: 10px;
                h3{
                    margin-top:10px;
                }
                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                    margin-top: 20px;
                    h3{
                        margin-top:23px;
                    }
                }
            }

            .ammeneties{


                @include media-breakpoint-up(lg) {
                    margin-right:60px;
                    width:35%;
                }
            }

            .rates{

                h3{
                    margin-bottom:0;
                }

                p.disclaimer{
                    font-size:1.0em;
                    @include media-breakpoint-up(lg) {
                        font-size:1.143em;
                    }
                }

                @include media-breakpoint-up(lg) {
                    width:65%;
                    
                    .btn.brown{
                        display:inline-block;
                    }
                }
            }

            .table{
                margin-bottom:10px;
                tr:first-child{
                    td{
                        border-top:none;
                    }
                }
                tr td, tr th{
                    padding:3px 0;
                }
                td:first-child{
                    font-size:1.0em;
                    @include media-breakpoint-up(md) {
                        font-size:1.286em;
                    }
                }
                td:not(:first-child){
                    font-size: 1.0em;
                    font-family: $headings-font-family;
                    font-weight: $headings-font-weight;
                    text-align: right;
                    @include media-breakpoint-up(md) {
                        font-size: 1.714em;
                    }
                }
                th:not(:first-child){
                    text-align: right;


                }
                th{
                    font-weight: 400;
                    font-size:10px;
                    line-height: 1;
                    &:first-child{

                    }
                    @include media-breakpoint-up(md) {
                        font-size:1em;
                    }
                }
            }

            .footer-area{
                display:flex;
                flex-direction: column;
                margin-top:15px;
                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top:30px;
                    > div:first-child{
                        margin-right: 60px;
                        width: 35%;
                    }
                }


            }

            .zoom{
                font-size:1.286em;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                display:flex;
                align-items: center;
                justify-content: center;
                cursor:pointer;
                margin-bottom: 20px;
                @include media-breakpoint-up(lg) {
                    justify-content: flex-start;
                    margin-bottom: 0;
                }
                i,svg{
                    font-size:2.714em;
                    margin-right:5px;
                }
            }

            .description-book-now{
                display:flex;
                flex-direction: column;
                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                    justify-content: space-between;

                    align-items: center;
                    width:65%;
                }

            }

        }

        .reserve-content{

            display:flex;
            flex-direction: column;
            padding-top:30px;
            padding-bottom:30px;
            max-width: 1280px;
            margin: 0 auto;
            
            p{
                font-size:1.0em;
                margin-bottom:20px;
                @include media-breakpoint-up(lg) {
                    font-size:1.286em;
                    margin-bottom:50px;
                }
            }

            .copy{
                display: flex;
                flex-direction: column;
                align-items: baseline;
            }

            .btn{
                width: 100%;
                margin-bottom:20px;
            }
            @include media-breakpoint-up(lg) {
                flex-direction: row;
                align-items: center;
                .copy{
                    margin-right:30px;

                }
                > div{
                    width:50%;
                }

                .btn{
                    width: auto;
                    margin-bottom:0;
                }
            }

        }

        .expandable{
            
            
            
            .top{
                padding:0; 
                
                
            }
            
            /*.btn.plus {

                span {

                    margin-right: 9px;
                    font-size: 12px;
                }

                i {
                    font-size: 28px;
                    @include media-breakpoint-up(md) {
                        font-size: 28px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 38.5px;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 46px;
                    }
                }
            }*/
        }
    }

    .expandable{

        .top{
            padding:15px 0; // this might break something but added it for new past event collapsable popupnavs
            // update: fixed by removing padding on home/tents expandables. look out for others....

            &.bordered{
                &:after{
                    content:"";
                    display:block;
                    width:100%;
                    height:1px;
                    background: linear-gradient(90deg, rgba(237,231,218,0) 0%, rgba(237,231,218,1) 25%, rgba(237,231,218,1) 75%, rgba(237,231,218,0) 100%);
                    position: relative;
                    top: 15px; // todo: this will probably mess up if the main .top padding is updated.
                }
            }
        }
        
        /*.btn.plus{
            span{
                font:size
            }
        }*/
        .meta{
            &:hover{
                .btn.plus{
                    .svg-btn-plus {

                        .west{
                            transform:translateY(11px) rotate(45deg);
                        }

                        .east{
                            transform:translateY(11px) rotate(-45deg);
                        }
                    }
                }
            }
        }

        .btn.plus{

            background:none;
            display: flex;
            justify-content: center;
            align-items: center;
     
            color: $brand-brown;
            
            &.light{
                color: white !important;
            }
            
            span{

                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                margin-right:9px;
                text-transform: none;
                font-size: 12px;
            }
            i, svg.fa-plus-circle {
                font-size:28px;
                transition:transform 0.2s ease;
                @include media-breakpoint-up(md) {
                    font-size:28px;
                }
                @include media-breakpoint-up(lg) {
                    font-size:38.5px;
                }
                @include media-breakpoint-up(xl) {
                    font-size:46px;
                }
            }
            
            .svg-btn-plus {

                g {
                    transition:transform 0.2s ease;
                    transform-origin: center center;
                }
                
                .inner{
                    transition:transform 0.2s ease;
                }
            }
            
            &:active, &:focus, &.active{
                outline:none;
                box-shadow:none;
                i, .svg-btn-plus .inner, svg.fa-plus-circle{
                    transform:rotate(135deg);
                }
                &:hover{
                    i, .svg-btn-plus .inner{
                        transform:rotate(180deg);
                    }
                }
            }

            /*&.active{
                i, .svg-btn-plus .inner{
                    transform:rotate(180deg);
                }
            }*/
        }
    }

    .v-collapse-content{
        max-height: 0;
        transition: max-height 0.3s ease-out;
        overflow: hidden;
        padding: 0;
    }
    .v-collapse-content-end{
        transition: max-height 0.3s ease-in;
        //max-height: 1120px;
        max-height: 2400px; //todo :note that this was extended because of the wedding pricing expando, not sure where this ends or if this can be more dynamic
    }
    // END EXPANDO COMPONENT
    
    // RELATED GROUP
    .related-pages{
        
        padding: 30px;
        position:relative;
        overflow: hidden;
        
        @include media-breakpoint-up(sm) {
            padding: 30px 30px 60px;
        }
        
        &.bin-large-text{

            @include media-breakpoint-up(xl1440) {
                
                .bin{
                    h3{
                        font-size: 20px;
                    }
                }
            }
            
        }

    }
    
    
    
    // TODO: remove the id and move to something smarter
    // Global Page Block updates
    // Top area
    #ContentWrapper{
        background: #fff;

        .filter-nav{

            @include media-breakpoint-up(lg) {
                margin:50px 0 0;

            }
        }
    }

}


// TODO: NEED TO MOVE THIS TO NEW GLOBAL
// (reminder: home page uses this for the accomodations) 
// also used on /montana-resorts

.left-right-content-modules{

    border-bottom:4px solid white;
    background-position: 0 0;
    background-color: rgba(242,240,233,1);
    background-repeat:no-repeat;
    background-size: 100%;
    text-align: center;
    position: relative;
    padding:55% 0 30px;
    max-width: 1920px;

    // 768 -> 991
    @include media-breakpoint-up(lg) {
        background-repeat:no-repeat;
        background-position: bottom left;
        background-size: contain;
        background-color: white;
        padding:150px 0;

        &.border-bottom{
            border-bottom:10px solid white;
        }

    }
    
    .meta{
        color:$brand-text-brown;
        text-align: left;
        h2{
            font-size:2.0em;
            margin-bottom: 10px;
            @include media-breakpoint-up(lg) {
                font-size:3.000em;
                margin-bottom: 10px;
            }
        }
        h3{
            font-size:1.5em;
            margin-bottom: 10px;
            @include media-breakpoint-up(lg) {
                font-size:2.4em;
            }
        }
        p{
            line-height: 1.2;
            @include media-breakpoint-up(lg) {
                line-height: 1.3; 
            }
        }

    }

    &:before{
        content:"";
        display: block;
        position:absolute;
        top:0; left:0;
        width:100%;
        height:100%;

        @include media-breakpoint-up(lg) {
            right:0; left: inherit;
            background: linear-gradient(to right, rgba(242,240,233,0) 0%,rgba(242,240,233,0) 20%,rgba(242,240,233,1) 72%,rgba(242,240,233,1) 100%);
        }
    }

    &.full-bg{
        @include media-breakpoint-up(lg) {
            background-size: cover;
        }
    }
    



    &.left {
        @include media-breakpoint-up(lg) {
            background-position:bottom right;
        }
        
        &:before{
            //background: linear-gradient(to left, rgba(242,240,233,0) 0%,rgba(242,240,233,0) 20%,rgba(242,240,233,1) 53%,rgba(242,240,233,1) 100%);

            // 768 -> 991
            @include media-breakpoint-up(lg) {
                
                background: linear-gradient(to left, rgba(242,240,233,0) 0%,rgba(242,240,233,0) 20%,rgba(242,240,233,1) 72%,rgba(242,240,233,1) 100%);
            }
        }
    }


}

// TODO: THIS IS A NEW STYLE THAT SHOULD REPLACE THE .left-right-content-modules 
// Used on /dining/outdoor-dining.jpg

// TODO: might be important here and not just for the outdoor dining page
.left-right-meta-modules{

    border-bottom:1px solid white;
    background-position: 0 0;
    background-color: rgba(242,240,233,1);
    background-repeat:no-repeat;
    background-size: 100%;
    text-align: center;
    position: relative;
    //padding:55% 0 30px;
    //max-width: 1920px;

    // 768 -> 991
    @include media-breakpoint-up(lg) {
        background-repeat:no-repeat;
        background-position: bottom left;
        background-size: cover;
        background-color: white;
        //padding:150px 0;
        position: relative;
        height:0;
        padding-top:25.521%;
        overflow:hidden;
        
        &.border-bottom{
            border-bottom:1px solid white;
        }

    }
    
    @include media-breakpoint-up(xl) {
        margin: 0 auto;
    }
    
    .content{
        max-width: 1280px;
        margin:0 auto;
        display:flex;
        align-items: center;
       

        @include media-breakpoint-up(lg) {
            position: absolute;
            top:0;
            left: 50%;
            height: 100%;
            width: 100%;
            transform: translateX(-50%);
            padding: 0 30px;
            justify-content: flex-end;
        }
        @include media-breakpoint-up(xl1440) {
            padding:0;
        }
    }
    
    .meta{
        color:$brand-text-brown;
        text-align: left;
        padding:30px;
        
        @include media-breakpoint-up(lg) {
            width:44%;
            padding:0;
        }
        @include media-breakpoint-up(xl) {
            width:50%;
        }
        
        h2{
            font-size:1.5em;
            margin-top:0;
            margin-bottom: 10px;

            @include media-breakpoint-up(lg) {
                font-size:1.5em;
                margin-bottom: 6px;
            }

            @include media-breakpoint-up(xl1440) {
                font-size:2.143em;
                margin-bottom: 10px;
            }
        }
        p{
            line-height: 1.2;
            font-size:	1em;
            //max-width: 260px;
            @include media-breakpoint-up(lg) {
                line-height: 1.3;
                
            }
            @include media-breakpoint-up(xl1440) {
                font-size:1.071em;
            }
        }
        
        a.btn{
            margin-top:15px;
        }

    }

    &.left {
        
        /*@include media-breakpoint-up(xl1440) {
            background-position: bottom center;
        }*/
        .content{

            @include media-breakpoint-up(lg) {
                justify-content: flex-start;
            }
        }

    }


}  

// TODO: extrat this to class file (used in outdoor dining page)
.day-group{
    
    display:flex;
    
    .day{
        border:1px solid #6f6349;
        border-radius: 5px;
        background-color:rgba(white,0.3);
        color:#6f6349;
        display:flex;
        justify-content: center;
        align-items: center;
        padding:4px;
        margin-right: 10px;
        height:24px;
        width:24px;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        font-size:0.857em;
        
        @include media-breakpoint-up(xl1440) {
            height:28px;
            width:28px;
            font-size:1em;
            
        }
        &.active{
            background-color:rgba(#a2916a,0.6);
            color:#5c523c;
            border:none;
        }
    }
}

