
.rate-card{

	h2{
		//font-family:$font-title-sans;
		font-size:2.714em;
		font-weight:500;
		margin-bottom: 6px;
		small{
			color:inherit;
		}
	}
	h4{
		font-size:1.25em;
		font-style:italic;
		font-weight:400;
		font-family:$font-family-serif;
		color:#8b8675;
		letter-spacing:0.5px;
		margin: 0 0 20px;

		+ .price{
			margin-top:-20px;
			font-size:1.9em;
		}
	}
	h5{
		font-size:1em;
		font-style:italic;
		font-weight:400;
		font-family:$font-family-serif;
		color:#8b8675;
		letter-spacing:0.5px;
		margin: 0 0 20px;
		
		+ .price{
			margin-top:-20px;
		}
	}

	p{
		margin:20px 0;

		&.note{
			font-family:$font-family-sans-serif;
			font-size:0.714em;
			line-height: 1.2;
			color: #9e9885;
		}
	}

	.specs{

		.label{
			border-radius: 24px;
			background-color:#829aa3;
			font-size:1em;
			font-weight: 400;
			padding:4px 20px;
			margin-bottom: 2px;
			display: inline-block;
		}

	}



	.gallery{
		margin:20px 0 10px;
		figure{
			float:left;
			width:calc(33.33333% - 1px);

			&:not(:last-child){
				margin-right:1px;
			}
		}

	}

	table{
		text-align: center;
		background:none;
		margin-bottom:0;
		thead{
			font-size:1.143em;
			font-weight:700;
			tr:first-child{
				font-size:1.5em;
				font-family: $headings-font-family;
				font-weight: $headings-font-weight;
				th{
					border-bottom:none;
				}
			}
			th{
				text-align:center;
			}

		}
		.size{
			font-size:0.714em;
			font-family:$font-family-sans-serif;
			line-height: 1;
		}

		&.align-left{
			text-align: left;
			tr{
				td{
					vertical-align: middle;
				}
			}
			thead{
				th{
					text-align: left;
				}

			}
		}
		&.modern{
			thead{
				tr:first-child{
					font-size:1.5em;
					font-family: $font-title-sans;				
				}

			}
		}
	}

	.price{
		font-family: $headings-font-family;
		font-weight: $headings-font-weight;
		font-size:2.286em;
		line-height: 1.4;

		+ div{
			font-size:0.857em;
			font-family:$font-family-sans-serif;
			line-height: 1.2;
		}
	}
}

.vacation-package{
	border-radius: 2px;
	margin:20px 0;
	border-bottom: 1px solid #e6e3db;

	.title-rate{

		display:flex;
		justify-content: space-between;
		flex-direction:column;

		.rate{
			text-align: right;
			display:flex;
			align-items: center;
			line-height: 1;
			margin-top: -12px;
			> div{
				font-family:$font-family-sans-serif;

				&:first-child{
					font-size:12px;
					color:#aaa38b;
					margin-right: 10px;
					padding-bottom: 20px;

				}

				.price{
					font-family: $headings-font-family;
					font-weight: $headings-font-weight;
					font-size:2.0em;

					+ div{
						font-size:10px;
						color:#aaa38b;
						position: relative;
						top: -5px;
					}
				}
			}
			h3{
				margin-top:10px;
				align-self:flex-start;
				font-size:1.4em;
			}
		}
		@include media-breakpoint-up(md) {
			flex-direction:row;

			h2{
				font-size: 1.8em;
				line-height: 1;
			}
			.rate{
				
				> div{
					
					.price{
						font-size:24px;
					}
				}
			}
		}
	}

	.titling{
		&:after{
			content:"";
			display:block;
			width:50px; height: 2px;
			background-color:$brand-gold;
			margin: 18px 0;
		}
	}
	
	h2{
		font-size:1.8em;
		margin:0;
	}
	h3{
		font-family:$font-title-sans;
		font-size: 1.0em;
		font-weight: 400;
		margin:5px 0 0;
	}

	img{
		display:block;
		max-width: 100%;
	}
	
	p{
		font-size:1em;
		&.disclaimer{
			font-style:italic;
			color:#aaa38b;
		}
	}
	.meta{
		padding:20px 30px;
		background-color:#fff;
	}

	.buttons{
		display:flex;
		flex-wrap: nowrap;
		flex-direction: row;
		justify-content: flex-end;
		
		.btn{
			margin-left:5px;
		}
		
	}
	
	a.more-btn{
		font-family:$font-family-sans-serif;
		border-radius: 24px;
		background-color: #dbe3e6;
		color: #748a94;
		font-size:1em;
		font-weight: 400;
		padding:4px 20px;
		margin-top:20px;
		margin-bottom: 2px;
		display: inline-block;

		&.collapsed{
			i,svg{
				transform:rotate(90deg);
			}

		}
		i,svg{
			font-size:0.818em;
			margin-left:4px;
			transform:rotate(0);
			transition:transform 0.3s ease;
		}

	}
	.more-details{
		margin:20px 0;
	}


}

#Rates{
	
	section.gray.overview{
		padding:0 15px;
		max-width: none;
		
		@include media-breakpoint-up(xl1440) {
			padding:0;
		}
	}
	
	// todo: investigate this, seems like it's legit used on the new rates overview page.
	.standard-container{
		padding:30px 0;
		@include media-breakpoint-up(xl) {
			padding:30px 0 50px;
		}
		.copy{
			text-align: center;
			margin-bottom:30px;
		}
	}
	
	.rate-pdf-bins{
		display:flex;
		flex-wrap: wrap;
		justify-content: center;
		
		@include media-breakpoint-up(md) {
			margin:0 -10px;
		}
		
		/*&.four-across{
			> .bin-col, > .bin{
				@include media-breakpoint-up(md) {
					width:calc(50% - 10px);
					margin:5px;
				}
				@include media-breakpoint-up(lg) {
					width:calc(20% - 10px);

				}
			}
			.bin{
				
			}
		}*/
		
		> .bin{
			opacity:1;
			margin: 0 0 10px;
			
			@include media-breakpoint-up(md) {
				width:calc(50% - 10px);
				margin:5px;
			}
			@include media-breakpoint-up(lg) {
				width:calc(25% - 10px);

			}
			@include media-breakpoint-up(xl) {
			
			}
			
			&.info{
				//width: 100%;
				padding: 15px;

				@include media-breakpoint-up(xl) {
					padding: 50px;
				}
				
				> div{
					display:flex;
					justify-content: center;
					flex-direction: column;
					height: 100%;
				}
			}
			
			&.see-all{
				display:flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				padding:30px;
				
				///background-color:$brand-gold;
				color:$brand-text-brown;
				text-transform: none;
				font-family: $headings-font-family;
				font-weight: $headings-font-weight;
				
				&:hover{
					color:$brand-gold;
				}
			}
			
			h3{
				font-size: 14px;
			}
			
			p{
				font-size:1.143em;
			}
		}
	}
	
	.rate-policy-cards{
		display:flex;
		flex-wrap: wrap;
		justify-content: center;
		padding:15px;
		background-color: $brand-gray-bg;
		border-radius: 2px;
		overflow: hidden;

		@include media-breakpoint-up(lg) {
			padding:30px;
		}
		
		
	}
	.rate-policy-block{

		width:100%;
		margin:5px 0;
		background-color:#fff;
		border-bottom: 1px solid #e6e3db;
		@include media-breakpoint-up(md) {
			width:calc(50% - 10px);
			margin:5px;
		}
		@include media-breakpoint-up(lg) {
			width:calc(33% - 10px);
			
		}
		@include media-breakpoint-up(xl) {
			width:calc(33% - 10px);
			
		}
		h3{
			margin-top:0;
			font-size: 1.714em;
		}
		p{
			font-size:0.929em;
			line-height: 1.4;
		}
		.meta{
			
			padding:20px 30px;
			
		}
	}

	/*nav.subnav{
		margin-top:50px;
		text-align: center;
		a{
			display:block;
			font-family: $headings-font-family;
			font-weight: $headings-font-weight;
			font-size:1em;
			margin: 0 0 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid rgba(0,0,0,0.1);
			&.active{
				color:$brand-gold;
				&:before{
					content:"";
					display:block;
					background:transparent url('/storage/static/events/nav-active-mark.svg') no-repeat top left;
					width:25px; height:7px;
					margin: 0 auto 10px;
				}
			}

			@include media-breakpoint-up(md) {
				display:inline-block;
				//font-size:1.1em;
				margin:5px 23px 15px;
				border-bottom: none;
				padding-bottom: 0;
				&.active{
					color:$brand-gold;
					//font-weight: 700;
					&:before{
						margin: 0 auto 10px;
					}
				}
			}
			@include media-breakpoint-up(xl) {
				margin:0 15px 15px;
			}
		}

		
	}*/

	article.special-offer{
		margin:0 15px 30px;
		box-shadow:none;
	}
}


/*
// 320 -> 413
@media (min-width: 320px) {

}
// 414 -> 767
@media (min-width: 414px) {

}

// 768 -> 991
@media (min-width: $screen-sm-min) {


}

// 992px -> 1200px
@media (min-width: $screen-md-min) {

}

//1200px +
@media (min-width: $screen-lg-min) {

}*/
