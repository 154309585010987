@keyframes linkArrow {
	0% {
		opacity: 0;
		transform: translate(-40px,0);
	}
	100% {
		opacity: 0.6;
		transform: translate(0,0);
	}
}

h1,h2,h3,h4,h5,h6{

}
body{
	//padding-top:1px;
	//border-top:1px solid #fff;

	&.show-modal{

		height:100%;
		overflow:hidden;
	}
	&.showPopUp{
		overflow: hidden;
		#app{
			transform:translate3d(-640px,0,0);
		}
		#Popup{
			.closePopupBtn{
				display:block;
			}
		}
	}
	
}
body::-webkit-scrollbar {
	width: 1em;
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}

ul.noDots{
	list-style:none;

	padding:0;
}

hr{
	&.blip{
		border-top: 1px solid $brand-gold;
		width: 40px;
	}
}
.skinny-container{
	margin-left:auto;
	margin-right:auto;
	max-width:1170px;
}
.container{
	background-color:transparent;

}

.main{
	position: relative;

	&.freeze{ // for when mobile menu is opened
		pointer-events: none;
	}

	&.showingDropdownMenu{
		pointer-events: none;

		&:after{
			content:"";
			position: fixed;
			top:0; left:0;
			width:100%;
			height:100%;
			background-color:rgba(#12100c,0.7);
		}
	}
}

.full-width{
	header{
		.navbar{
			background-color:#fff;
		}
	}
	footer{

	}
}


p, div.paragraph{
	font-size:1.0rem;

	a{
		color: #677a8a;
		text-decoration: underline;
		&:hover{
			color:$brand-gold;
			text-decoration: underline;
		}
	}

	sup{
		position:relative;
		font-size: 10px;
		top: -6px;
	}
}

#app{
	min-width: 100%;
	min-height: 100%;
	background-color: $body-bg;
	position: relative;
	top: 0;
	bottom: 100%;
	left: 0;
	z-index: 1;
	transition:transform 0.3s ease;

	@include media-breakpoint-up(sm) {
		
	}
}



// slick overrides
.slick-prev, .slick-next{
	height: 100px;
	width: 40px;
	&:before{
		font-size:42px;
	}
}
.slick-slider{
	&.noDots{
		margin-bottom:0;
	}
}

.slick-dots{
	bottom:-30px;
	li button::before{
		font-weight: 900;
		opacity:0.2;
	}
}

.img-fluid{
	width:100%;
}

.embed-responsive-1by1{
	padding-bottom:100%;
}

.tooltip{
	font-family:$font-family-sans-serif;
}
/*

.fix-font-numbers{
	font-variant-numeric: lining-nums;
	-moz-font-feature-settings:"lnum" 1;
	-moz-font-feature-settings:"lnum=1";
	-ms-font-feature-settings:"lnum" 1;
	-o-font-feature-settings:"lnum" 1;
	-webkit-font-feature-settings:"lnum" 1;
	font-feature-settings:"lnum" 1;
}
*/


#Mask-for-mobile-menu{
	&.show{
		display:block;
		position:absolute;
		top:0;left:0;right:0;bottom:0;
		background-color:rgba($brand-gold,0.6);

		z-index:1;
	}
}

//.row{
//	margin-left: 15px;
//	margin-right: 15px;
//	> div {
//		padding-left: 5px;
//		padding-right: 5px;
//	}
//}

.alert{
	
	&.alert-info{
		background-color: #e8f1f6;
		border-color: #e8f1f6;
		color: #7592a1;
		font-size:11px;
		margin-bottom:0;
		padding:8px 20px;
		i,svg{
			margin-right:10px;
		}
		+ .alert{
			margin-top:-20px;
		}
	}
}
.embed-responsive-64by25{
	padding-bottom: 39.0625%;
}
.embed-responsive-5by2{
	padding-bottom: 40%;
}
.embed-responsive-4by1{
	padding-bottom: 25%;
}
.embed-responsive-4by1{
	padding-bottom: 25%;
}
.embed-responsive-8by3{
	padding-bottom: 37.5%;
}
.embed-responsive-1440by648{
	padding-bottom: 45.1%;
}

.with-bg{
	background-size: cover;
	background-repeat: no-repeat;
}

/*
// 320 -> 413
@media (min-width: 320px) {

}
// 414 -> 767
@media (min-width: 414px) {

}

// 768 -> 991
@media (min-width: $screen-sm-min) {

}

// 992px -> 1200px
@media (min-width: $screen-md-min) {

}

//1200px +
@media (min-width: $screen-lg-min) {

}*/


.lazy-hidden{
	opacity:0;
	transition:opacity 0.5s;

	&.lazyloaded{
		opacity:1;
	}
}
