#Home{

    .home-events-module{
        text-align:center;
        background-size: cover;
        background-position: top center;
        position: relative;

        padding:0 0 35px;
        //font-size: 9px;

        .container{
            position:relative;
        }
        
        .row{
            margin-right:0;margin-left:0;
        }

        div.edge{
            position:absolute;
            top:0; left:0;
            width:100%;
            height:20px;
            background: linear-gradient(to bottom, rgba(34,30,22,0.16) 0%,rgba(34,30,22,0) 100%);
        }


        // 992px -> 1200px
        @include media-breakpoint-up(xl) {
            //padding:0 0 85px;
            padding: 40px 0 130px;
        }
        
        h2{
            //font-family:$font-title-sans;
           // font-weight:700;
            text-align:center;
            font-size:1.7em;
            
            padding-top:50px;
            max-width: 280px;
            margin: 0 auto 40px;
            
            &.white{
                color:#fff;
            }
            
            // 576 -> 767
            @include media-breakpoint-up(md) {
                font-size:1.9em;
                max-width: none;
            }
            
            // 768 -> 991
            @include media-breakpoint-up(lg) {
                font-size:2.3em;
                margin: 0 auto 60px;
            }
            
            + h3{
                margin-top:-20px;
                margin-bottom: 40px;
                font-size:0.75em;
                color:#fff;

                // 576 -> 767
                @include media-breakpoint-up(md) {
                    font-size:1.0em;
                }

                // 768 -> 991
                @include media-breakpoint-up(lg) {
                    margin-top: -50px;
                    font-size: 1.214em;
                }
            }

        }
        p{
            color:#646e87;
        }
        .bin{
            &:hover{
                p{
                    color:$brand-gold;
                }
            }
        }
        .btn.btn-outlined{

            margin-top:25px;

            z-index: 1;
            position: relative;
            background-color:rgba(0,0,0,0.7);

            i,svg{
                margin-right:5px;
            }

        }
    }
    
    // 576 -> 767
    @include media-breakpoint-up(md) {

    }

    // 768 -> 991
    @include media-breakpoint-up(lg) {



    }

    // 992px -> 1200px
    @include media-breakpoint-up(xl) {

    }

    // 1440px +
    @include media-breakpoint-up(xl1440) {


    }
}