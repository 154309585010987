.vm--container{
    z-index: 1001 !important;
}
.vm--overlay {

    background: rgba(0, 0, 0, 0.8) !important;
    
    @include media-breakpoint-up(md) {
        background: rgba(0, 0, 0, 0.1) !important;
    }
}
.vm--modal{


    
    .inner{
        display:flex;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
        picture{
            

            @include media-breakpoint-up(md) {
                width:50%; height:auto;
            }
        }
    }
    .meta{
        padding:30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include media-breakpoint-up(md) {
            width:50%;
        }
        
        h3{
            font-size: 16px;
            margin-bottom: 0;
            margin-top:0;
            @include titleUnderBar();
            
            @include media-breakpoint-up(md) {
                font-size: 28px;
                margin-bottom:0;
                &:after{
                    margin:28px 0;
                }
            }
        }
        h4{
            font-size:14px;
            @include media-breakpoint-up(md) {
                font-size:24px;
            }
            
            a{
                color:#4a4535
            }
        }
        p{
            font-size:11px;
            line-height: 1.4;
            @include media-breakpoint-up(md) {
                line-height: 1.7;
                margin-bottom:20px;
                font-size:16px;
            }
        }
        
    }
    
    button{
        position:absolute;
        top:0; right:0;
        background:none;
        width:40px;
        height:40px;
        display:flex;
        align-items: center;
        justify-content: center;
        padding:0;
        font-size: 2em;
        background-color:black;
        color:#fff;
        @include media-breakpoint-up(md) {
            color:#000;
            background-color:transparent;
        }
    }
}