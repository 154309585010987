.new-design-2019{


    .reservations  {
        margin-bottom: 10px;
        background-color:#f9f9f9;
        border-radius:2px;
        text-align: center;
        padding:15px 30px;
        p{
            font-size: 	0.875rem;
            line-height: 1.3;
        }
        
        a{
            font-family: $headings-font-family;
            font-size:1.25rem;
            font-weight:$headings-font-weight;
            color:$brand-brown;
        }

        &.retail{
            padding:40px 30px;
            .msg{
                font-size:1.125em;
                font-family: $headings-font-family;
                font-weight:$headings-font-weight;
            }
            .extra-msg{
                font-size:0.875em;
            }
        }
        
        @include media-breakpoint-up(md) {
           
  

        }

    }



}