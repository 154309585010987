.html5-video-wrapper{
    height:0;
    overflow: hidden;
    padding-bottom:75%;
    position: relative;
    margin-bottom: 8px;
    background-color:black;
    @include media-breakpoint-up(md) {
        padding-bottom:45%;
    }
    .vid-takeover{
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
.vimeo-video-wrapper{
    height:0;
    overflow: hidden;
    padding-bottom:75%;
    position: relative;
    margin-bottom: 8px;
    background-color:black;
    @include media-breakpoint-up(lg) {
        padding-bottom:45%;
    }
    .vid-takeover{
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
#Home{

    &.show-modal{
        .vid-takeover{
            &.lets-go{
                .center-block{
                    transform:none;
                    h1, button.white{
                        display:none;
                    }
                }
            }
        }
    }
    .vid-takeover {
        padding-left: 0;
        padding-right: 0;
        position: relative;
        
        display:block;
        
        > div.embed-responsive{
            background:black;

            margin-bottom:8px
        }

        //padding-top: 4px;
        margin-bottom:-55px;
        
        @include media-breakpoint-up(md) {
            //padding-top: 0;
            margin-bottom:0;

        }
        
        &.green-o{

            
            img{
                width:120px;
                height:auto;

                @include media-breakpoint-up(md) {
                    width:240px;
                }
            }

            h2, h3{
                display:none;
                @include media-breakpoint-up(sm) {
                    display:block;
                }
            }
            
            h1, h2, h3{
                font-family: $font-family-sans-serif;
                font-weight: 400;
                color:white;
                
                @include media-breakpoint-up(xs) {
                    
                }
            }
            h1{
                margin-top: 9px;
                @include media-breakpoint-up(md) {
                    margin-top: 23px;
                }
            }
            h2{
                margin-top: 0;
            }
            
            h3{
                font-size: 0.75em;
                text-transform:uppercase;
                letter-spacing: 0.25em;
                margin-top: 14px;
                @include media-breakpoint-up(md) {
                    margin-top: 23px;
                }
                color: #9c9837;
            }
        }
        
        
        &.national-parks{

            video{
                opacity: 0.7;
            }
            .center-block{
                top:60%;
                padding:0 1rem;
                width: 100%;

                text-align: left;
                //z-index: 1;
                @include media-breakpoint-up(md) {
                    max-width: 70vw;//1440px;
                    top:50%;
                }
                @include media-breakpoint-up(lg) {

                }

                > div{
                    color:#ffe4a7;
                    font-size:0.875rem;
                    text-transform: uppercase;
                    //@include headlineFont();
                    @include media-breakpoint-up(lg) {
                        font-size: 1.3125vw; //26px
                        letter-spacing: 0.1vw;

                        margin-bottom: 0.75rem;

                    }
                }

                h1{
                    font-size: 1.25rem;
                    color:white;
                    text-transform: uppercase;
                    line-height: 1.1;
                    letter-spacing: 0;
                    @include media-breakpoint-up(md) {
                        font-size: 1.875rem;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 4.1vw; //5rem;
                    }
                }

                p {
                    font-size:0.6875rem;
                    color:rgba(white,0.8);
                    line-height: 1.6;
                    font-family: $font-family-sans-serif;
                    font-weight: 400;
                    margin-top:5px;


                    @include media-breakpoint-up(md) {
                        font-size: 1.75vw;
                    }
                    @include media-breakpoint-up(lg) {
                        margin-top: 23px;
                        font-size: 1.2285vw;  //24px
                    }
                    @include media-breakpoint-up(xl) {
                        //font-size:1.0em;
                        //font-family: $headings-font-family;
                        //font-weight: $headings-font-weight;
                        max-width:1200px;
                    }
                }
                
                h3{
                    color:rgba($brand-gold,0.5);
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: 1rem;
                    @include media-breakpoint-up(lg) {
                        margin-top: 23px;
                        margin-bottom: 11.5px;
                        font-size: 1.5rem;
                    }
                    
                }

            }

            iframe{
                opacity: 0.65;
            }
        }
        
        
        &.lets-go{
            padding: 0 8px;
            //padding-top: 0;
            margin-bottom: 0;
            @include media-breakpoint-up(md) {

               // padding-top: 55px;
               // margin-bottom: -55px;
                
            }
            .center-block{
                top:50%;
                padding:0 15px;
                max-width: 580px;
                @include media-breakpoint-up(md) {
                    width:auto;
                    //top:50%;
                }
                h1{
                    font-size: 1.25em;
                    color:white;
                    margin-top: 0;
                    @include media-breakpoint-up(md) {
                        font-size: 2.5em;
                        margin-bottom:24px;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 3.5em;
                        margin-bottom:36px;
                    }
                }
                
                button.white{
                    
                    @include media-breakpoint-up(xl) {
                        padding: 1rem 6rem;
                        font-size: 1.25em;
                        font-family: $headings-font-family;
                        font-weight: $headings-font-weight;

                        text-transform: none;
                        
                    }
                }

            }

        }
        
        

        > div{
            pointer-events: none;
        }
        .slides{
            opacity:0.8;
            margin-bottom: -6px;
        }

        > iframe {
            opacity: 0.8;
        }

        .center-block{
            position:absolute;
            top:60%;left:50%;
            transform:translate(-50%,-50%);
            text-align: center;

            width: 100%;

            @include media-breakpoint-up(md) {
                width:auto;
                top:50%;
            }
            h1{
                font-size: 1.0em;
                
                @include media-breakpoint-up(xl) {
                    font-size: 1.5em;
                }
            }
            
            h2{
                font-size:0.875em;

                &:after{
                    content:none;
                }

                @include media-breakpoint-up(md) {


                }
                @include media-breakpoint-up(lg) {

                }
                @include media-breakpoint-up(xl) {
                    font-size:1.0em;
                }
            }

        }
        
        .titleblock{

            position:absolute;
            left:15px;
            bottom:15px;
            margin:0;

            color:#fff;
            text-shadow:0 0 20px rgba(black,1);

            h1{
                font-size: 1rem;

                @include media-breakpoint-up(md) {
                    font-size: 0.875rem;

                }
                @include media-breakpoint-up(lg) {
                    font-size: 1rem;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 1.125rem;
                }
            }

            h2{
                font-size:1.0em;

                &:after{
                    content:none;
                }

                @include media-breakpoint-up(md) {


                }
                @include media-breakpoint-up(lg) {

                }
                @include media-breakpoint-up(xl) {
                    font-size:1.143em;
                }
            }

            .date{
                display: inline-block;
                font-size:1.0em;
                padding: 0;
                //padding: 3px 10px 6px;
                margin-top: 0;
                margin-bottom:10px;
                line-height: 1;
                //border-radius: 2px;
                color: #fff;
                border: none;
                //border: 1px solid rgba(white,0.5);
                //background-color:rgba(black,0.3);

                text-shadow: 0 0 3px rgba(0,0,0,0.6);
            }

            @include media-breakpoint-up(md) {
                left:40px;

            }
            @include media-breakpoint-up(lg) {
                left:36px;
                bottom:30px;
            }
            @include media-breakpoint-up(xl) {
                //left:36px;
                bottom:50px;
            }
        }
    }
    
/*    #TopHero{

        display:flex;
        flex-direction: column;
        padding-top:55px;
        padding-left:8px; padding-right:8px;
        background-color:#fff;

        #Featured{
            margin-bottom:10px;
            
            @include media-breakpoint-up(md) {
                width:67%;
                margin-bottom:0;
                padding-top: 33%;
                position: relative;
                height:0;
                overflow: hidden;
                .slides{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

            }

        }

        #SubFeatured{
            
            .meta{
                position:static;
                font-family: $font-title-sans;
                font-size:12px;
                font-weight:$headings-font-weight;
                line-height:1;
                padding-top:1em;
                .title{
                    font-size:1.5em;
                    @include media-breakpoint-up(sm) {
                        font-size:1.0em;
                    }
                    @include media-breakpoint-up(md) {
                        color:#fff;

                    }
                    @include media-breakpoint-up(lg) {
                        font-size:1.5em;
                    }
                }
                .btn{
                    margin-top:15px;
                    
                    &:hover{
                        color:#fff;
                        background-color:$brand-gold;
                    }

                    i,svg{
                        margin-left:5px;
                    }
                }
                @include media-breakpoint-up(md) {
                    position:absolute;
                    font-size:14px;
                    padding-top:8em;
                }
            }
            
            !*
            .btn{

                @include media-breakpoint-up(md) {
                    margin-top:20px;
                }
            }
            *!


!*            .top{
                margin-bottom:2px;
                position:relative;
                
                .slide{
                    position: relative;
                }


                @include media-breakpoint-up(md) {
                    margin-bottom:-5px;
                }
            }*!

            !*.bottom{
                margin-bottom: 50px;

                .meta{
                    .btn{
                        margin-top:0;
                        @include media-breakpoint-up(md) {
                            margin-top:20px;
                        }
                    }
                }

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }
            }*!

            @include media-breakpoint-up(md) {
               // width:33%;
                width: calc(33% - 8px);
                padding-top: 33%;
                position: relative;
                height:0;
                overflow: hidden;
                
                .slides{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                
                //border-left: 8px solid white;
            }
        }

        .slick-dots{
            bottom: 104px;
            li{

                margin: 0 3px;

                button{
                    &:before{
                        text-shadow:none;
                        color: #000;
                        @include media-breakpoint-up(md) {
                            color:#fff;
                        }
                    }
                }

            }
            @include media-breakpoint-up(md) {
                bottom: 14px;
            }
        }

        .slide, .slick-slide{

            position: relative;
            > a{
                position: relative;
                display: block;
            }

            &.slick-current{
                .meta{
                    transform: translate3d(0,0,0);
                    opacity:1;
                }
            }

            .meta{
                position:static;
                transform: translate3d(0,20px,0);
                padding: 2em 0.1em 0.5em;
                transition:opacity 1s linear, transform 1.5s ease-out;
                opacity:0;
                text-align: center;
                //font-family: $font-title-sans;
                font-size:8px;
                color:#2a363c;

                &.with-right-logo{
                    @include media-breakpoint-up(md) {
                        padding-right:100px;
                    }
                    @include media-breakpoint-up(lg) {
                        padding-right:150px;
                    }
                }
                
                .title{
                    font-family: $headings-font-family;
                    font-size:2.571em; //36px
                    font-weight: $headings-font-weight;
                    line-height: 1;
                    @include media-breakpoint-up(md) {
                        font-size:1.75em; 
                    }
                    @include media-breakpoint-up(lg) {
                        font-size:2.0em; 
                    }
                    @include media-breakpoint-up(xl) {
                        font-size:2.25em; //2.8em
                    }
                    &.medium{

                        @include media-breakpoint-up(md) {
                            font-size:1.9em;
                            line-height: 1.0;
                        }
                    }
                    
                    &.larger{
                        font-size:3.00em; //42px
                        @include media-breakpoint-up(md) {
                            font-size:2.286em;
                        }
                    }
                    &.smaller{
                        font-size:2.143em; //30px
                        @include media-breakpoint-up(md) {
                            font-size:1.714em;
                            line-height: 1.0;
                        }
                    }
                    &.smaller-mobile{
                        font-size:2.143em;
                        @include media-breakpoint-up(md) {
                            font-size:3.000em;
                        }
                    }
                    &.m-smaller{
                        font-size:1.8em; //30px
                        @include media-breakpoint-up(md) {

                        }
                    }
                }

                .sub-title{
                    //font-family: $headings-font-family;
                    
                    font-size:1.125em; // 1.5em;
                    line-height: 1;
                    margin-top:8px;

                    @include media-breakpoint-up(md) {
                        font-size:1.125em;
                        line-height: 1;
                        margin-top:5px;
                    }
                }

                .range{
                    font-size:1.5em;
                    line-height: 1;

                    @include media-breakpoint-up(md) {
                        font-size:1.286em;
                        line-height: 1;
                    }
                }

                .btn.btn-mobile-dark{
                    border: 1px solid #39331E;
                    color: #39331E;
                    background:none;
                }

                .logo{
                    position: absolute;
                    bottom:100px;
                    width:90px;
                    height:auto;
                    &.right{
                        right:10px;
                    }
                    @include media-breakpoint-up(md) {
                        width:90px;
                        bottom:20px;
                    }
                    @include media-breakpoint-up(lg) {
                        bottom:20px;
                        width:140px;
                        height:auto;
                        &.right{
                            right:20px;
                        }
                    }
                }

                @include media-breakpoint-up(md) {
                    position:absolute;
                    left: 0; bottom:0;
                    padding: 7em 2em 3em ;
                    width: 100%;
                    font-size:12px;
                    color:#fff;
                    text-align: left;
                    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);

                    &.black-light{
                        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
                    }
                    &.black-medium{
                        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
                    }
                    &.white{
                        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                        color:#2a363c;

                    }
                    &.blue{
                        background: linear-gradient(to bottom, rgba(#21799e,0) 0%,rgba(#21799e,1) 100%);
                    }
                    &.clear{
                        background:none;
                    }
                    .btn-white.btn-mobile-dark{
                        border-color:inherit;
                        color:inherit;
                        &:hover{
                            border-color:#ffe284;
                            color:#ffe284;
                            background-color:rgba(0,0,0,0.7);
                        }
                    }
                }
                
                @include media-breakpoint-up(lg) {
                    padding: 7em 2em 3em ;
                    font-size:14px;
                }
            }

  

            @include media-breakpoint-up(lg) {
                //padding:0;
            }
        }

        // 576 -> 767
        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding-top:0;
        }
    }
    
    #SlideChooser{
        height:50px;
        display:none;
        background-color:$body-bg;
        position: relative;
        padding:0 8px;
        top: -7px;
        //opacity:0;
        transition:opacity 0.5s;
        &.enable{
            opacity:1;
        }

        > a {
            display:block;
            flex-grow:1;
            text-align: center;
            font-size: 0.929em;
            color:$brand-text-brown;
            padding:10px 0;
            border-top:4px solid rgba(0,0,0,0);
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            transition:all 0.5s ease;
            background-color:$body-bg;
            &.active{
                border-color:$brand-gold;
                background:lighten($body-bg,10%);
            }
        }

        @include media-breakpoint-up(lg) {
            display:flex;
        }
    }
    */
    // 576 -> 767
    @include media-breakpoint-up(md) {}

    // 768 -> 991
    @include media-breakpoint-up(lg) {}

    // 992px -> 1200px
    @include media-breakpoint-up(xl) {}

    // 1440px +
    @include media-breakpoint-up(xl1440) {}
}