
/*#CloseSubMenu{
    color:#fff;
    font-size:2rem;
    font-family:$font-title-sans;
    margin:4px 0 30px;
    display:flex;
    align-items:center;
    
    i, svg{
        width:20px;
        text-align: center;
    }
    @include media-breakpoint-up(md) {
        display:none;
    }
}*/


.ie{
    .nav-drawer{
        ul.thumbs{
            display:flex;
            @include media-breakpoint-up(md){
                > li{
                    margin:0 0.25rem;
                    width:calc((100% / 7) - 0.5rem);
                }
            }
        }
        .links{
            ul{
                > li{
                    line-height: 100%;
                    width: 100% !important;
                }
            }
        }
    }
}

.nav-drawer{
    
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    padding:4rem 1rem 1rem;
    
    //font-size:12px;
    
    background-size:cover;
    background-repeat: no-repeat;
    background-position:bottom center;
    
    @include media-breakpoint-up(sm) {
       // justify-content: center;
        //padding: 20px 31px;
    }
    
    @include media-breakpoint-up(md) {
       // justify-content: flex-start;
        //padding:20px 10px 40px;
        //margin:0 auto;
        // width: 768px;
    }
    
    @include media-breakpoint-up(lg) {
       // justify-content: center;
        //width: 990px;


    }
    
    @include media-breakpoint-up(xl) {
        //font-size:14px;
        //width:1200px;
        padding:1rem;
    }

    h3{
        font-size:1.5rem;
        //font-weight: 700;
        text-align: left;
        line-height: 1.0;
        color:#afa489;
        margin-top:0;
        margin-bottom:1.5rem;
        @include media-breakpoint-up(lg) {
            font-size:1.5rem;
        }
    }

    ul.thumbs{
        list-style: none;
        padding-left:0;

        @include media-breakpoint-up(xl){
           // -ms-grid-columns: minmax(0, 1fr)[7];
            grid-template-columns: repeat(7, minmax(0, 1fr)) 14%;
            display: grid;
            grid-gap: 0.5rem;
        }

        > li{

            &:hover {
                .meta{
                    color: #ffe4a7;
                }
            }

            &:last-child{

                @include media-breakpoint-up(lg){

                }
            }

            @include media-breakpoint-up(md){

            }

            img{
                border-radius: .125rem;

                @include media-breakpoint-up(lg){
                 //   border-radius: .125rem;
                }
            }

        }
    }

    .meta{
        display:flex;

        font-size: 0.75rem;
        font-weight: 500;
        font-family:$headings-font-family;
        line-height: 1.2;
        color:#e9dec4;
        text-transform: uppercase;


        //text-align: left;
        //background-color:rgba(#a2916a,0.05);

       // transition:background-color 0.3s;

        @include media-breakpoint-up(lg) {
            flex-wrap: wrap;
            justify-content: center;
            text-align:center;
            padding-top:0.5rem;
            //padding-left: 0.25rem;
            //height:2.75rem;
            //font-size: 0.875rem;

        }

        svg.fa-angle-right{
            margin-left:0.5rem;
            font-size:0.625rem;
        }
    }

    button.navbar-toggle {

    }



    .listings{
        display:flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
       @include media-breakpoint-up(md) {
           //width: 700px;
        }
        @include media-breakpoint-up(xl) {

            flex-direction: row;
            width:calc(100% - 210px);
            //margin-right:80px;
        }

        
        .listing-column{
            
            min-width:33.33%;
            
            
            @include media-breakpoint-up(lg) {
                //min-width:210px;
                //min-width:33%;
                //margin-right:30px;
                
            }
            @include media-breakpoint-up(xl) {
               // min-width:280px;
            }
            
            h3{
                margin-bottom: 10px;
                text-align: right;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                @include media-breakpoint-up(xl) {
                    display: block;
                    text-align: left;
                    margin-bottom:20px;
                }
                &:after{
                    content:"";
                    display:block;
                    width:54px; height:1px;
                    background:rgb(#a2916a,0.3);
                    margin-top: 12px;
                    @include media-breakpoint-up(xl) {
                        margin-top: 19px; 
                    }
                }
            }
            
            ul{
                list-style: none;
                padding:0;
                margin-bottom: 30px;

                @include media-breakpoint-up(xl) {
                    margin-bottom: 0;
                }
  
                li{
                    margin-bottom:4px;
                    @include media-breakpoint-up(xl) {
                        margin-bottom:0;
                    }
                    a{
                        //color:#fff7e5;
                        font-size:0.875rem;
                        font-family: $headings-font-family;
                        font-weight: $headings-font-weight;
                        @include media-breakpoint-up(xl) {
                            font-size:1.125rem;
                        }
                        i, svg{
                            color:#a2916a;
                            font-size:0.786em;
                            margin-left:5px;
                        }
                        &:hover{
                            color:#fff;
                        }
                    }
                }
                
            }
        }
    }
    
    /*.popular{
        @include media-breakpoint-up(lg) {
            //width:458px;
           // margin-right:25px;
        }
        @include media-breakpoint-up(xl) {
            //width:560px;
            //margin-right:40px;
        }
        @include media-breakpoint-up(xl1440) {
            //margin-right:80px;
        }
        ul{
            
            list-style:none;
            margin:-2px; padding:0;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            align-items: center;
            
            @include media-breakpoint-up(xl) {
                margin:-5px;
            }
            
            > li{
                margin:2px;
                width:100%;
                
                @include media-breakpoint-up(md) {
                   // width:auto;
                    //width:calc(33.3% - 8px);;
                    width: calc(33.3% - 4px);
                    
                }
                
                @include media-breakpoint-up(lg) {
                    //width: calc(33.3% - 4px);
                }
                
                @include media-breakpoint-up(xl) {
                    width:auto;
                    margin:5px;
                }
            }
            
            a{
                display:flex;
                flex-direction: row;  
                
                width:100%; height:auto;
                align-items: center;
                border-radius: 2px;
                overflow: hidden;
                //box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15);
                img{
                    width:100px;  height:auto;
                }
                i, svg{
                    font-size: 0.786em;
                    display:inline;
                }
                &:hover{
                    .meta{
                        color:#dbd0b7;
                        background-color:rgba(#a2916a,0.08);
                        //background-color:rgba(#1d1a14,1);
                    }
                }
                
                @include media-breakpoint-up(md) {
                    
                    
                    flex-direction: column;
                    align-items: flex-start;
                    img{
                        width:100%;  height:auto;
                    }
                }
                @include media-breakpoint-up(lg) {
                    width: 150px;
                    height: 150px;
                }
                
                @include media-breakpoint-up(xl) {
                    width:180px; height:180px;
                    img{
                        width:180px;  height:120px;
                    }
                }
            }

            
        }

        .meta{
            width: 100%;
            font-size:1.2em;
            
            letter-spacing: 0.07em;
            align-self: stretch;
            span{
                !*&:after{
                    content:"\f105";
                    display:inline;
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
                    font-size: 0.786em;
                    padding-left: 5px;
                }*!
            }

            @include media-breakpoint-up(md) {
                font-size:0.857em;
                height:60px;
                align-self: auto;
            }
            @include media-breakpoint-up(xl) {
                height:60px;
            }
        }
    }*/
    

    
    &.groups{
        justify-content: center;
        height: 100%;
        align-content: flex-start;
        @include media-breakpoint-up(sm) {
            
        }

        @include media-breakpoint-up(md) {
            padding:30px 10px 40px;
        }

        @include media-breakpoint-up(lg) {
            align-content: center;
        }

        @include media-breakpoint-up(xl) {
           // padding:10px 10px 10px;
        }
    }

    .group-links{
        display:flex;
        flex-direction: column;
        //margin-top: 20px;
        
        @include media-breakpoint-up(md) {
            flex-direction: row;
            margin-top: 0;
        }
        
        a{
            padding:20px 30px;
            font-size: 1.25em;
            font-weight: $headings-font-weight;
            font-family:$headings-font-family;
            line-height: 1.2;
            color:#a2916a;
            background-color:rgba(#a2916a,0.05);
            transition:background-color 0.3s;
            text-align: center;
            display:flex;
            justify-content: center;
            align-items: center;
            margin:0 0 20px;
            //box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15);
            &.overview{
                color:#e6d7b4;
            }
            
            @include media-breakpoint-up(md) {
                margin:0 5px 0;
            }
            span{
               /* &:after{
                    content:"\f105";
                    display:inline;
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
                    font-size: 0.729em;
                    padding-left: 5px;
                }*/
            }

            &:hover{
                color:#ffe4a7;
            }

            @include media-breakpoint-up(lg) {
                font-size: 0.875em;
            }
        }
        
        
        .overview{
            
        }

    }
    
    &.rates{
        
        .popular{
            @include media-breakpoint-up(lg) {
                width: 638px;
                ul{
                    a{
                        width:auto;
                        height:auto;
                    }
                }
            }
            @include media-breakpoint-up(lg) {
                width: 848px;
                ul{
                    a{
                        img{
                            width:260px;
                            height:auto;
                        }
           
                    }
                }
            }
        }
        
        .overview-links{
            @include media-breakpoint-up(lg) {
                width: 240px;
            }
        }
        
        .thumbs{

            @include media-breakpoint-up(md) {
                
            }
        }
        
        .pullquote{
            position:relative;
            
            @include media-breakpoint-up(lg) {
                left:38px;
            }
        }
    }

    &.activities{

        padding: 20px 20px;
        
        @include media-breakpoint-up(md) {

        }
        @include media-breakpoint-up(lg) {
            //justify-content: flex-start;
            //padding: 20px 30px 30px;
            //margin:0 auto;
            //width:1200px;
            
            //padding: 20px 30px 0;
        }

        @include media-breakpoint-up(xl) {
            padding: 30px 0 50px;
            width:1200px;
            margin: 0 auto;
        }
        .links{
            @include media-breakpoint-up(md) {
                //width: 700px;
            }
            @include media-breakpoint-up(xl) {
                //width:25%;
                width:210px;
                margin-top: 0;
            }
            /*@include media-breakpoint-up(lg) {
                width:180px;

            }
            @include media-breakpoint-up(xl) {
                width:180px;

            }*/

        }

        a{
            color:#e9dec4;
        }

    }
    
  /*  .overview{
        margin-bottom:30px;

        @include media-breakpoint-up(md) {
            //margin-right:25px;
            margin-bottom:0;
        }
        @include media-breakpoint-up(lg) {
            margin-right:25px;
        }
        @include media-breakpoint-up(xl) {
            //margin-right:40px;
            margin-right:80px;
        }
        @include media-breakpoint-up(xl1440) {
            //margin-right:80px;
        }

        h3{
            display:none;
            @include media-breakpoint-up(md) {
                display:block;
            }
        }
        
        > a{
            display: block;
            border-radius: 2px;
            overflow: hidden;
            //box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15);
            img{
                width:100%; height:auto;
            }
            
            i, svg{
                font-size:0.929em;
            }
            
            &:hover{
                .meta{
                    color:#dbd0b7;
                    background-color:rgba(#a2916a,0.08);
                }
            }
            
            @include media-breakpoint-up(md) {
                height:225px;
                img{
                    width:225px; height:150px;
                }
            }

            @include media-breakpoint-up(xl) {
                height:270px;
                img{
                    width:270px; height:180px;
                }
            }
        }
        
        .meta{
            height:75px;
            font-size:1.5em;
            letter-spacing: 0.05em;

            span{
                !*&:after{
                    content:"\f105";
                    display:inline;
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
                    font-size: 0.729em;
                    padding-left: 5px;
                }*!
            }
            @include media-breakpoint-up(lg) {
                font-size:1.143em;
            }
            @include media-breakpoint-up(xl) {
                height:90px;
            }
        }
    }*/

  /*  .overview-links{
        display:flex;
        flex-direction: column;
        margin-top: 20px;
        width: 100%;
        @include media-breakpoint-up(md) {

        }
        @include media-breakpoint-up(lg) {
            //width:470px;
            width: 210px;
            margin-top: 42px;
        }
        @include media-breakpoint-up(xl) {
            //width:560px;
            width: 210px;
            margin-top: 0;
        }
        > div:first-child{
            display:flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
            @include media-breakpoint-up(lg) {
                flex-wrap: wrap;
                flex-direction: row;
            }
            @include media-breakpoint-up(xl) {
                flex-wrap: nowrap;

            }
        }
    }*/


    .links {

        margin-top: 30px;
        background-color: #13120f;


        @include media-breakpoint-up(md) {
            margin-top: 0;

        }
        @include media-breakpoint-up(lg) {

        }
        @include media-breakpoint-up(xl) {
            //width:210px;
            margin-top: 0;
            background:linear-gradient(0deg, rgba(24, 21, 16, 0) 0%, rgba(24, 21, 16, 1) 100%);
        }


        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            //display: flex;
            //flex-wrap: wrap;
            //flex-direction: column;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {

                /*flex-wrap: wrap;
                flex-direction: row;*/
            }
            @include media-breakpoint-up(xl) {
                //flex-direction: row;
                flex-wrap: nowrap;
                flex-direction: column;
            }

            > li {

                line-height: 1;
                padding: 0 1rem;
                text-align: center;
                @include media-breakpoint-up(md) {


                }
                @include media-breakpoint-up(xl) {
                    margin-bottom: 0.75rem;
                    text-align: left;
                }

                &:first-child:not(.profile-link){
                    margin-top:1rem;
                }

                a {
                    color: #bfb8a0;
                    font-size: 0.875rem;
                    font-family: $headings-font-family;
                    font-weight: 500;
                    text-transform: uppercase;
                    line-height: 1.2;
                    display: block;
                    padding: 0.75rem 0;
                    justify-content: flex-end;

                    @include media-breakpoint-up(xl) {
                        font-size: 0.75rem;
                        letter-spacing: 0;
                        display: inline;
                        padding: 0;
                    }

                    &:hover {
                        color: #ffe4a7;
                    }

                    svg {
                        display: inline-block;
                    }

                    &.btn {
                        text-transform: none;
                        border: 1px solid #362d1a;
                        display: inline-block;
                        padding: 6px 18px;
                    }
                }
                &.downloads{
                    display: flex;
                    padding-top:10px;
                    border-bottom: none;
                    justify-content: center;
                    + .downloads{
                        padding-top:0;
                    }

                    @include media-breakpoint-up(md) {
                        padding-top:10px;
                        + .downloads{
                            padding-top:10px;
                        }
                    }

                    @include media-breakpoint-up(xl) {
                        justify-content: flex-start;
                        padding-top:10px;
                        + .downloads{
                            padding-top:0;
                        }

                    }

                    margin-bottom: 0;

                    a{
                        display: flex;
                        font-size:1rem;

                        @include buttonFont();
                        letter-spacing: 0.1rem;
                        color:#dbd0b7;
                        margin-bottom:15px;
                        line-height: 1.2;
                        align-items: center;

                        i, svg{
                            font-size:1.875rem;
                            margin-right: 4px;
                            width:34px;
                        }
                        span{
                            width:calc(100% - 34px);
                        }
                        &:hover{
                            color:#fff;
                        }
                        @include media-breakpoint-up(xl) {
                            font-size: 1rem;
                        }
                    }

                }
                &.profile-link{

                    padding:0;

                    a{
                        background-color:#0a0907;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        padding:0.5rem;
                        @include media-breakpoint-up(xl) {
                            justify-content: flex-start;
                        }
                        img{
                            border-radius: 100%;
                            width:30px; height:30px;
                            margin-right:0.5rem;
                        }

                        .title{
                            color:#bfb8a0;
                            font-size:0.875rem;
                        }
                        .name{
                            color:#fffaee;
                            font-size:0.75rem;
                        }
                    }

                }
            }
        }


    }

    
    /*.main-content{
        display: flex;
        flex-direction: column;
        
        > div:first-child{
            @include media-breakpoint-up(md) {
               // margin-right: 25px;
            }
            @include media-breakpoint-up(lg) {
                //margin-right: 0;
            }
        }
        
        @include media-breakpoint-up(md) {
            //flex-direction: row;
        }
        @include media-breakpoint-up(lg) {
            //flex-direction: row;
            //width: calc(100% - 210px);
        }
    }*/
    
}


.top-tools{
    background-color:rgba($brand-gold,0.075);
    width:100%;
    padding:1.5rem 20px;
    margin-top: 3rem;
    font-weight: $headings-font-weight;
    font-family: $headings-font-family;
    border-bottom:1px solid rgba($brand-gold, 0.1);

    > div{
        margin:0 auto;

        @include media-breakpoint-up(md) {
            width:100%;
        }
        @include media-breakpoint-up(lg) {
            
        }
        @include media-breakpoint-up(xl) {
            width:1200px;
        }
    }
    
    
    @include media-breakpoint-up(md) {


    }
    @include media-breakpoint-up(xl) {

        padding:10px 30px;
        margin-top: 0;
        
    }

    a{
        display: block;
        
        svg{
            margin-left:5px;
        }
        
        &.btn.btn-outlined{
            color: #a5976a;
            &.overview{
                color:#e6d7b4;
                border-color:#766a3f;
                &:hover{
                    border-color: #ffe284;
                    color: #ffe284;
                }
            }
        }     
        
        &:not(:last-child){
            margin: 10px 0;
            //padding-bottom:10px;
            border-bottom:none;
            @include media-breakpoint-up(xl) {
                margin: 0 30px 0 0;
            }
        }



        @include media-breakpoint-up(md) {


            &:not(:last-child){
                //border-bottom:1px solid rgba($brand-gold, 0.15);
            }

        }

        @include media-breakpoint-up(lg) {

        }

        @include media-breakpoint-up(xl) {
            margin-right:50px;
            display:inline;
        }
    }
}
