#Home{

    #Activities{


        // 768 -> 991
        @include media-breakpoint-up(lg) {


        }

        // 992px -> 1200px
        @include media-breakpoint-up(xl) {

        }

        // 1440px +
        @include media-breakpoint-up(xl1440) {

            //padding: 0 0 40px;
        }
        
        
        .bin{
            background-color: rgba(white,0.5);
            
            /*.img-wrap:after{
                content: "";
                position: absolute;
                width: 100%;
                height: 210px;
                background: red;
                bottom: 0;
                left: 0;
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
            }*/
        }
    }

   /* .activity-gallery{
        //opacity:0;
        margin:0 20px 30px;
        padding: 0;
        &.slick-initialized{
            opacity:1;
            
            .slick-slide{

                @include media-breakpoint-up(md) {
                    margin: 0 5px;
                }
            }
        }
        .slick-dots li button:before{
            color:black;
        }
        .bin{
            display:block;
            margin: 5px;
            .imgWrap{

            }
        }
        .slick-next,.slick-prev{
            &:hover:before{
                color:$brand-gold;
                font-weight: 400;
            }
        }
        .slick-next{
            right:-50px;
        }
        .slick-prev{
            left:-50px;
        }
    }*/

    // 576 -> 767
    @include media-breakpoint-up(md) {

    }

    // 768 -> 991
    @include media-breakpoint-up(lg) {



    }

    // 992px -> 1200px
    @include media-breakpoint-up(xl) {

    }

    // 1440px +
    @include media-breakpoint-up(xl1440) {


    }
}