#Groups2{
    
    .venues-grid{

        
        .filters{
            
            text-align: center;
            margin-bottom:10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            
            span{
                font-size:1.286em;
                //color:#bfbfbf;

                @include media-breakpoint-up(lg) {
                    font-size:1.0em;
                    margin-right: 20px;
                }
            }
            @include media-breakpoint-up(md) {
                margin-bottom:0;
                flex-direction: row;
                align-items: flex-start;
            }

            
            a{
                font-family:$headings-font-family;
                font-size: 1.143em;
               
                margin: 0 20px 7px;
                white-space: nowrap;
                border-bottom:2px solid transparent;
                &.active{
                    color:$brand-gold;
                    border-bottom:2px solid $brand-gold;
                }

                @include media-breakpoint-up(md) {
                    //font-size: 1.286em;
                    padding-bottom:10px;
                    flex-direction: row;
                    margin: 0 20px;
                }
                
                @include media-breakpoint-up(lg) {
                    //font-size: 1.429em;
                }
            }
            
        }
        
        .grid-area{
            padding-top:30px;
            padding-bottom:30px;
            @include media-breakpoint-up(md) {
                
            }

            @include media-breakpoint-up(lg) {
                
            }
        }

        .center-up{

            display:flex;
            flex-wrap:wrap;
            justify-content: center;
        }
        $mobileGridItemHeight: 70px;
        
        .grid-item{
            margin:0 0 5px;
            display: flex;
            flex-direction: row;
            width: 100%;
            height:$mobileGridItemHeight;
            @include media-breakpoint-up(md) {
                margin:0 5px 10px;
                flex-direction: column;
                width:200px;
                height:auto;
            }

            @include media-breakpoint-up(lg) {
                
            }
            
            div.img-wrap{
                width:$mobileGridItemHeight;
                height:$mobileGridItemHeight;

                @include media-breakpoint-up(md) {
                    width:auto;
                    height:auto;

                }
                @include media-breakpoint-up(lg) {

                }
            }

            
            .meta{
                background-color:#fff;
                padding:15px;
                height:$mobileGridItemHeight;
                position:relative;
                width:calc(100% - #{$mobileGridItemHeight});
                h5{
                    font-size:1.000em;
                    margin:0 0 5px;
                }
                
                p{
                    font-size:0.857em;
                    line-height: 1.1;
                }
                .cta{
                    position:absolute;
                    top: 5px;
                    right: 2px;
                    display:flex;
                    align-items: center;
                    i,svg{
                        font-size: 0.857em;
                        
                    }
                    span{
                        display:none;
                        font-size:0.857em;
                        font-family: $font-family-sans-cond;
                        opacity: 0;
                        transition:opacity 0.2s;
                        text-transform:uppercase;
                        
                    }
                    
                }


                @include media-breakpoint-up(md) {
                    width:auto;
                    height:145px;
                    h5{
                        font-size:1.071em;
                        margin:0 0 10px;
                    }
                    p{
                       // font-size:1.000em;
                    }
                    .cta{
                        top:auto;
                        right:auto;
                        bottom:15px;
                        left:14px;
                        i,svg{
                            font-size: 1.429em;
                            margin-right:10px;
                        }
                        span{
                            font-size:0.929em;
                            display:inline;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    h5{
                        font-size: 1.071em;
                    }
                }
            }
            
            &:hover{
                .meta{
                    .cta{
                        span{
                            opacity:1;
                        }
                    }
                }
            }
            
        }
    }
    
    .capacity-modal{
        
        h2{
            font-size:22px;
            margin-bottom:0;
        }
        
        h3{
            font-size:16px;
            margin-top:0;
            font-weight:400;
            font-family: $font-family-sans-serif;
        }
        
        .stat{
            margin-bottom:5px;
            padding-bottom: 5px;
            
            &:not(:last-child){
                border-bottom:1px solid rgba($brand-gold,0.1);
            }
            .value{
                font-weight:700;
                span{
                    color:rgba(black,0.2)
                }
            }
            .desc{
                line-height:1.2;
                margin-bottom:5px;
            }
            
        }
    }
}