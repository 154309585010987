#Resort{

	div.map{
		background-color:#f5f5f5;
		padding-bottom:40px;
		.pullquote{
			position:relative;
			//top:-40px;
		}
	}
	
	section#GreenOBanner{
		padding:10px 0;
	}
	
	.left-right-content-modules{
		.meta{
			h2{
				margin-bottom: 20px;
				@include media-breakpoint-up(md) {
				//@media (min-width: $screen-sm-min) {
					margin-bottom: 30px;
				}
			}

		}
	}
	
	.button-farm{

		> a{
			width:100%;
			margin-top: 0;
			margin-bottom:10px;
			font-size: 12px;
			padding-left: 20px;
			padding-right: 20px;
			@include media-breakpoint-up(xs) {
				font-size: 14px;
				
			}
		}
		
		// 768 -> 991
		@include media-breakpoint-up(md) {
		//@media (min-width: $screen-sm-min) {
			flex-wrap: wrap;
			display: flex;
			margin:0 -5px;
			
			> a{
				width: calc(50% - 10px);
				margin: 5px;
			}

			
		}
	}
	

}



