.block-video{
    margin:15px auto;
    padding:15px 0;

    .titleblock{
        margin-bottom:30px;
    }

    &.dark{
        padding:30px 0;
        margin:0 auto;
        background-color:$brand-brown-bg;
        color: $brand-text-over-brown;

        .wrap-all-video-components{
            padding-bottom:40px;
        }
        .main-titles{
            @include titleUnderBar(50px,2px,true);

        }
        .video-image-caption{
            span{
                color: lighten($brand-gold, 20%);
            }
        }
    }
}
