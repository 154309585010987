
#Takeover{

	display:none;
	position:relative;
	top:0;
	//margin-top:48px;
	margin-bottom:0;
	border-bottom: 4px solid white;

	@include media-breakpoint-up(lg) {
		margin-top:0;
		margin-bottom:-1px;
		border-bottom-width: 0.5rem;

		button{
			background:none;
			top: -5px;
			right: 3px;
			font-size: 20px;
			i,svg{
				color:#fff;
			}
			&:hover{
				i,svg{
					color:#89e4f5;
				}
			}
		}
	}








	&.activate{
		display:block;
	}
	
	button{
		background:rgba(white,0.5);
		outline:none; border:none;
		position:absolute;
		top: 0;
		right: 0;
		font-size: 30px;
		line-height: 1;
		padding: 8px 6px;
		i,svg{
			color:#000;
		}
		&:hover{
			i,svg{
				color:#89e4f5;
			}
		}
	}
	.slick-vertical .slick-slide{
		border:none;
	}


}

.takeover-header{
	width:100%;
	
	display:block !important;
	

}

