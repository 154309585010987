.new-design-2019{
    
    &#Accommodations{

        #Hero{
            .slides{
                margin-bottom: -5px;
            }
/*            @include media-breakpoint-up(md) {
                min-height:339px;
            }
            @include media-breakpoint-up(lg) {
                min-height:440px;
            }
            @include media-breakpoint-up(xl1440) {
                min-height:642px;

            } */ 
        }

        .pullquote{
            max-width:700px;
            margin:50px auto;
        }
        
        .extra{
            display:flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin:30px 5px 10px;
            
            
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
            
            > a{
                display:block;
                margin:0 5px 10px;
                position:relative;
                
                @include media-breakpoint-up(md) {
                    margin:0 5px
                }
                
                .meta{
                    position:absolute;

                    bottom:0;
                    width:100%;
                    
                    background-color:rgba(#1e1c15,0.8);
                    color:white;
                    padding:30px;
                    
                    
                    @include media-breakpoint-up(md) {
                        height:160px;
                        width: calc(50% - 5px);
                        margin-top:0;
                    }
                    @include media-breakpoint-up(lg) {
                        height:200px;
                    }
                    @include media-breakpoint-up(xl) {
                        width:460px;
                        left:50%;
                        bottom:50px;
                        transform:translateX(-50%);
                        
                    }
                    
                    svg.fa-arrow-circle-right{
                        font-size:3.286em;
                        text-align: center;
                        display:block;
                    }
                    
                    h2{
                        font-size:1.25em;
                        margin:0 0 10px;
                        @include media-breakpoint-up(lg) {
                            font-size:1.75em;
                        }
                        @include media-breakpoint-up(xl) {
                            font-size:2.186em;
                        }
                    }
                    
                    p{
                        font-size:1.0em;
                        line-height:1.2;
                        
                        @include media-breakpoint-up(md) {
                            height:34px;
                        }
                        @include media-breakpoint-up(lg) {
                            height:44px;
                        }
                        @include media-breakpoint-up(xl) {
                            font-size:1.143em;
                        }
                    }
                    
                }
            }
        }

        .glance{
            background-color:#fff;
            color:$brand-text-brown;
            text-align: left;
            padding:30px;
            h2{
                text-align: center;
            }
            .btn.plus{
                margin-bottom:20px;
                text-align: center;
                width: 100%;
            }
            a{
                text-decoration: none;
            }
            .homes-with-tents-table{
                td{
                    em{
                        font-family: $font-family-sans-serif;
                        font-size:11px;
                    }
                }
            }

        }
        
        .map{
            position:relative;
            padding-left: 0;
            padding-right: 0;
            max-width: none;
            margin-bottom:10px;
            &.white{
                .legend{
                    background-color:rgba(white,0.8);
                    color:#000;
                    li{
                        
                        > div{
                            > div{
                                color:rgba(black,0.6);
                            }
                        }
                        span:first-child{
                            background-color:black;
                            color:#fff;
                        }
                        
                    }
                    &.large-dots{
                        li{
                            span:first-child{
                                border-radius: 24px;
                                width:27px; height:27px;
                            }
                        }
                    }
                    &.centered-text{
                        li{
                            align-items: center;
                        }
                    }
                }
            }
            .legend{
                position:absolute;
                background-color:rgba(black,0.8);
                color:#fff;
                padding:30px;
                display:none;
                
                h3{
                    margin:0 0 10px;
                    font-size:1.286em;
                }
                
                ul{
                    list-style: none;
                    margin:0;
                    padding:0;
                }
                
                li{
                    font-size:1.0em;
                    font-family: $headings-font-family;
                    font-weight: $headings-font-weight;
                    display:flex;
                    align-items: flex-start;
                    &:not(:last-child){
                        margin-bottom:5px;
                    }
                    > div{
                        > div{
                            color:rgba(white,0.6);
                            font-size:10px;
                            margin-top:-2px;
                        }
                    }
                    span:first-child{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color:white;
                        color:#000;
                        border-radius: 22px;
                        width:21px; height:21px;
                        font-size:1.143em;
                        margin-right:10px;
                    }
                    span:last-child:not(:first-child){
         
                    }
                }

                .footer{
                    margin-top:20px;
                }
                &.bottom{
                    bottom:80px;
                }
                &.top{
                    top:80px;
                }
                &.left{
                    left:80px;
                }
                &.right{
                    right:80px;
                }
                
                @include media-breakpoint-up(lg) {
                    display:block;
                }
            }
        }
        
        .accommodations-browser{
            padding:30px 15px;
            background-color:#201e17;

            @include media-breakpoint-up(md) {
                
            }
            @include media-breakpoint-up(lg) {
                padding:30px 80px 50px;
            }
            @include media-breakpoint-up(xl) {
               
            }
            
            .titleblock{
                margin-bottom: 0;
            }
            
            h2{
                font-size:1.714em;
                color:#a2916a;
                text-align: center;
                margin-bottom:30px;
            }

            .seeAllLink{
                font-size:1.143em;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                color:#a2916a;
                text-align: center;
                display:block;
                //margin-top: 50px;
                
                &:hover{
                    color: lighten(#a2916a, 15%);
                }
            }
            
            
            .slider{
                .slick-prev:before, .slick-next:before{
                    color:rgba(white,0.6);
                }

                .slick-prev, .slick-next{
                    &:hover{
                        &:before{
                            color:rgba(white,1);
                        }

                    }
                }

                .slick-prev{
                    left: -58px;
                }
                .slick-next{
                    right: -58px;
                }
                .slick-next:before{
                    content:'\f0a9';
                    font-weight: 300;
                }
                .slick-prev:before{
                    content:'\f0a8';
                    font-weight: 300;
                }
            }
            .slide{
                position:relative;
                padding: 0 5px;
               
            }

            .bin{
                border-bottom:none;
                
                h5{
                    span{
                        color: #baa87b;
                    }
                }
            }
            .meta{
/*                position:absolute;
                bottom:0;
                left:0;
                width:100%;
                
                padding:15px 30px;
                background-color:rgba(#1e1c15,0.8);
                color:white;
                font-size:1.143em;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                h3{
                    color:white;
                    font-size:1.143em;
                    
                }*/
                
                height: auto;
                align-items: center;
                display: flex;
                
                @include media-breakpoint-up(lg) {
                    
                }
            }
        }

        .modal-container.rate-desc{
            p{
                font-size:1em;
            }
        }
        
        .modal-container.floorplans{


            .tabs{
                display:flex;
                margin-bottom:30px;
                flex-wrap: wrap;
                justify-content: center;
                @include media-breakpoint-up(lg) {
                    justify-content: flex-start;
                }
            }
            .tab{
                background:#fff;
                font-size:1.0em;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;                
                padding:7px 14px;
                text-transform:none;
                border:1px solid #ddd;
                justify-content: center;
                margin-bottom:4px;
                width: 100%;
                &.active,&:active{
                    background:#ddd;
                    border: 1px solid #ddd;
                    box-shadow:none;
                    color:black;
                }
                @include media-breakpoint-up(lg) {
                    justify-content: flex-start;
                    width: auto;
                }
            }
            
            .sqft{
                position:absolute;
                
                
                font-size:16px;
                
                &.bottom{
                    bottom:30px;
                }
                &.left{
                    left:0;
                }
                &.right{
                    right:0;
                }
            }
        }
        
        .modal-gallery{
            .modal-body {
                padding:60px 15px;
    

                
                @include media-breakpoint-up(sm) {
                    padding:0 90px; 
                }
                @include media-breakpoint-up(md) {
                    padding:30px 110px;
                }
                @include media-breakpoint-up(lg) {
                    padding:30px 110px;
                }
                @include media-breakpoint-up(xl1440) {
                    padding:30px 110px;
                }
            }
            
            
        }
        
        section.dining{
            background-color: #f4f4f4;
            padding-top: 20px;
            padding-bottom: 20px;
            .honeymoon-dining{
                
                display:flex;
                flex-direction: column;
                .meta{
                    padding:15px;
                    background:white;
                    display:flex;
                    justify-content: center;
                    flex-direction: column;
                }
                
                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                    .meta{
                        padding:30px;
                    }
                    
                    img{
                        width:50%;
                    }
                }
            }
            
        }
    }
    

    .camp-buyout-accordion{
        
        min-height: 0;
        margin-bottom:0;
       
        background-color:white;
        
        .v-collapse-content{
            transition: max-height 0.3s ease-out;
        }
        .v-collapse-content-end{
            max-height: 864px;

            @media (min-width: 2440px) {
                max-height: 1144px;
            }
        }
        
        
        .padding-box{

            margin-top:-60px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size:cover;
            @include media-breakpoint-up(md) {
                margin-top:-60px;
            }
            @include media-breakpoint-up(lg) {
                position:relative;
                height:0;
                overflow: hidden;
                padding-top: 864px / 1920px * 100%;
            }
            @include media-breakpoint-up(xl) {
                margin-top:-72px;
            }
            @include media-breakpoint-up(xl1440) {
                margin-top:-98px;
            }
        }
        
       /* .vc-collapse{

            position:relative;
            height:0;
            overflow: hidden;
            padding-top: 1093px / 1920px * 100%;

        }*/
        
        .expandable{
            
          //  margin-bottom: 4px;
            border-top:2px solid white;
            border-bottom:2px solid white;
            
            &.active{
                
                .top{
                    background-color:transparent;
                    background-image:none;
                    border-bottom:none;
                }
                .info{
                    .btn.plus{
                        color:white;
                        
                        svg{
                            transform: rotate(135deg);
                        }
                        
                        &:hover{
                            color:white;
                        }
                    }
                }
                .titles{
                    opacity: 0;
                }

                .v-collapse-content{
                    overflow: visible;
                }
            }
        }

        .title-slice{
            background-color: rgba(#f8f4ed, 1);
            color:black;
            margin-bottom: 4px;
            cursor: auto;
            h2{
                font-size:14px;

                @include media-breakpoint-up(md) {
                    font-size:24px; 
                }
            }
        }
        
        .info{
            .btn.plus{

                &:hover{
                    color:black;
                }
            }
        }

        .top{
            //background-color: rgba(#f8f4ed, 1);
            background-color: rgba(#fcfaf5, 1);
            background-repeat: no-repeat;
            background-position: center center;
            background-size:cover;
            position: relative;
            z-index: 2;
            border-bottom: 1px solid #ece9d9;

            @include media-breakpoint-up(md) {
               // background-size:contain;
            }
        }
        
        .meta{
            position: relative;
            background-color: transparent;
            color:black;
            //transition:background-color 0.2s;
            z-index: 1;
            height:60px;
            max-width: 1440px;
            margin: 0 auto;
            @include media-breakpoint-up(md) {
                
            }
            @include media-breakpoint-up(xl) {
                height:72px;
            }
            @include media-breakpoint-up(xl1440) {
                height:98px;
            }
            
            .titles{
                //transition:opacity 0.2s;
                
                h5{
                    margin:0 0 5px;
                    font-family: $font-family-sans-serif;
                    font-weight: 400;
                    font-size: 11px;
                    @include media-breakpoint-up(md) {
                        font-size: 14px;
                    }
                    @include media-breakpoint-up(xl1440) {
                    }
                }
                h2{
                    margin:0;
                    font-size: 1.0em;
                    @include media-breakpoint-up(md) {
                        font-size: 1.15em;
                    }
                    
                    @include media-breakpoint-up(xl) {
                        font-size: 1.75em;
                    }
                    @include media-breakpoint-up(xl1440) {
                        font-size: 2.0em;
                    }
                }
            }
        }

        .content{

            max-width: none;
            padding:0;
            position: relative;
            
            @include media-breakpoint-up(lg) {
                position: absolute;
                top:0; left:0;
                width:100%;
                height:100%;
            }
            
            @include media-breakpoint-up(xl) {
               // max-width: none;
            }
            
            @include media-breakpoint-up(xl1440) {
                //max-width: 1440px;
            }
            
            img{
                /*
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                */

                @include media-breakpoint-up(lg) {
                   // position:absolute;
                }
            }
            
            &:before{
                content:"";
                position:absolute;
                top:0; left:0;
                width:100%;
                height:80px;
                z-index: 1;
                background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);

                @include media-breakpoint-up(lg) {
                    height:150px;
                }
            }
            
            .centered-content{
                

                @include media-breakpoint-up(lg) {
                    position: absolute;
                    top:0;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    transform: translateX(-50%);
                }
                @include media-breakpoint-up(xl) {
                    max-width: 1180px;
                }
                @include media-breakpoint-up(xl1440) {
                    max-width: 1280px;
                }
            }
            
            .meta-window{

                width:100%;
                background-color: rgba(white,0.95);
                padding:30px 30px;

                @include media-breakpoint-up(md) {
                    padding: 15px 40px;
                }
                @include media-breakpoint-up(lg) {
                    position:absolute;
                    left:0;
                    bottom:0;
                    &.right{
                        left:auto;
                        right:0;
                    }
                }
                @include media-breakpoint-up(xl) {
                    width:420px;
                    left:0;
                    bottom:20px;
                    padding: 25px 20px;
                }
                @include media-breakpoint-up(xl1440) {
                    width:480px;
                    bottom:60px;
                    padding: 50px 40px;
                }
            }
            
            h5{
                margin-top:0;
                font-size: 11px;
                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
            }

            h2{
                margin-bottom: 10px;
                font-size: 20px;

                @include media-breakpoint-up(md) {
                    font-size: 30px;
                }
                
                @include media-breakpoint-up(xl) {
                    margin-bottom: 15px;
                }
                @include media-breakpoint-up(xl1440) {
                    margin-bottom: 25px;
                }
            }
            
            p{
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                font-size: 11px;
                
                @include media-breakpoint-up(md) {
                    
                }
                @include media-breakpoint-up(xl) {
                    line-height: 2.3;
                    font-size: 14px;
                }
            }
            
            .btn{
                background-color: #e0d6c2;
                border-radius: 30px;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                text-transform: none;
                color:$brand-brown;
                margin-top: 10px;
                display:inline-flex;
                align-items: center;
                justify-content: center;
                padding:6px 6px 6px 20px;
                transition:all 0.2s ease;
                width: 100%;
                @include media-breakpoint-up(md) {
                    width: auto;
                }
                @include media-breakpoint-up(xl) {
                    margin-top: 15px;
                }
                @include media-breakpoint-up(xl1440) {
                    margin-top:25px;
                }
                svg{
                    font-size:20px;
                    margin-left:10px;
                }
                
                &:hover{
                    background-color: lighten(#e0d6c2,10%);
                }
            }
            
        }
        
    }
}


