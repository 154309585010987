#FriendsOfTheRanch{

    #app{
        padding-top: 0;
    }

    #SidePopup{
        //z-index: 13;
        width: 100%;
        // 414 -> 767
        @media (min-width: 414px) {
            width:400px;
        }
        // 
        @media (min-width: 1024px) {
            width:640px;
            padding:50px 0 65px 40px;
        }
        
        .closePopupBtn{
            display:block;
        }
        
        .titleblock{
            h2{
                font-size:2.75rem;
            }
        }
        
        .item{
            h5{
                margin-bottom: 5px;
            }
            h3{
                margin-top:0;
            }
        }
        
    }
    main{
        transition: transform 0.3s ease;
        z-index: 1;
        position:relative;
        background-color: #e9e6d8;
    }
    &.show-side-popup{
        overflow: hidden;
        main{
            transform:translate3d(-400px,0,0);
            //pointer-events: none;
            position:relative;
            @media (min-width: 1024px) {
                transform:translate3d(-640px,0,0);
            }
            &:after{
                content:"";
                display:block;
                position:absolute;
                top:0; left:0; bottom:0; right:0;
                background-color:rgba(white,0.8);
            }
        }
        #SidePopup{
            .closePopupBtn{
                display:block;
            }
        }
    }
    .center-up-and-over{
/*        position:absolute;
        top:0;
        left:50%;
        transform:translateX(-50%);
        width: 100%;*/
        //margin-top: -660px;
    }
    
    .btn.btn-primary{
        background-color:#6b685b;
        &:hover{
            background-color: lighten(#6b685b,5%);
        }
    }
    .content{
        
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        
        min-height: 600px;
        position:relative;
        
        .disclaimer{
            
            position:absolute;
            width: calc(100% - 20px);
            bottom:10px;
            left:50%;
            transform:translateX(-50%);
            padding:2px 10px 4px;
            background:rgba(white,0.8);
            border-radius: 10px;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: bold;
            text-align: center;
            line-height: 1;
            @include media-breakpoint-up(md) {
                width:auto;
                max-width:590px;
            }
        }
        
        
    }
    .embed-responsive{
       // padding-bottom: 112.6126%;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        overflow: visible;
        background:black;
        
        &:after{
            content:"";
            position: absolute;
            bottom:0;
            left:0;
            width:100%;
            height:360px;
            background:linear-gradient(0deg, rgba(233,230,216,1) 0%, rgba(233,230,216,0) 100%);
        }
        
        /*@include media-breakpoint-up(md) {
            padding-bottom: 40.625%;
            .visible-md-block{
                display: block !important;
            }
            .hidden-md{
                display: none !important;
            }
        }*/
        
        iframe{
            opacity: 0.5;
        }
    }
    header{
        background:transparent;
        display:flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding:40px 0;
        @include media-breakpoint-up(md) {
            position: static;
        }
        .navbar-brand svg{
            width:160px;
            height:auto;
            @include media-breakpoint-up(md) {
                width:180px;
            }
            @include media-breakpoint-up(lg) {
                width:240px;
            }
        }
    }
    
    .titles{
        color:white;
        h1{
            margin-bottom:10px;

            @include media-breakpoint-up(lg) {
                margin-top:190px;
            }
        }
        h2{
            margin-top:0;
            font-size:18px;
            font-family: $font-family-base;
            opacity: 0.8;
        }
    }
    
    .offers{
        display:flex;
        flex-wrap: wrap;
        margin:30px -10px 70px;
        @include media-breakpoint-up(lg) {
            margin:40px -10px 70px;
        }
    }
    
    .offer{
        width:calc(50% - 10px);
        margin:5px;
        border-radius: 4px;
        overflow: hidden;
        opacity: 1;
        box-shadow: 0 0 30px rgba($brand-gold,0.4);
        text-align: center;
        @include media-breakpoint-up(md) {
           
        }
        @include media-breakpoint-up(lg) {
            width:calc(33.3333% - 20px);
            margin:10px;
        }

        &:hover{
            h3{
                color: inherit;
            }

        }

        img.badge {
            position:absolute;
            bottom: -8px;
            width:16px; height:16px;
            left: 50%;
            transform:translateX(-50%);
            color:$brand-gold;
            display: block;
            filter:drop-shadow(0 2px 8px rgba(black,0.25));

            @include media-breakpoint-up(md) {
                bottom: -11px;

                width:26px; height:26px;
            }

            @include media-breakpoint-up(lg) {
                bottom: -17px;

                width: 42px;
                height: 42px;
            }
        }

        .fa-certificate {
            position:absolute;
            bottom: -8px;
            font-size: 18px;
            left: 50%;
            transform:translateX(-50%);
            color:$brand-gold;

            @include media-breakpoint-up(md) {
                bottom: -11px;
                font-size: 26px;
            }

            @include media-breakpoint-up(lg) {
                bottom: -17px;
                font-size: 36px;
            }
        }
        
        .img-wrap{
            position:relative;
        }
        
        .meta{
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
            @include media-breakpoint-up(md) {
                ///display:block;
            }
            @include media-breakpoint-up(lg) {
                height: 125px;
            }
            @include media-breakpoint-up(xl) {
                height: 170px;
                padding: 30px 50px 30px;
            }
        }
        
        button{
            padding-left:10px;
            padding-right: 10px;
            border: 2px solid rgba(#4a4535,0.125);
            background: transparent;
            color: #4a4535;
            transition:all 0.3s;
            
            &:hover{
                border-color:rgba(#4a4535,0.5);
                color:lighten(#4a4535,20%);
            }
            
            
            @include media-breakpoint-up(lg) {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        
        h3{
            font-size: 12px;
            @include media-breakpoint-up(md) {
                font-size: 14px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 16px;
                margin: 0 0 15px;
            }
            @include media-breakpoint-up(xl) {
                font-size: 18px;
                margin: 0 0 20px;
            }
        }


    }
    
    .get-intouch{
        display:flex;
        justify-content: space-between;
        align-items: center;
        
        background-size:cover;
        padding-bottom:130.859%;
        
        position: relative;
       // display: block;
        height: 0;

        
        @include media-breakpoint-up(md) {
            overflow: hidden;
            padding-bottom:62.5%;
        }
        > img{
            width:20%; height:auto;
        }
        
        .contact{
            //width: 50%;
            position: absolute;
            left: 40%;
            bottom: 38%;
            
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            @include media-breakpoint-up(md) {
                bottom: 40%;
            }
            span{
                display:block;
                font-size:11px;
                @include media-breakpoint-up(md) {
                    display:inline;
                    font-size:inherit;
                }
                &.pipe{
                    display:none;
                    opacity: 0.25;
                    + span{
                        margin-top:-9px;
                    }
                    
                    @include media-breakpoint-up(md) {
                        display:inline;

                        + span{
                            margin-top:0;
                        }
                    }
                }
            }

            .names{
                font-size: 22px;
                @include media-breakpoint-up(lg) {
                    font-size: 24px;
                }
                
                .drew-name{
                    font-size: 33px;
                    border-right: 2px solid rgba(#212529,0.125);
                    padding-right: 0.75rem;
                    margin-right:0.25rem;
                    @include media-breakpoint-up(md) {
                        font-size: inherit;
                    }
                }
            }
            
            .contacts{
                font-size: 18px;
                @include media-breakpoint-up(md) {
                    font-size: 12px;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 20px;
                }
                svg{
                    font-size:14px;
                    margin-right:0.25rem;
                }
                a{
                    color: #28261f;
                    display:block;
                    margin-top: 10px;
                    @include media-breakpoint-up(md) {
                        display:inline;
                        //color: #fff5de;
                        margin-top: 0;
                    }
                    &:hover{
                        color:#fff
                    }

                    &:nth-child(2){
                        //border-right: 2px solid rgba(#212529,0.25);
                        //padding-right: 0.75rem;
                        //margin-right:0.3rem;
                        margin-right:1rem;
                    }
                }
            }
        }
        
        .sign-up{
            //width:30%;
            padding:10px;
            
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%);
            position: relative;
            margin:0 auto;
            width:calc(100% - 20px);
            
            @include media-breakpoint-up(md) {
                width:calc(100% - 60px);
            }
            @include media-breakpoint-up(lg) {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                padding:30px;
            }
            @include media-breakpoint-up(xl) {
                top: 5%;
                width:auto;
            }
            
            .meta{
                display:flex;
                align-items: center;
                flex-direction: column;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                }
            }
            
            p{
                font-size: 11px;
                //width:calc(67% - 10px);
                margin-right:10px;
                text-align: center;
                @include media-breakpoint-up(lg) {
                    font-size: 1.143em;
                    margin-right:30px;
                    width:calc(75% - 30px);
                    text-align: left;
                }
            }
            
            a{
                //width:33%;
                @include media-breakpoint-up(lg) {
                    width:25%;
                }
            }
            
            &:after, &:before{
                content:"";
                display:block;
                width:100%;
                height:2px;
                left:0;
                background: linear-gradient(90deg, rgba(162,145,106,0) 0%, rgba(162,145,106,1) 25%, rgba(162,145,106,1) 75%, rgba(162,145,106,0) 100%);
                position:absolute;
            }
            &:before{
                top:0;
            }
            &:after{
                bottom:0;
            }
        }
        
    }
    
    .banner{
        display:block;

        margin:80px -5px 0;
        border-radius: 4px;
        overflow: hidden;
        @include media-breakpoint-up(lg) {
            margin:60px 0 0;
        }
    }
}