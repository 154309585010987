footer{
	font-size:14px;
	font-family: $font-family-sans-serif;
	background-color:#15130f;//#101416;
	padding-top:87px;
	padding-bottom:50px;
	background-repeat: no-repeat;
	background-size:cover;
	background-position: top center;
	
	.container{
		max-width: 1170px;

		padding: 0 50px;
		@include media-breakpoint-up(xl) {
			padding-right: 15px;
			padding-left: 15px;
		}
	}
	
	.row{
		> div{
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	ul{
		list-style: none;
		margin:0; padding:0;
	}

	a{
		text-transform: uppercase;
		line-height: 1;
		letter-spacing: 1px;
		color:#828076;//#778084;

		&:hover{
			color:$brand-gold;
		}
	}
	h3{
		color:#F0E9D3;
		font-size:16px;
		text-align: center;
		margin-top:0;
		margin-bottom:75px;
	}
	h4, label{
		color:#F0E9D3;//#d2e6f0;
		font-size:16px;
		letter-spacing: 0.3px;
		margin-top:0;
		margin-bottom:30px;
		font-weight: $headings-font-weight;
	}

	.footer-links{
		text-align: center;
		padding-top: 20px;


		
		&:nth-child(2){
			padding-top:0;
			margin-top: -15px;
			h4{
				margin-bottom:0;
			}
		}
		
		li{
			display: block;
			padding:0 0 26px;
			line-height: 1;
		}

		a{
			font-size:13px;


		}
		
		.footer-mailing-address{
			color:#CFC9B6;//#b6c8d0;
			font-size:13px;
			line-height: 1;
			text-transform: uppercase;
			padding:0 0 8px;
			i,svg{
				font-size:14px;
				margin-left:4px;
			}
		}

		.footer-tele{
			padding:0 0 5px;
			a{
				font-size:13px;
				i,svg{
					font-size:8px;
				}
			}
		}

		.footer-email{
			padding-top:5px;
			a{
				text-transform: none;
				font-size:12px;
			}
		}

		// 768 -> 991
		@include media-breakpoint-up(md) {
		//@media (min-width: $screen-sm-min) {
			&:nth-child(2){
				//padding-top:20px;
				margin-top: 0;
				h4{
					margin-bottom:30px;
				}
			}
		}
	}


	#FooterAwards{
		text-align: center;
		margin:20px auto 10px;

		display:flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		li{
			min-height: 41px;
			margin: 0 20px 20px;
			//display: inline-block;
			display: flex;
			align-items: center;
			transition:opacity 0.3s;

			@include media-breakpoint-up(md) {
			//@media (min-width: $screen-sm-min) {
				margin: 0 16px;
			}
			
			// 992px -> 1200px
			@include media-breakpoint-up(lg) {
			//@media (min-width: $screen-md-min) {
				margin: 0 10px;
				min-height: 74px;
				
			}
			@include media-breakpoint-up(xl) {
			//@media (min-width: $screen-lg-min) {
				margin: 0 15px;
				min-height: 74px;
				
			}
			&:hover{
				//opacity: 1;
			}
			img{
				width:auto;
				opacity: 0.6;
				transition:opacity 0.2s;
			}
			
			a{
				&:hover{
					img{
						opacity: 1;
					}
					
				}	
			}
			
		}
		.trip-advisor{
			//height:47px;
			height:46px;
		}
		.andrew-harper{
			//height:44px;
			height:35px;
		}
		.forbes{
			//height: 52px;
			height: 42px;
		}
		.virtuoso{
			//height:36px;
			height:29px;
		}
		.signature{
			//height:31px;
			height:25px;
		}
		//.glamping{
		//	height:7px;
		//}
		//.top-50-ranches{
		//	height:11px;
		//}
		//.conde-nast{
		//	height:24px;
		//}
		.traveller-made{
			//height:16px;
			height:13px;
		}
		.lexus{
			//height:16px;
			height:13px;
		}
		@include media-breakpoint-up(md) {
		//@media (min-width: $screen-sm-min) {
			margin: 20px auto 50px;
		}
		// 992px -> 1200px
		@include media-breakpoint-up(lg) {
		//@media (min-width: $screen-md-min) {
			margin: 20px auto 102px;

			justify-content: space-between;
			align-items: center;
			.trip-advisor{
				//height:78px;//91px;
				height:82px;//91px;
			}
			.andrew-harper{
				//height:78px;;//85px;
				height:62px;;//85px;
			}
			.conde-nast{
				//height:40px;
				height:32px;
			}
			.forbes{
				//height:75px;
				height:60px;
			}
			.virtuoso{
				//height:65px;//75px;
				height:52px;//75px;
			}
			.signature{
				//height:51px;//54px;
				height:41px;//54px;
			}
			.glamping{
				//height:12px;
				height:10px;
			}
			.top-50-ranches{
				//height:20px;
				height:16px;
			}
			.traveller-made{
				//height:35px;//42px;
				height:28px;//42px;
			}
			.lexus{
				//height:30px;//36px;
				height:24px;//36px;
			}
/*			.trip-advisor{
				height:68px;
			}
			.andrew-harper{
				height:64px;
			}
			.forbes{
				height:64px;
			}
			.conde-nast{
				height:30px;
			}
			.virtuoso{
				height:56px;
			}
			.signature{
				height:40px;
			}
			.glamping{
				height:12px;
			}
			.top-50-ranches{
				height:20px;
			}
			.traveller-made{
				height:35px;
			}
			.lexus{
				height:27px;
			}*/
		}
		@include media-breakpoint-up(xl) {
		//@media (min-width: $screen-lg-min) {
			flex-wrap: nowrap;
			padding-left: 100px;
			padding-right: 100px;
			max-width: 1510px;
			
/*			.trip-advisor{
				//height:78px;//91px;
				height:62px;//91px;
			}
			.andrew-harper{
				//height:78px;;//85px;
				height:62px;;//85px;
			}
			.conde-nast{
				//height:40px;
				height:32px;
			}
			.forbes{
				//height:75px;
				height:60px;
			}
			.virtuoso{
				//height:65px;//75px;
				height:52px;//75px;
			}
			.signature{
				//height:51px;//54px;
				height:41px;//54px;
			}
			.glamping{
				//height:12px;
				height:10px;
			}
			.top-50-ranches{
				//height:20px;
				height:16px;
			}
			.traveller-made{
				//height:35px;//42px;
				height:28px;//42px;
			}
			.lexus{
				//height:30px;//36px;
				height:24px;//36px;
			}*/
		}
	}

	#FooterBottomRow{
		padding-top:40px;
		text-align: center;
		display:flex;
		flex-direction: column-reverse;
		align-items: center;
		
	}

	#FooterSocial{
		
		margin-top:30px;
		
		li{
			display:inline-block;
			a{
				padding:0 10px 0 0;
				font-size:26px;
			}
		}
	}

	#FooterLegal{
		font-size:9px;
		text-transform: uppercase;
		color:#4F4D46;//#464b50;
		letter-spacing:0.5px;
		a{
			margin:0 10px;
		}
	}
	
	#FooterAdditonalLinks{
		margin-bottom:20px;
		li{
			display:block;
			margin:0 0 20px ;
			a{
				font-size:12px;
			}
		}
	}
	
	#EmailSignUpWrapper{
		text-align: center;
		padding:40px 30px 0;
		.form-group{
			vertical-align: top;
			margin-bottom: 0;
		}
		label{
			//font-family: "rift", "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-family: $headings-font-family;
			display:block;
			line-height: 1;
		}
		input{
			background-color:#1F1E1B;//#1b1e1f;
			border:none;
			width: 100%;
		
			display:inline-block;
			margin-right:4px;
			vertical-align: top;
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: rgba(#FFF6D6,0.2);
				text-transform: uppercase;
				font-style: italic;
				font-size:12px;
			}
			&::-moz-placeholder { /* Firefox 19+ */
				color:  rgba(#FFF6D6,0.2);
				text-transform: uppercase;
				font-style: italic;
				font-size:12px;
			}
			&:-ms-input-placeholder { /* IE 10+ */
				color:  rgba(#FFF6D6,0.2);
				text-transform: uppercase;
				font-style: italic;
				font-size:12px;
			}
			&:-moz-placeholder { /* Firefox 18- */
				color: rgba(#FFF6D6,0.2);
				text-transform: uppercase;
				font-style: italic;
				font-size:12px;
			}
			&:focus {
				border-color: transparent;
				outline: 0;
				box-shadow: none;
				background-color:lighten(#1F1E1B,60);
				color:#000;
			}
		}
		
		.btn{
			background-color: #787055;//#556b78 ;
			color:#fff3d6;//#d4f1ff;
			border-radius: 0;
			padding: 6px 22px;
			margin-top:4px;
			width:100%;
			display:block;
			&:hover{
				background-color: #A2916A;
			}
		}
		
		#SendError{
			color: #f03f1a;
			//height:30px;
			opacity:0;
			transition: opacity 0.3s;
			margin-top: 5px;
			ul{
				font-size: 12px;
				line-height: 1.3;
			}
			&.show{
				opacity:1;
			}
		}
		#EmailSuccessMsg{
			display: none;
			color: #3ef01d;
			height:40px;
			line-height: 1.1;
		}
		&.success{
			#EmailSuccessMsg{
				display: block;
			}
			
			input,.btn{
				display: none;
			}
			
		}
		// 768 -> 991
		@include media-breakpoint-up(md) {
		//@media (min-width: $screen-sm-min) {
			padding:40px 0 0;
		}
	}
}



/*
// 320 -> 413
@media (min-width: 320px) {

}
// 414 -> 767
@media (min-width: 414px) {

}
*/

// 768 -> 991
@include media-breakpoint-up(md) {
//@media (min-width: $screen-sm-min) {

	footer{
		padding-bottom:0;


		#EmailSignUpWrapper{
			text-align: center;
			padding-top: 40px;
			.form-group{
				vertical-align: top;
				margin-bottom: 0;
			}
			label{
				//font-family: "rift", "Helvetica Neue", Helvetica, Arial, sans-serif;
				display:block;
				line-height: 1;
			}
			input{
				//background-color:#1F1E1B;
				border:none;
				width: 200px;
				height:40px;
				display:inline-block;
				margin-right:4px;
				vertical-align: top;
				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					//color: rgba(#d4f1ff,0.2);
					text-transform: uppercase;
					font-style: italic;
					font-size:12px;
				}
				&::-moz-placeholder { /* Firefox 19+ */
					//color:  rgba(#d4f1ff,0.2);
					text-transform: uppercase;
					font-style: italic;
					font-size:12px;
				}
				&:-ms-input-placeholder { /* IE 10+ */
					//color:  rgba(#d4f1ff,0.2);
					text-transform: uppercase;
					font-style: italic;
					font-size:12px;
				}
				&:-moz-placeholder { /* Firefox 18- */
					//color: rgba(#d4f1ff,0.2);
					text-transform: uppercase;
					font-style: italic;
					font-size:12px;
				}
				&:focus {
					border-color: transparent;
					outline: 0;
					box-shadow: none;
					//background-color:lighten(#1b1e1f,60);
					color:#000;
				}
			}

			.btn{
				height:40px;
				width:auto;
				display:inline-block;
				margin-top:0;
			}



		}
		
	}
}

// 992px -> 1200px
@include media-breakpoint-up(lg) {
//@media (min-width: $screen-md-min) {
	footer{

		.footer-links{
			text-align: left;
			padding-top: 0;
			li{
				padding: 0 0 13px;
			}
			a {
				font-size: 11px;
			}
		}
		
		#EmailSignUpWrapper {
			padding-top: 0;
			text-align: left;
			input{
				
				
			}
			.btn{
				
				
				
			}
		}
		#FooterSocial {
			margin-top: 30px;
		}
		#FooterBottomRow {
			text-align: left;
			flex-direction: row;
			justify-content: space-between;
		}
		
		#FooterAdditonalLinks {
			margin-bottom: 0;
			li{
				margin:0 0 0 20px;
				display:inline-block;
				a{
					font-size:11px;
				}
			}
		}
	}
}

//1200px +
@include media-breakpoint-up(xl) {
//@media (min-width: $screen-lg-min) {

	footer{
		#EmailSignUpWrapper {
			padding-left: 70px;
			input {
				width: 290px;
			}
		}
		#FooterAdditonalLinks{
			padding-left: 70px;
		}
	}

}