.press-parchment{

    background-color: #faf8f2;

    
    .bg{
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;
        max-width: 1600px;
        margin: 0 auto;
    }
    
    section{
        margin:0 auto;
        h2{
            text-align: center;

            @include media-breakpoint-up(lg) {
                font-size:1.714283em;
            }
        }
    }
    
    .accolades{
        padding-top:50px;
        padding-bottom:30px;

        .accolades-wrapper{
            display:flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        
        .accolade{
            //width:50%;
            margin-top:30px;
            
            
            &:not(:nth-child(1)):not(:nth-child(2)){

                &:before{
                    content:"";
                    background:transparent url('/images/pedals-sm.svg');
                    background-repeat: no-repeat;
                    background-size:contain;
                    width:20px; height:10px;
                    display:block;
                    margin:0 auto 20px;
                    opacity:0.3;
                }
            }
            

            
            .meta{
                text-align: center;
                
            }
            
            h5{
                font-size:1.14275em;
            }
            
            h6{
                font-size:1.0em;
                font-family: $font-family-sans-serif;
                color:#8e7f5d;
            }
        }
    }
    
    
    .press-articles{
        padding-top:30px;
        padding-bottom:90px;
        
        .press-wrapper{
            display:flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        
        .press{
            //width:33%;
            margin-top:30px;
            display:flex;
            
            
            img{
                width:105px; height:140px;
                display:block;
            }
            
            .meta{
                height:140px;
                padding:15px;
                background-color:rgba(white,0.5);
                width:calc(100% - 105px); 
                position:relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            h5{
                font-size:1.14275em;
                color:#4a4535;
                margin:0 0 5px;
                line-height: 1.0;
            }

            h6{
                font-size:1.0em;
                font-family: $font-family-sans-serif;
                color:#8e7f5d;
                margin: 0;
            }
            
            .readmore{
                position:absolute;
                bottom:10px; left:15px;
                font-size:0.875em;
                color:#8e7f5d;
            }
            
            &.has-link{
                .meta{
                    &:after{
                        content: "";
                        position:absolute;
                        bottom: 0;
                        right: 0;
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 10px 0 10px 10px;
                        border-color: transparent transparent #8e7f5d  transparent;
                        transition:opacity 0.2s;
                        opacity:0.2
                    }  
                }

            }
            
            &:hover{
                .meta{
                    &:after{
                        opacity:1
                    }
                }
            }
        }
    }
    
    
}