#EventsOverview{

    .events-header{
        background-color: #5f5232;
        height:60px;
        margin:0 0.5rem 0.5rem;
        border-top: 0.5rem solid white;

        @include media-breakpoint-up(lg) {
            height:108px;
        }


    }
    
    
}