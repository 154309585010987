@import "venues/venues";
@import "venues/catering-gallery";
@import "accomodations";
@import "partials/itinerary";
@import "contact";
@import "pricing";
//@import "weddings/elopements";

// TODO: Take a look at this after Bootstrap 4 migration and see if we can remove most.

#Groups2{

    .adventures{
        display:flex;
        margin:0 -5px;
        
        .link{
            margin: 1px 8px 1px 1px;
             
            @include media-breakpoint-up(lg) {
                margin:0 5px;
            }
        }
    }

    
    // TODO: EXTRACT OUT. APPEARS TO BE IN VUE COMPONENT
    .adventure-links{
        display:flex;
        justify-content: center;
        margin:10px -5px 40px;
        > div{
            margin:0 5px;
            width:calc(33% - 10px);
            text-align:center;
        }
        
        a{
            font-size: 0.7em;
            margin:0 5px;
            display:block;
            @include media-breakpoint-up(lg) {
                font-size: 1em;
                margin:0 20px 10px;
            }
        }
    }
    
    .groupNav, .adventures{

        .links{
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .link{
                width:calc(50% - 10px);
            }
            
            @include media-breakpoint-up(lg) {
                &.three-up{
                    .link{
                        width:calc(33% - 10px);
                    }
                }
                &.four-up{
                    .link{
                        width:calc(25% - 10px);
                    }
                }

            }
        }
        
        .link{

            margin:5px;
            text-align: center;
            position:relative;

            h4{
                display:flex;
                justify-content: center;
                align-items: center;
                color:#fff;
                font-size:1em;
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                padding:0 40px;

                @include media-breakpoint-up(lg) {
                    font-size:	1.429em;
                }
            }

            .img-wrap{
                background-color:black;
                overflow: hidden;
            }

            img{
                opacity: 0.7;
                transition:opacity 0.3s, transform 0.3s ease;
            }

            .active-bar{
                position:absolute;
                bottom:0;
                left:0; right:0;
                height:10px;
                transition:opacity 0.3s, height 0.3s ease;
                opacity:0;
                background-color:$brand-gold;
            }

            &.active{
                .active-bar{
                    opacity:1;
                }
                img {
                    opacity: 1;
                }
                h4{
                    text-shadow: 0 0 12px rgba(black,0.5);
                }
            }

            &:hover{
                img{
                    opacity: 1;
                    transform:scale(1.1);
                }
                .active-bar{
                    opacity: 1;
                    height:20px;
                }
            }
        }
    }

    table.details{
        box-shadow:0 0 30px rgba(black,0.05);

        .header{
            background-color:hsl(41, 17%, 83%);
            font-size:1.714em;
            font-weight:500;
            padding:10px 25px;
            line-height: 1;
            font-family: $headings-font-family;
        }
        tbody{

            td{

                padding:5px 25px;

            }
        }
        .footer{
            background-color:hsl(41, 21%, 93%);
            font-size:1.429em;
            padding:10px 25px;
            line-height: 1;
            font-family: $headings-font-family;
        }

    }
}