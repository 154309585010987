
@mixin titleUnderBar($width:50px,$height:2px,$center:false,$color:#cab382){
	&:after{
		content:"";
		display:block;
		width:$width; height: $height;
		background-color:$color;
		@if($center){
			margin: 18px auto;
		}
		@else {
			margin: 18px 0;
		}
	}
}

@mixin buttonFont(){

	font-family: $font-family-sans-cond;
	font-weight: $font-family-sans-cond-weight;
	text-transform: uppercase;
	letter-spacing: 0.075rem;
}

@mixin headlineFont(){
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
}

@mixin scrimGradient($direction: 'to bottom', $startColor: 'black') {

	$scrimCoordinates: (
			0: 1,
			19: 0.738,
			34: 0.541,
			47: 0.382,
			56.5: 0.278,
			65: 0.194,
			73: 0.126,
			80.2: 0.075,
			86.1: 0.042,
			91: 0.021,
			95.2: 0.008,
			98.2: 0.002,
			100: 0
	);

	$hue: hue($startColor);
	$saturation: saturation($startColor);
	$lightness: lightness($startColor);
	$stops: ();

	@each $colorStop, $alphaValue in $scrimCoordinates {
		$stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
		$stops: append($stops, $stop, comma);
	}

	background: linear-gradient(unquote($direction), $stops);

}


//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
/*$breakpoints: (
		//xxs: 360px,
		//xs: 576px,
		//sm: 768px,
		//md: 992px,
		//lg: 1200px,
		//lg2: 1280px,
		//xl: 1440px,
		
		xxs: 0,
		xs: 360px,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px,
		xxl: 1440px,
		x1600: 1600px,
		x1920: 1920px,
		x2560: 2560px,
);*/

