#Home{

    #GlampingVideo {
        border-top:10px solid white;
        background-size: cover;
        background-position: bottom center;
        text-align: center;
        position: relative;
        padding:30px 15px 260px;
        z-index: 0;
        .meta{
            color:#000;
            text-align: left;
            margin-top:20px;
            //text-shadow:0 0 2px white, 0 0 4px white, 0 0 8px white;

            h2{
                font-size:2.4em; //39px
                color:#000;
                //font-family:$font-title-sans;
                //font-weight:700;
                margin:0;

            }

            p{

            }

        }

    }

    // 576 -> 767
    @include media-breakpoint-up(md) {


        #GlampingVideo {

            padding:50px 0 260px;

            .flex-wrap{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .vid{
                width:320px; height:180px;

            }

            .meta{
                width:calc( 100% - 320px);
                padding-left:15px;
                margin-top:40px;
                h2{
                    font-size:2.786em;
                }
            }
        }

    }

    // 768 -> 991
    @include media-breakpoint-up(lg) {

        #GlampingVideo {

            padding:50px 0 260px;

            .flex-wrap{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .vid{
                width:640px; height:360px;

            }

            .meta{
                width:calc( 100% - 640px);
                padding-left:30px;
                h2{
                    font-size:2.5em;
                }
            }
        }
    }

    // 992px -> 1200px
    @include media-breakpoint-up(xl) {


    }

    // 1440px +
    @include media-breakpoint-up(xl1440) {



    }
}