
section.special-offers{
    
    padding-top:30px;
    padding-bottom:50px;
    
   > div {
        display:flex;
        justify-content: center;
        align-content: flex-start;
        flex-wrap:wrap;
        margin:0 -15px;
   }
    
    .paragraph{
        font-size:12px;
        line-height: 1.5;
        max-width: 960px;
        margin: 20px 0 0;
    }


}



article.special-offer{
    
    
    margin:30px auto 30px;
    flex-direction: column;
    display: flex;
    border-bottom: 1px solid #e6e3db;
    overflow: hidden;
    background: white;
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
    
    @include media-breakpoint-up(md) {
        width:calc(50% - 30px);
    }
    @include media-breakpoint-up(xl) {
        width:calc(33.33% - 30px);
    }
    
    > img{
        
    }
    
    .meta{
        
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 30px;
        
        h2{
            font-size:1.286em;

            @include media-breakpoint-up(md) {
                
            }
        }
        h3{
            font-size:1.000em;
            margin-top: 0;
            @include media-breakpoint-up(md) {

            }
        }

        hr{
            margin-top: 10px;
            margin-bottom: 10px;
        }

        p{
            font-size:1.000em;
        }
        
        
        
        .titling{
            //height:50px;
            //overflow-y: auto;
        }
        
        .desc{
            //height:170px;
           // overflow-y: auto;
            line-height:1.5;
        }
        
        .highlights{
           // height:260px;
            //overflow-y: auto;
            line-height:1.3;
            ul{
                padding-left: 16px;
            }
            p,li{
                //font-size:0.857em;
                //font-weight:700;
                color:darken(#4a4535,10%);
                margin-bottom: 12px;
                
                &:not(:last-child){
                   // border-bottom: 1px solid #e6e3db;
                }   
            }
            
            a.btn{
                background:#eae2d3;
                padding:3px 9px;
                font-size: 12px;
                margin:10px 0;
            }
        }
        .terms{
            font-size:0.857em;
            line-height:1.3;
        }
        
        .footer{
            padding-bottom:20px;
        }
        
        .contact{
            font-size:1.000em; 
            line-height: 1.5;
            
        }
        
        .buttons{
            //display:flex;
            
            a.book{
                margin:20px 0;
                padding: 8px 35px 8px 40px;
            }
            
        }
        

        
    }
    
}