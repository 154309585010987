// RELATED SLIDER

.related{

    padding-top:20px;
    padding-bottom:20px;
    //padding:20px 50px 20px;
    background: linear-gradient(to bottom, #e6e3db 0%, #f2efe6 100%);

    .container{
        padding-left: 0;
        padding-right: 0;
    }

    .bin.event-bin .meta{
        //height: 140px;

        @include media-breakpoint-up(md) {
            //height: 140px;
        }
        @include media-breakpoint-up(lg) {
            height: 120px;
        }
        @include media-breakpoint-up(xl) {
            height: 140px;
        }
    }

    .title{
        position:relative;
        h3{
            font-size:2.2em;
            color:#4a4535;
            margin-bottom:20px;
            text-align: center;
        }
    }
    .seeAllLink{
        display:block;
        text-transform:uppercase;
        font-family: $font-title-sans;
        font-size:1.0em;
        font-weight: 700;
        color:#63798d;
        text-align: center;
        margin:-20px 0 20px 0;
        letter-spacing:0.5px;
        transition:transform 0.3s ease;
        &:hover{
            color:$brand-gold;
            transform:translate(8px,0);
        }
    }

    .slider{
        //margin-right: -20px;
        display: none;
        margin-top:0;

        .slick-dots {
            bottom:-40px;
            li button:before{
                color:black;
            }
        }
        button{
            &:before{
                //font-size:34px;
            }
            &.slick-next{
                right: -15px;
            }
            &.slick-prev{
                left: -15px;
            }
        }

        &.slick-initialized{
            display: block;
        }

        @include media-breakpoint-up(sm) {
            button{

                &.slick-next{
                    right: -42px;
                }
                &.slick-prev{
                    left: -42px;
                }
            }

        }
    }

    .slide{

    }

    .bin.event-bin, .bin.activityBin{

        margin:20px auto;
        width:auto;
        height:auto;

        .meta {
            position: relative;
        }

        h4{
            display:none;
        }
        &.event-bin{
            //.learnmore{
            //
            //	position: absolute;
            //	bottom: 1px;
            //	left: 30px;
            //
            //}
        }


    }
    @include media-breakpoint-up(sm) {
        //.container{
        //	padding-left: 15px;
        //	padding-right: 15px;
        //}
        .bin.event-bin, .bin.activityBin{
            margin:20px 5px;
        }
    }
    @include media-breakpoint-up(sm) {

        padding:20px 85px 20px;
        .slider{
            .slick-dots{
                bottom: -25px;
            }
        }
        
        .bin.event-bin, .bin.activityBin{
            h4{
                display:block;
            }
        }
    }
    @include media-breakpoint-up(md) {
        .title{
            h3{
                font-size:2.286em;
            }
        }
        .seeAllLink{
            font-size:1.0em;
            position:absolute;
            right: 0;
            top: 30px;
            margin:0 4px 0 0;
        }
        .bin{
            //font-size:11px;
        }
        
        
    }
    @include media-breakpoint-up(md) {

        

    }
}

