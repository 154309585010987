#Groups2{
    
    .itineraries{
        //background-color:$brand-gray-bg;
        padding-top:50px;
        h3{
            font-size:2.000em;
            margin-bottom:30px;
        }
    }

    .itinerary-day{
        &:last-child{
            padding-bottom:50px;
        }
    }
    
    .itinerary{

        display: flex;
        margin-bottom:30px;
        flex-direction: column;
        align-items: stretch;

        .img-wrapper{
            background-position: center center;
            background-size:cover;
            background-repeat: no-repeat;

            height: 0;
            overflow: hidden;
            padding-top: (630px / 300px) * 10%;

            position: relative;
            //flex: 1 100%;
            /*@include media-breakpoint-up(md) {
                width: 400px;
            }
            @include media-breakpoint-up(lg) {
                width: 400px;
            }
            @include media-breakpoint-up(xl) {
                width: 630px;
            }*/
        }
        img{
           /* display:block;
            max-width:100%;
            width:100%;*/
            //height:auto;
            /*position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;*/
            //align-self: stretch;
           // aspect-ratio: 630 / 300;
            display: block;
            max-width: 100%;
            width: 100%;
            height: auto;
            align-self: center;
            @include media-breakpoint-up(md) {

            }
            @include media-breakpoint-up(lg) {
                width: 400px;
            }
            @include media-breakpoint-up(xl) {
                width: 630px;
            }
        }
        
        .meta{
            background:rgba(white,1);
            padding:15px;
            display:flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            flex: 1 100%;


            @include media-breakpoint-up(md) {


            }
            @include media-breakpoint-up(lg) {
                width: calc(100% - 400px);
                background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0.25) 100%);
                padding:30px;
                margin-right: 1rem;
            }
            @include media-breakpoint-up(xl) {
                width: calc(100% - 630px);
                margin-right: 0;
                margin-left: 0;
                &:nth-child(2n) {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
            h4{
                font-size: 1.143em;
                margin-top:0;
                @include media-breakpoint-up(lg) {
                    font-size: 1.286em;
                }
            }
            p{
                line-height: 1.2;
                margin-bottom:20px;
                font-size: 1em;
                @include media-breakpoint-up(lg) {
                    font-size: 1.143em;
                }
            }

        }

        @include media-breakpoint-up(lg) {
            flex-direction: row-reverse;

            &:nth-child(2n) {
                .meta{
                    background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0.25) 100%);
                    margin-left: 1rem;
                    margin-right: 0;
                }
                flex-direction: row;
            }
        }
        @include media-breakpoint-up(xl) {

            &:nth-child(2n) {
                .meta{
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}