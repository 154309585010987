#Home{
    
    #BottomPromotion{
 
        padding:5px;
        
        display:flex;
        flex-direction:column;
        background-color:#f2f0e9;
        
        
        picture {
            display: block;
        }
        picture > br {
            display: none;
        }
        img {
            max-width: 100%;
            height: auto !important;
        }

        
        a.btn{
            display:block;
            margin:0 5px 5px;
            @include media-breakpoint-up(xl1440) {
                margin:0 5px; 
            }
            
            svg{
                margin-left: 0.25rem;
            }
        }

        .content{
            background-position:top center;
            background-size: 100%;
            background-repeat: no-repeat;
            background-color: white;
            position:relative;
            overflow: hidden;
            border-radius: 2px;

            &:first-child{
                margin-bottom:5px;
                @include media-breakpoint-up(lg) {
                    margin-bottom:0;
                }
                
            }

            > .meta{
                position: relative;
                display:flex;
                align-items: flex-end;
                width:100%;
                background:white;
                padding:30px;
                p{
                    line-height: 1.3;
                }
                >div:first-child{
                    width:auto;
                }
            }


        }
    }
    
    
    @include media-breakpoint-up(sm) {}

    // 576 -> 767
    @include media-breakpoint-up(md) {}

    // 768 -> 991
    @include media-breakpoint-up(lg) {

        #BottomPromotion{

            flex-direction:row;
            padding:8px 4px;
            
            .video-thumb{
                bottom:auto;
                top:30px;   

                &:hover{}
            }

            .content{
                width:50%;
                margin:0 4px;
                background-position: top center;
                background-size: contain;

                position:relative;

                > .meta{
                    display:flex;
                    align-items: flex-end;
                    width:100%;
                    background:white;
                    padding:30px;
                }
            }
        }
    }

    // 992px -> 1200px
    @include media-breakpoint-up(xl) {

        #BottomPromotion{

            .content{

                > .meta{

                    &.with-video-thumb{
                        > div:first-child{
                            width:calc(100% - 268px);
                        }
                    }
               
                }

            }

        }
        
    }
    
    // 1440px +
    @include media-breakpoint-up(xl1440) {}
}