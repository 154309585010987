@import "../../sections/home/partials/hero";
@import "../../sections/home/partials/welcome";
@import "../../sections/home/partials/stats";
@import "../../sections/home/partials/happenings";
@import "../../sections/home/partials/accomodations";
@import "../../sections/home/partials/adventures";
@import "../../sections/home/partials/events";
@import "../../sections/home/partials/salmon-lake";
@import "../../sections/home/partials/bottom-promotion";
@import "../../sections/home/partials/glamping-video";
//@import "../../sections/home/settings/the-list";
//@import "../../sections/home/settings/blackfoot";
#Home{

	#Hero{
		display:none;
	}
	
	.content{
		padding-bottom:20px;
		position: relative;

		&.container-fluid{
			> .container{
				padding-left:0; padding-right:0;
			}
		}

	}

	h2{

		font-size:1.75em;//1.857em;
		margin-bottom:30px;
		margin-top:20px;
		letter-spacing: 0.5px;


		@include media-breakpoint-up(lg) {
			font-size:2.4em;
		}
	}
	
	.back-to-top{
		position: absolute;
		right:8px;bottom: 8px;

		background-color: #fff;
		border-radius: 30px;
		padding: 0 4px 1px 13px;
		
		i,svg{
			transition:transform 0.2s ease;
		}
		
		&:hover{
			background-color:$brand-gold;
			color:#fff;
			
			i,svg{
				transform:translateY(-2px);
			}
		}
	}
	
	.bin{
		//
		&.sm{

		}

		&.large{
			font-size:12px;
			.meta{
				height:auto;

			}

		}
		
		&:not(.event-bin):not(.activityBin){
			.imgWrap{
				overflow:hidden;
				height: 150px;
				> img {
					width:100%; height:auto;
					max-width: 100%;
					display: block;
					//transition:transform 0.5s ease;
					top: -90px;
					position: relative;
				}
				> .slides {
					img {
						width:100%; height:auto;
						max-width: 100%;
						display: block;
						//transition:transform 0.5s ease;
					}
				}
			}
		}
		
		&.event-bin{
			.meta{
				//height: 100px;
			}
		}




	}

	.title{
		position:relative;
	}

	.seeAllLink{
		display:inline-block;
		//text-transform:uppercase;
		//font-family: $font-family-sans-cond;
		font-family:$headings-font-family;
		font-weight:$headings-font-weight;
		font-size:1.0em;
		//font-weight: 700;
		color:#655e48;
		text-align: center;
		margin: 0 auto 20px;
		letter-spacing:0.5px;
		transition:transform 0.3s ease;
		&:hover{
			color:$brand-brown;
			//transform:translate(8px,0);
		}
		// 992px -> 1200px
		@include media-breakpoint-up(lg){
			font-size:1.125em;
			margin:35px auto 0;
		}
	}


	.button-group{
		display:flex;
		flex-wrap: wrap;
		margin: 0 -5px;
		.btn {
			margin:0 5px 5px;
		}

		.watch-video{
			border:1px solid $brand-text-brown;
			background-color: transparent;
			&:hover{
				background-color: rgba($brand-brown,1);
				color:#fff;
			}
		}

		@include media-breakpoint-up(md) {
			
		}
	}

/*	.label-orange{
		font-size:1.143em; //41px
		color:#fff;
		font-family:$font-title-sans;
		font-weight:700;
		background-color:#9f5a23;
		text-align: center;
		line-height: 1;
		white-space: nowrap;
		border-radius: 2px;
		padding: .15em 1em .15em;
		display:inline-block
	}

	#WinterGraphic{
		position: absolute;
		bottom: -175px;
		display: block;
		left: 0;
		width: 700px;
		height: auto;
	}*/


	 .event-group{
		display:flex;
		justify-content: center;
		flex-wrap:wrap;
		align-items: flex-start;
		 
		 @include media-breakpoint-up(lg) {
			 padding-top: 0;
		 }
		 
		.footer{
			@include media-breakpoint-up(lg) {
				font-size: 0.75em;
			}
			@include media-breakpoint-up(xl) {
				font-size: 0.857em;
			}
		}
		
		.icons{
			@include media-breakpoint-up(lg) {
				//display:none;
			}
			@include media-breakpoint-up(xl) {
				//display:block;
			}
		}

		&.special{
			.bin{
				background-color:rgba($brand-brown,0.8);
				.meta{
					h5{
						&.series{
							span{
								color: white;
								background-color: rgba(159, 148, 95, 0.5);
							}
						}

					}
					h3{
						color:#fff;
						
						+ h4{
							color:rgba(#b3aa8f,1);
						}
					}
				}

				.footer{
					color:rgba(#b3aa8f,1);


				}
			}
		}
		
		.bin{
			margin:5px 0;
			border-bottom:none;

			h3{
	
				@include media-breakpoint-up(lg) { }
				
				@include media-breakpoint-up(xl) { }

			}
			
			@include media-breakpoint-up(sm) {
				margin:5px;
			}
			
			@include media-breakpoint-up(md) { }
			
			@include media-breakpoint-up(lg) {
				margin:0 4px;
				//width:calc(25% - 8px);
				width:auto;
				max-width:320px;
			}
		}
	}


	
/*	#SlideChooser {
		display: none;
	}*/

	.modules{
		margin-top:15px;
		 .event-group{
			margin-top:0;
		}
		h2{
			//font-family:$font-title-sans;
			//font-weight:700;
			text-align: center;
			@include titleUnderBar(50px,2px,true,#444a4f);
		}
	}


	.video-thumb{

		position:absolute;
		bottom:10px; right:10px;
		background-color:white;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 
			0 8px 15px rgba(0, 0, 0, 0.1);
		width:124px;
		padding-right:0;
		padding-left:0;
		transition:box-shadow 0.3s ease;
		cursor: pointer;
		
		.img-wrap{
			height:82px;
			position:relative;
			img{
				transition:opacity 0.2s; 
			}
			i, svg{
				position:absolute;
				top:50%; left:50%;
				transform:translate(-50%,-50%);
				font-size:24px;
				color:rgba(white,0.8);
				transition:transform 0.3s ease;
				transform-origin: center center;
				text-shadow: 0 0 15px rgba(0,0,0,0.25);
			}
		}

		.meta{
			background-color:white;
/*			font-family:$headings-font-family;
			font-weight:$headings-font-weight;*/
			
			@include buttonFont();
			
			padding:4px 10px;
			text-align:center;
		}

		&:hover{
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1),
			0 8px 15px rgba(0, 0, 0, 0.2);
			
			.img-wrap{
				img{
					opacity:0.8;
				}
				
				i,svg{
					//transform:scale(1.1) translate(-50%,-50%);
					color:rgba(white,1);
				}
			}
		}

		@include media-breakpoint-up(lg) {
			bottom:30px; right:30px;
			width:208px;
			.img-wrap{
				height:138px;
				
				i,svg{
					font-size:48px;
				}
			}
		}
	}
}


/*// 320 -> 413
@media (min-width: 320px) {

}
// 414 -> 767
@media (min-width: 414px) {

}*/

// 768 -> 991
@include media-breakpoint-up(md) {
//@media (min-width: $screen-sm-min) {

	#Home {

		/*.navbar-brand{
			img,svg{
				width: auto; height:30px;
				margin:55px 0;
			}
		}*/

		header {
			#Nav{
				> li{
					> a{
						//padding: 10px 6px 18px;
					}
				}
			}

		}
		
		
		.row:not(.reg-pad){
			margin-left: 30px;
			margin-right: 30px;
			> div {
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		.bin{
			//margin: 20px 0;

			&.large{
				font-size: 11px;
				.meta{
					height:120px;

				}
			}

			.imgWrap{
				height: auto;
				>img{
					top: 0;
				}
			}

		}

		.content{
			padding-bottom:20px;
		}

	}

}

// 992px -> 1200px
@include media-breakpoint-up(lg) {
//@media (min-width: $screen-md-min) {
	#Home {

		.modules{
			margin-top:20px;
		}

	}
}

//1200px +

@include media-breakpoint-up(xl) {
//@media (min-width: $screen-lg-min) {


	#Home {
		header {
			.navbar{
				//padding-bottom: 20px;
			}
			#Nav{
				> li{
					> a{
						//padding: 10px 9.4px 18px;
					}
				}
			}

		}

		.modules{
			margin-top:30px;
		}


		

		#Utility{
			margin-top: 50px;

			.bin{
				h3{
					font-size:1.714em;
				}
			}
		}

	}

}
/*

//1440px +
@media (min-width: 1440px) {
	#Home {
		

	}
}

//1601px +
@media (min-width: 1601px) {
	#Home {


	}
}*/
