#Groups2{
    
    .contact{
        padding-bottom: 60px;
        
        p{
            margin-bottom:60px;
        }
        
        h4{
            font-size:2.000em;
            margin-bottom:5px;
        }

        h5{
            font-size:1.714em;
            margin-top:0;
        }
    }
    
}