section#Wheres{

    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 5px;

    display:flex;
    flex-direction:column;
    
    @include media-breakpoint-up(lg) {
        flex-direction:row;
        padding-top:40px;
        padding-bottom:40px;
    }
    
    
    
    .meta{
        position: relative;
        
        padding:  15px 15px 15px;
        h2{
            margin-top:0;
        }
        p{
            line-height: 1.3;
        }
        @include media-breakpoint-up(lg) {
            padding: 30px;
        }
    }
    
    .content{
        //padding: 62% 15px 15px;
        background-position: bottom;
        background-size: cover;
        position: relative;
        margin-bottom:10px;
        background-color: #fff;
        padding:10px;
        
/*        &:before{
            content:"";
            display: block;
            position:absolute;
            bottom:0; left:0;
            width:100%;
            height:50%;

            background: linear-gradient(to bottom, rgba(242,240,233,0) 0%,rgba(242,240,233,0) 20%,rgba(242,240,233,1) 72%,rgba(242,240,233,1) 100%);

        } 
        */
        
        &:first-child{
            .meta{
                //padding: 0 15px 15px;

                @include media-breakpoint-up(lg) {
                   // padding: 0 30px 15px;
                }
            }
        }
        
        // 768 -> 991
        @include media-breakpoint-up(lg) {
            width: 50%;
            margin: 0 5px;
            //padding: 35% 66px 30px;


        }
        
        
        
    }
        
    
}