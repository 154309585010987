#Home{

    .left-right-content-modules{
        
        text-align: center;
        
        @include media-breakpoint-up(lg) {
            text-align: left;
        }
        @media (min-width: 1920px) {
            //max-width: none;
           // background-position:bottom center;
           // background-size: cover;
            border-color:#f5f5f5;
        }
    }
    

    
    #GlampingMod {
        
       // background-position:bottom center;


    }

    #LuxHomesMod {
       // background-position: 0 0;

        
        // 768 -> 991
        @include media-breakpoint-up(lg) {
            //background-position:bottom center;
            //background-size: cover;

        }

    }

    #OneResortTwoVacationMod{

      //  border-top: 1px solid white; 
        text-align:center;
        background-color:#f3eedc;
        background-size: cover;
        padding:0 0 20px;
        font-size: 12px;
        h2{
            text-align:center;
            font-size: 2em;
            line-height: 1;
            + h3{
                margin-top: -19px;
                font-size: 1.25em;
                color: #877964;
                a{
                    color:darken(#877964,15%);

                }
            }

        }
        p{
            //color: #877964;
            font-size:1em;
        }
        .btn.outlined{
            border-color:#444a4f;
            color:#444a4f;
            margin-top:10px;
            transition:all 0.3s;
            &:hover{
                border-color:#A2916A;
                color:#A2916A;
            }
        }
        // 768 -> 991
        @include media-breakpoint-up(lg) {

           // border-top: 10px solid white;
            background-size: cover;
            background-repeat:no-repeat;
            background-position:bottom center;
            padding:40px 0;
            font-size: 14px;
            h2{
                font-size:3em;
                + h3{
                    margin-top:-30px;
                    font-size: 1.25em;
                }
            }
            p{
                font-size:	1.143em;
            }
            .btn.outlined{
                margin-top:20px;
            }
            
        }
    }




    // 992px -> 1200px
    @include media-breakpoint-up(xl) {


    }

    //1200px +
    @include media-breakpoint-up(xl1440) {

    }
}