
.popup-nav{
    
    padding:30px 0;
    
    section{
        max-width: none;
    }
    
    &.gallery-popup-nav{

        .event-group{
            > div{
                align-items: flex-start;
            }

        }

    }
    
    // used on event overview calendar titles
    &.overview-mode{
        .main-titles{
            h2{
                font-size:2.5em;
            }
            h3{
                font-size:1.75em;
            }
        }

    }
    
    .titleblock{
        margin-bottom:30px;
    }

    nav.subnav{
        margin-top:50px;
        text-align: center;
        a{
            display:block;
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            font-size:1em;
            //text-transform: uppercase;
            //letter-spacing:1px;
            margin: 0 0 10px;
            padding-bottom: 10px;

            border-bottom: 1px solid rgba(0,0,0,0.1);

            &:last-child {
                border-color:transparent;
            }


            @include media-breakpoint-up(md) {
                display:inline-block;
                font-size:1.1em;
                margin:5px 23px;
                border-color:transparent;
                padding-bottom: 0;

            }

            &.active{

                color:$brand-gold;

                @include media-breakpoint-up(md) {

                    border-color:$brand-gold;
                }
                /*&:before{
                    content:"";
                    display:block;
                    background:transparent url('/storage/static/events/nav-active-mark.svg') no-repeat top left;
                    width:25px; height:7px;
                    margin: 0 auto 10px;
                }*/
            }
        }

        @include media-breakpoint-up(md) {
            a{

                &.active{
                    //color:$brand-gold;
                    &:before{
                        margin: 0 auto 10px;
                    }
                }
            }
        }
        @include media-breakpoint-up(xl) {
            a{
                margin:0 15px;
            }
        }
    }
}

.popup-nav-item{
    .icon{
        position:absolute;
        bottom:10px;
        left:20px;
        display:none;

        span{
            font-size:10px;
        }

        @include media-breakpoint-up(lg) {
            display:block;
        }
    }
    

    .imgWrap{
        position:relative;

        /*&.portrait{
            width: 64px;
            @include media-breakpoint-up(lg) {
                width:auto;
            }
            img{
                width: 64px;
                @include media-breakpoint-up(lg) {
                    width: 100%;
                }
            }

        }*/
    }
    
    &.popup-nav-item-video{
        cursor:pointer;
        
        &:hover{

            .fa-play-circle {
                transform:translate(-50%,-50%) scale(1.1);
            }
        }
        .imgWrap{
            position:relative;
            

        }
        .fa-play-circle {
            position:absolute;
            top:50%; left:50%;
            transform:translate(-50%,-50%);
            color:white;
            font-size: 94px;
            transition:transform 0.2s ease;
        }
    }

    figure{
        position:relative;
        .fa-search-plus{
            position:absolute;
            top:50%; left:50%;
            transform:translate(-50%,-50%);
            color:white;
            font-size: 24px;
        }
        figcaption{
            display:none;
        }
    }
}