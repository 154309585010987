.block-itinerary{
    
    padding:30px 0;
    
    &.compact{
        .heading, .grouping .heading{
            font-size:	1.125em;
            padding: 7px 10px;

            @include media-breakpoint-up(lg) {
                padding:8px 20px;
                font-size:	1.0em;
            }
        }
        .row{
            margin-right:-5px;
            margin-left:-5px;
        }
        .col-md-4,.col-lg-2{
            padding-left:5px;
            padding-right:5px;

            margin-bottom:10px;
        }
        .col-lg-12{
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    padding-bottom:50px;

    .disclaimer{
        p{
            font-size:0.875rem;
        }
    }

    .day{
        background-color:white;

        box-shadow:0 0 10px rgba(black,0.05);

        margin-bottom:10px;

        @include media-breakpoint-up(lg) {
            margin-bottom:0;
        }
    }

    .heading{
        //background-color:#cfc8b9;
        background-color:#fff;
        font-size:	1.125em;
        color:#1e1c15;
        font-family: $headings-font-family;
        padding: 7px 10px;
        border-bottom:1px solid #f2f1ef;

        @include media-breakpoint-up(lg) {
            padding:15px 20px;
            font-size:	1.286em;
        }
    }

    .grouping{
        .heading{
            background-color:#f5f4f1;
            font-size:	1.3em;
            @include media-breakpoint-up(lg) {
                font-size:	1.286em;
            }
        }

        .item{
            padding: 7px 10px;
            display:flex;
            line-height: 1.2;

            &:not(:last-child){
                border-bottom:1px solid #f2f1ef;
            }

            &.with-time{

                i,svg{
                    padding-top: 0;
                    font-size: 16px;
                }
            }

            i,svg{
                margin-right:7px;
                color:$brand-gold;
                padding-top: 4px;
                font-size: 18px;
                width: 20px;

            }

            .time{
                font-size:0.75rem;
                color:#6b6759;
            }

            @include media-breakpoint-up(lg) {
                padding:15px 20px;
            }
        }
    }

}