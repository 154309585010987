.shadowedBox{
	box-shadow:0 0 20px rgba(61,52,30,0.15);
	padding:15px;
}

.slides{
	list-style: none;
	margin:0; padding:0;

	li:not(:first-child) {
		display:none;
	}

	&.slick-initialized{
		li{
			display:inline-block;
		}

	}
	&.slick-dotted.slick-slider{
		margin-bottom:0;
	}
	.slick-dots {
		bottom: 7px;
		li button:before{
			text-shadow: 0 0 19px #000;
		}
	}

	li {
		position: relative;
		.caption-sm{
			position:absolute;
			bottom:30px; right:0;
			text-align: right;
			background:rgba(0,0,0,0.4);
			color:#fff;
			font-family: $font-title-sans;
			font-size: 1.000em;
			padding: 4px 10px 4px 5px;
			line-height: 1;
			letter-spacing: 0.1em;
		}
		img{
			width:100%;
			max-width: 100%;
			height:auto;
		}
	}

}


.pullquote{
	color:$brand-gold;
	position:relative;
	margin:30px 0;
	font-size:	1.125em;
	font-weight:500;
	font-family:$headings-font-family;
	padding-left: 40px;
	line-height:1.2;
	
	&:before{
		content:"“";
		position:absolute;
		top:0; left:0;
		font-size: 94px;
		font-family: Georgia, "Times New Roman", Times, serif;
		line-height: 0.8;
		opacity:0.3;
	}
	.author{
		font-weight:500;
		font-family:$headings-font-family;
		margin-top: 10px;
		font-size: 0.8em;
		color:darken($brand-gold,10%);
		
		p:first-child{
			&:before{
				content:"—";
				display:inline;

			}
		}

	}
	
	&.centered{
		padding-left: 0;
		text-align: center;
		&:before{
			position:relative;
			display:block;
			max-height: 55px;
		}
		@include media-breakpoint-up(lg) {
			padding-left: 0;
		}
	}
	
	@include media-breakpoint-up(lg) {
		font-size:	1.429em;
		padding-left: 70px;
		&:before{
			font-size: 7.571em;
		}
		.author{
			font-size: 0.8em;
		}
	}
}




/**************************************************

PAGE BLOCK ADJUSTMENTS

 **************************************************/

.b_pullquote{
	
	+ .b_pullquote{

		.pullquote{
			margin-top:0;
		}
	}
}
hr{
	&.no-line{
		border-top:none;
	}
	&.with-height{
		margin-top:0;
		margin-bottom:0;
	}
}

/**************************************************

BUTTONS

 **************************************************/
.btn-group{
	text-align: center;
	display:block;
	margin:40px 0;

	> .btn{
		float:none;
		margin:0 10px 10px;
	}

	&.btn-group-lg{
		> .btn{
			padding: 10px 26px;
		}

	}

	&.default{
		.btn{
			color:#7c6e51;
			background-color:#fff;
			padding:7px 14px 8px;
			box-sizing: border-box;
			border-bottom:1px solid #d8cfb9;

			i,svg{
				font-size:16px;
				margin-right:4px;
				color:#7c6e51;
				position:relative;
				top:1px;

			}

			&:hover{
				border-bottom:1px solid $brand-gold;

			}
		}
	}
}

.btn {
	text-decoration: none;
	text-transform: uppercase;
	font-family:$font-family-sans-cond;

	//font-family:$font-title-sans;
	//font-family:$font;
	border:none;
	border-radius:2px;
	letter-spacing: 0.5px;
	padding-left:30px;
	padding-right:30px;
	
	span{
		transition:all 0.3s ease;
		position:relative;
		display:block;
	}
	&:hover{
		span{
			transform:scale(1.05);
		}
	}

	&.btn-lg{
		padding: 10px 26px;
		i,svg{
			margin-right:10px;
		}

	}

	&.outlined{
		border:1px solid $brand-gold;

		&:hover{

		}
	}

	&.muted{
		border:none;
		background: linear-gradient(to bottom, rgba(234,226,211,1) 0%,rgba(221,207,177,1) 100%);
		color:#584d35;

		&:hover{
			background:rgba(204,190,161,1)
		}

		

	}
	
	&.dark{
		border:none;
		background: linear-gradient(to bottom, rgb(88, 77, 53) 0%, rgb(73, 63, 45) 100%);
		color:#fff;

		&:hover{
			background: rgb(101, 88, 61)
		}


	}
	
	&.black{

		font-family: $font-title-sans;
		color:#fff;
		background: linear-gradient(to bottom, rgba(49,64,70,1) 0%,rgba(30,38,43,1) 100%);

		&:hover{
			background: linear-gradient(to bottom, rgba(30,38,43,1) 0%,rgba(30,38,43,1) 100%);
		}

	}
	&.brown{
		color:#fff;
		background-color:$brand-text-brown;

		&:hover{
			background-color:darken($brand-text-brown,30%);
		}
	}
	&.white{
		color:$brand-text-brown;
		background-color:#fff;

		&:hover{
			background-color:darken(#fff,30%);
		}
	}

	&.btn-outlined{
		border:1px solid #39331E;
		color:#39331E;
		//margin-top:20px;
		transition:all 0.3s;

		&.btn-white{
			color:#fff;
			background-color:rgba(0,0,0,0.1);
			border:1px solid #fff;
		}
		&.btn-black{
			color:#000;
			background-color:rgba(0,0,0,0.1);
			border:1px solid #000;
		}
		&:hover{
			border-color:#ffe284;
			color:#ffe284;
			background-color:rgba(0,0,0,0.7);
		}
	}
}

.question{
	> span{
		font-weight: 700;
		display:block;
		margin-bottom:20px;
	}
	padding-bottom:20px;
	margin-bottom:20px;
	//border-bottom:1px solid #e4ddcd;
}

.date-marker{

	width:70px; height: 87px;
	text-align: center;


	.date{
		background: rgb(104,131,157);
		background: linear-gradient(135deg, rgba(104,131,157,1) 0%,rgba(83,105,126,1) 100%);
		color:#fff;
		font-family: $font-title-sans;
		font-size:1.571em;
		font-weight: 700;
		letter-spacing: 3.0px;
		padding:9px 0;
		line-height: 1.1;
	}

	.year{
		background-color:#52687d;
		font-size:0.857em;
		font-family: $font-title-sans;
		letter-spacing: 8px;
		line-height: 1;
		padding: 7px;
		color:#97b5d0;
	}
}

.image-with-caption{

	.caption{
		font-size:10px;
		font-family: $font-family-sans-serif;
		text-align: center;
		color:$brand-gold;
		padding-top:4px;
		display:block;
	}
}

.target-nav{
	margin-bottom: 30px;
	display:block;
	list-style: none;
	padding-left:0;

	> li{
		margin-bottom:2px;
		&:first-child{
			font-size:11px;
			color: #bfbfbf;
			font-family:$font-family-sans-serif;
			display:block;
		}
		display:inline-block;
		> a {
			display:inline-block;
			font-family:$font-family-sans-serif;
			border-radius:20px;
			background-color: #dbe3e6;
			color: #748a94;
			font-size:0.857em;
			padding: 6px 20px;
			line-height: 1;
			> i:not(:first-child), svg:not(:first-child){
				margin-left:10px;
				transition:transform 0.2s ease;
			}
			> i:first-child, svg:first-child{
				margin-right:5px;
			}
			&:active{
				background-color: #dbe3e6;
				color: #748a94;
			}
			&:hover{
				background-color:#5a7683;
				color:#fff;
				> i:not(:first-child),svg:not(:first-child){
					transform:translate(0, 2px);
				}
			}
		}
	}
	&.lg{
		> li{
			> a{
				font-size:1.000em;
			}
		}
	}

	span{
		padding:0 20px;
		color: #9a9a9a;
	}
}

.filter-nav{
	margin-bottom: 30px;

	> li{
		margin-bottom:2px;
		&:first-child{
			font-size:1.143em;
			color: #bfbfbf;
			font-family:$font-family-sans-serif;
			display:block;
		}
		display:inline-block;
		&.active{
			a{
				background-color:#829aa3;
				color:#fff;
			}
		}
		>a {
			display:inline-block;
			font-family:$font-family-sans-serif;
			border-radius:20px;
			background-color: #dbe3e6;
			color: #748a94;
			font-size:1em;
			padding: 6px 20px;
			line-height: 1;
			> i,svg{
				margin-left:10px;
				transition:transform 0.2s ease;
			}
			&:active{
				background-color: #dbe3e6;
				color: #748a94;
			}
			&:hover{
				background-color:#5a7683;
				color:#fff;
				> i,svg{
					transform:translate(0, 2px);
				}
			}
		}
	}

	span{
		padding:0 20px;
		color: #9a9a9a;
	}
}


.back-to-top{
	font-family: $font-family-sans-serif;
	display:block;
	text-align: center;
	text-transform:uppercase;
	font-size:10px;
	margin:10px 0;
	i,svg{
		margin-left:4px;
	}

	
	@include media-breakpoint-up(lg) {
		text-align: right;
		margin:20px 10px;
	}
}

.callout{
	//margin:30px 0;
	font-size:	1.0em;
	font-family:$font-family-sans-serif;
	i,svg{
		font-size: 1.343em;
		margin-right:10px;
	}
	.panel-heading{
		color: #333;
		background-color: #f5f5f5;
		border-color: #ddd;
	}
}

// This component allows for the expanding of content too small
.overflow-wrapper{
	position:relative;
	z-index:1;
	.expand-btn{
		display:none;
		position: absolute;
		right: 0;    top: 10px;
		z-index: 1;
		color: #268ce6;
		font-size: 18px;
		&.close-exp{
			right: 50px;top: 40px;
		}
	}
	> div{

	}
}

.table{
	background: white;

	th{
		&.small{
			font-size:70%;
			font-family:$font-family-sans-serif;
		}
		&.head{
			font-size:150%;
			font-family:$font-title-sans;
			font-weight: 700;
		}
	}

	tr{
		&.strongBotBorder{
			td{
				border-bottom: 3px solid #ddd;
			}
		}

	}
	td{
		&.strongBotBorder{
			border-bottom: 3px solid #ddd !important;
		}
		&.large{
			font-size:120%;
		}
	}

	h4{
		font-family: $font-family-serif;
		font-weight: 700;
		font-size:16px;
	}
	a{
		text-decoration: underline;
		&:hover{
			text-decoration: underline;
		}
	}
	&.mini-schedule{
		font-family: $font-family-sans-serif;
		.head{
			i {
				margin-right:8px;
			}
		}
		tr{
			&.date-block{
				div:first-child{
					font-size:24px;
					font-family:$font-title-sans;
					font-weight: 400;
					margin-bottom:0;
					+ div{
						margin-top:-11px;
						font-size:11px;
						color:#888;
					}
				}

			}

		}
		a{
			text-decoration: none;

		}
	}
    
    
    &.compare{
        thead{
            .head{
                background-color: #cfc8b9;
                color: black;
				font-family: $headings-font-family;
				font-weight: 500;
            }
        }
        .title{
            font-family: $headings-font-family;
            font-weight: 500;
            text-align: left;
            //background: linear-gradient(to bottom, rgba(237,241,244,1) 0%,rgba(237,241,244,0) 100%);
            a{
                text-decoration:none; 
            }
        }
    }
    &.sans{
        font-family: $font-family-sans-serif;
    }
}

.video-image-caption {
	font-size: 12px;
	font-family: $font-family-sans-serif;
	text-align: center;
	color: $brand-gold;
	margin: 5px 0 20px;

	span {
		color: darken($brand-gold, 20%);
	}
}

.panel{
	> .panel-heading{
		font-weight: 700;

	}
}

.spinner {
	width: 40px;
	height: 40px;
	//margin: 100px auto;
	//background-color: #333;
	border:3px solid #fff;

	border-radius: 100%;
	-webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
	animation: sk-scaleout 1.0s infinite ease-in-out;
}

.collageWrapper{

    background-color:white;

	margin:30px 0;
	
    position:relative;
	max-width:100%;
	overflow: hidden;
    img{
		position:static;
		overflow: hidden;
		display:block;
		width:100%; height:auto;
		margin-bottom:1px;
    }
	
	+ .image-caption{
		font-size: 12px;
		font-family: $font-family-sans-serif;
		text-align: center;
		color: $brand-gold;
		margin: -18px 0 20px;
	}
	
	@include media-breakpoint-up(md) {
		width:710px;
		height:361px;
		img{
			width:auto;
			position:absolute;
			overflow: hidden;
			margin-bottom:0;
			img{
				
				height:100%;
			}

		}

		&.collageZero{

			.zero{
				width:355px;
				height:361px;
				top:0; left:0;

			}
			.one{
				width:354px;
				height:180px;
				top:0; left:356px;
			}
			.two{
				opacity:0;
				width:355px;
				height:180px;
				top:181px; left:0;
			}
			.three{
				width:354px;
				height:180px;
				top:181px; left:356px;
			}

		}

		&.collageOne{


			.zero{
				width:355px;
				height:180px;
				top:0; left:0;
			}
			.one{
				width:354px;
				height:361px;
				top:0; left:356px;
			}
			.two{
				width:355px;
				height:180px;
				top:181px; left:0;
			}
			.three{
				opacity:0;
				width:354px;
				height:180px;
				top:181px; left:356px;
			}

		}

		&.collageTwo{

			.zero{
				width:355px;
				height:180px;
				top:0; left:0;
			}
			.one{
				width:354px;
				height:180px;
				top:0; left:356px;
			}
			.two{
				width:355px;
				height:180px;
				top:181px; left:0;
			}
			.three{
				width:354px;
				height:180px;
				top:181px; left:356px;
			}

		}

		&.collageThree{

			.zero{
				width:355px;
				height:361px;
				top:0; left:0;

			}
			.one{
				width:354px;
				height:361px;
				top:0; left:356px;
			}
			.two{
				opacity:0;
				width:355px;
				height:180px;
				top:181px; left:0;
			}
			.three{
				opacity:0;
				width:354px;
				height:180px;
				top:181px; left:356px;
			}

		}

	}



}


.login-panel{
	
	margin-top: 100px;
	
	.social-signins{

		text-align: center;
		padding: 20px 0 40px;
		
		a{
			display:inline-block;
			
			> div{
				pointer-events: none;
			}
		}
	}
	
	
}



/**************************************************

OTHER

 **************************************************/
#Popup, #SidePopup{
	//width:95%; 
	height: 100%;
	position:fixed;
	top: 0; right: 0;
	padding:50px 5px 65px 15px;
	box-sizing: border-box;
	z-index: 0;
	//transition:transform 0.3s ease;
	//transform:translate3d(calc(100% + 20px),0,0);
	backface-visibility: hidden;
	//box-shadow: 0 0 40px rgba(61,52,30,0.2);
	//background: linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
	//background-color:$brand-gold;
	background-color: #1c1814;
	display:none;
	transition:background-color 0.5s;

	&.initialized{
		display:block;
	}

	&.lighter{
		background-color:lighten($brand-gold,30%);
	}

	hr{
		border-top: 1px solid rgba(255,255,255,0.2);

	}

	.popup-content{
		color:#fff;
		max-height:100%;
		overflow-y: scroll;
		-webkit-overflow-scrolling:touch;
		h1:not(.no-bar){
			//font-family:$font-title-sans;
			//font-family:$font-family-base;
			//font-weight:500;
			&:after{
				content:"";
				display:block;
				width:50px; height: 2px;
				background-color:darken($brand-gold,10%);
				margin: 18px 0;
			}
		}
		a{
			color:$brand-gold;
			text-decoration:none
		}
		button[type=submit]{
			text-transform: none;
		}
		.btn{
			margin-top:40px;
			//border:2px solid #fff;
			color:#fff;
			background:#776d55;
			font-family: $headings-font-weight;
			font-weight: $headings-font-weight;
			&:hover{
				//border-color:#4a4535;;
				//color:#4a4535;
				background:$brand-gold
			}
		}
		&::-webkit-scrollbar {
			width: 0.5em;
		}

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		}

		&::-webkit-scrollbar-thumb {
			background-color: darkgrey;
			outline: 1px solid slategrey;
		}
		&.schedule{
			h2{
				margin-top:30px;
			}
			h3{
				font-size: 20px;
				//font-weight: 400;
				padding-top: 6px;
				border-top:1px solid rgba(255,255,255,0.1);
			}
		}
		@media (min-width: 1024px) {
			padding: 0 30px 0 0;
		}
	}
	.loadMsg{
		position:absolute;
		right:15px;
		display:none;
		div:last-child{
			color:#fff;
			font-size:10px;
			text-transform:uppercase;
			font-family:$font-family-sans-serif;
			text-align: center;
		}
	}
	.closePopupBtn{
		position:absolute;
		top:20px; right:20px;
		display:none;
		svg{
			height: 25px;
			width: 25px;
			transition:transform 0.1s ease;
			.cls-1-close-btn {
				fill: #75633d;
				fill-rule: evenodd;
			}
		}
		&:hover{

			svg{
				//transform:rotate(90deg);
				transform:scale(1.15);
				.cls-1-close-btn{
					fill:#fff;

				}
			}

		}
	}

	blockquote{
		padding-left: 1rem;
		border-left: 4px solid #eceae2;
		p{
			color:#eceae2;
		}

	}

	// 414 -> 767
	@media (min-width: 414px) {
		width:400px;
	}
	// 
	@media (min-width: 1024px) {
		width:640px;
		padding:50px 0 65px 40px;
	}
}
@-webkit-keyframes sk-scaleout {
	0% { -webkit-transform: scale(0) }
	100% {
		-webkit-transform: scale(1.0);
		opacity: 0;
	}
}

@keyframes sk-scaleout {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	} 100% {
		  -webkit-transform: scale(1.0);
		  transform: scale(1.0);
		  opacity: 0;
	  }
}


.gallery{
	margin:30px 0;

	&.one-across{
		figure{
			width:100%;
			float:none;
		}
	}
	
	&.two-across{
		figure{
			width:calc(50% - 1px);
		}
	}
	
	&.three-across{
		figure{
			width:calc(33.33333% - 1px);
		}
	}

	&.four-across{
		figure{
			width:calc(25% - 1px);
		}
	}

	&.five-across{
		figure{
			width:calc(20% - 1px);
		}
	}

	&.six-across{
		figure{
			width:calc(16.667% - 1px);
		}
	}

	figure{
		float:left;
		position:relative;
		margin-bottom:1px;
		&:not(:last-child){
			margin-right:1px;
		}
		i,svg{
			position:absolute;
			bottom:5px; right:5px;
			font-size:18px;
			color:#fff;
			transition:transform 0.3s ease;
			backface-visibility: hidden;
			transform: translateZ(0);
		}
		&:hover{
			i,svg{
				transform:scale3d(1.2,1.2,1) translateZ(0);
				transform-origin: bottom right;
			}
		}
	}
	figcaption{
		display:none;
	}

	+ .video-image-caption{
		margin-top:-15px;
	}
}

.pswp__caption__center{
	text-align: center;
	font-family:$font-title-sans;
	font-size:18px;
	letter-spacing:0.5px;
	color:rgba(255,255,255,0.8);
}
.pswp__counter, .pswp__button{
	color:rgba(255,255,255,0.8);
}

.youtube-player {
	position: relative;
	padding-bottom: 56.23%;
	/* Use 75% for 4:3 videos */
	height: 0;
	overflow: hidden;
	max-width: 100%;
	background: #fff;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: transparent;
	}

	img {
		bottom: 0;
		display: block;
		left: -2px;
		margin: auto;
		max-width: calc(100% + 4px);
		width: calc(100% + 4px);;
		position: absolute;
		right: -2px;
		top: 0;
		border: none;
		height: auto;
		cursor: pointer;

		transition: .4s all;



	}
	&:hover{
		img{
			-webkit-filter: brightness(75%);

		}
		.play{
			svg{
				.ytp-large-play-button-bg{
					transition: fill .1s cubic-bezier(0.0,0.0,0.2,1),fill-opacity .1s cubic-bezier(0.0,0.0,0.2,1);
					fill: #cc181e;
					fill-opacity: 1;
				}
			}
		}
	}

	.play {
		height: 30px;
		width: 42px;
		left: 50%;
		top: 50%;
		margin-left: -21px;
		margin-top: -15px;
		position: absolute;
		//background: url("/storage/static/global/youtube-play-arrow.svg") no-repeat;
		cursor: pointer;

		svg{

		}
	}
}


.social-share{
	.addthis_inline_share_toolbox{
		margin-left: -2px;
		float:left;
		width:64%;
	}
	.sharePage{
		float:right;
		width:36%;
		font-family:$font-family-sans-serif;

		//border: 1px solid #d4d4d4;
		border-radius: 3px;
		padding: 7px 10px 8px;
		background-color: #40525d;
		//float: left;
		color: #fff;
		display: block;
		text-align: center;
		&:hover, &:active{
			background-color:lighten(#40525d,10%);
			color: #fff;
			div{
				color:#fff;
			}
		}
		i,svg{
			font-size:13px;
			display:block;

		}
		div{
			font-size:10px;
			color:rgba(255,255,255,0.6);
		}


	}
}

.packing-list{
	h3{
		font-size:2em;

	}
	ul{
		margin-bottom:30px;
		padding-bottom: 20px;
		border-bottom:1px solid #e4ddcd;
	}
	li{
		font-size:1.143em;
	}
}

.amenities-module{
	.tools{
		margin:20px 0;
		a{
			font-family:$font-family-sans-serif;
			font-size:	0.857em;
			display: inline-block;
			&:first-child{


				&.collapsed{
					i,svg{
						transform:rotate(90deg);
					}

				}
				i,svg{
					font-size:0.818em;
					margin-left:4px;
					transform:rotate(0);
					transition:transform 0.3s ease;
				}
			}
			&:last-child{
				float:right;
				i,svg{
					font-size:1.091em;
				}
			}
		}
	}

	ul.amenities{
		margin:20px 0;
		padding:0;
		list-style:none;

		.label{
			display:block;
			text-align: left;
			font-family:$font-family-sans-serif;
			font-size:0.786em;
			font-weight: 400;
			line-height: 1;
			border-radius:20px;
			background-color: #e5e2da;
			color: #948a73;
			padding: 6px 16px;
			margin-bottom:4px;
			border-bottom: 1px solid #cec9bd;
			white-space: normal;
		}
	}
}

#silentbox-overlay{

}
#silentbox-overlay__close-button{
	.icon{
		width: 3rem !important;
		left: -1.3rem !important;
	}
}
#silentbox-overlay{
	z-index:10000 !important;
}

/*// 320 -> 413
@media (min-width: 320px) {

}
// 414 -> 767
@media (min-width: 414px) {

}*/

// 768 -> 991
@include media-breakpoint-up(md) {
//@media (min-width: $screen-sm-min) {

	.slides{
		li {
			.caption-sm {
				position:absolute;
				bottom:10px; right:10px;
				text-align: right;
				font-size: 1.429em;
				padding: 10px;
			}
		}
	}
	

	.packing-list{
		h3{
			font-size:2em;
		}
	}

	.amenities-module{

		ul.amenities{

			.label{
				display:inline-block;
			}
		}
	}


}

// 992px -> 1200px
@include media-breakpoint-up(lg) {
//@media (min-width: $screen-md-min) {

	.left-image{
		float:left;
		margin:10px 40px 15px 0;

	}

	.overflow-wrapper{
		.expand-btn{
			&.open-exp{
				display:block;
			}
			&.close-exp{
				display:none;
			}
		}
		&.expanded{
			margin-left: -30px;
			margin-top: -30px;
			padding-bottom: calc(100% + 370px);
			.expand-btn{
				&.open-exp{
					display:none;
				}
				&.close-exp{
					display:block;
				}
			}
			> div{
				position:absolute;
				width: 1110px;
				background: rgba(255,255,255,0.5);
				padding: 30px;
				box-shadow: 0 0 20px rgba(0,0,0,0.1);

				> div{
					background: white;
					table{
						margin-bottom:0;
					}

				}
			}
			table{
				width:100%; max-width:100%;
			}
		}
		table{
			width:130%; max-width:130%;
		}


	}
}

//1200px +
@include media-breakpoint-up(xl) {
//@media (min-width: $screen-lg-min) {

	.related{
		.bin{
			font-size:11px;
		}
	}


}

///* position dots in carousel */
//.flickity-page-dots {
//	bottom: 0px;
//}
//
///* white circles */
//.flickity-page-dots .dot {
//	width: 12px;
//	height: 12px;
//	opacity: 1;
//	background: transparent;
//	border: 2px solid white;
//}
//
///* fill-in selected dot */
//.flickity-page-dots .dot.is-selected {
//	background: white;
//}
//.flickity-button{
//	background:none !important; 
//	border:2px solid white !important;
//	
//	&:hover{
//		background:white !important;
//		border:2px solid white !important;
//		color:#000 !important;
//	}
//}
//.flickity-prev-next-button{
//	color:white !important;
//}
//.flickity-viewport{
//	padding-top:30px;
//}


.modal-gallery{
	.slick-prev:before, .slick-next:before{
		color:rgba(white,0.6);
	}

	.slick-prev, .slick-next{
		&:hover{
			&:before{
				color:rgba(white,1);
			}

		}
	}
	
	.slick-slide{
		img{
			max-width: 90%;
			margin:0 auto;
		}
	}
	
	button.browse{
		position:absolute;
		top:50%;
		transform:translateY(-50%);
		padding:1rem;
		background:rgba(black,0.5);
		svg{
			color:white;
			font-size:3rem;
		}
		
		&.next{
			right: 5px;
		}
		&.prev{
			left: 5px;
		}
	}
	
	.slick-prev{
		left: -75px;
	}
	.slick-next{
		right: -75px;
	}
	.slick-next:before{
		content:'\f0a9';
		font-weight: 300;
	}
	.slick-prev:before{
		content:'\f0a8';
		font-weight: 300;
	}
}
