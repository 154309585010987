.super-gallery{
    
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 0 60px;
    
    &.default{
        background-color: #1e1c15;
        color: #dad2be;
    }
    
    &.super-gallery-mini-mode{
        margin: 15px 0;
        padding: 15px 0;
        section{
            padding-left: 15px;
            padding-right: 15px;
        }
        
        .titleblock,.caption{
            display:none;
        }
        .grid-row{
            figure{
                
                a{
                    display:block;
                    background:$brand-brown;
                }
                .fa-search-plus{
                    transform:scale3d(1.0,1.0,1);
                    font-size:1.875em;
                    transition:opacity 0.2s, transform 0.2s ease;
                    opacity:0.8;
                    left:auto; top:auto;
                    right: 1px;
                    bottom: 6px;
                }

                &:hover{

                    .fa-search-plus{
                        opacity:1;
                        transform:scale3d(1.1,1.1,1);
                    }
                }
            }
        }
    }
    
    .grid-row{
        
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 2px;
        grid-row-gap: 2px;
        margin-bottom:0;

        @include media-breakpoint-up(lg) {
           // margin-bottom: 10px;
            grid-column-gap:10px;
            grid-row-gap:10px;
            
            + .grid-row{
                margin-top:10px;
            }
            
        }

        
        figure{
            position:relative;
            margin: 0;
            
            a{
                display:block;
                background:$brand-brown;
            }
            &:hover{
                img{
                    opacity:0.7;
                }
                .fa-search-plus{
                    opacity:1;
                }
            }
        }
        figcaption{
            display:none;
        }
        img{
            transition:opacity 0.3s;
        }
        .fa-search-plus{
            position:absolute;
            top:50%; left:50%;
            transform:translate(-50%,-50%);
            font-size:2em;
            opacity:0.0;
            transition:opacity 0.2s;
            pointer-events: none;
            color:white;
        }

        
        &.typeA{
            figure:nth-child(1){
                grid-column: 1 / span 2;
                grid-row: 1 / span 2;
            }
            figure:nth-child(2){
                grid-column: 3 ;
                grid-row: 1 ;
            }
            figure:nth-child(3){
                grid-column: 4 ;
                grid-row: 1 ;
            }
            figure:nth-child(4){
                grid-column: 3 ;
                grid-row: 2 ;
            }
            figure:nth-child(5){
                grid-column: 4 ;
                grid-row: 2 ;
            }
        }
        &.typeB{
            figure:nth-child(1){
                grid-column: 1 ;
                grid-row: 1 ;
            }
            figure:nth-child(2){
                grid-column: 2 / span 2;
                grid-row: 1 / span 2;
            }
            figure:nth-child(3){
                grid-column: 4 ;
                grid-row: 1 ;
            }
            figure:nth-child(4){
                grid-column: 1 ;
                grid-row: 2 ;
            }
            figure:nth-child(5){
                grid-column: 4 ;
                grid-row: 2 ;
            }
        }
        &.typeC{
            figure:nth-child(1){
                grid-column: 1 ;
                grid-row: 1 ;
            }
            figure:nth-child(2){
                grid-column: 2 ;
                grid-row: 1 ;
            }
            figure:nth-child(3){
                grid-column: 3 / span 2;
                grid-row: 1 / span 2;
            }
            figure:nth-child(4){
                grid-column: 1 ;
                grid-row: 2 ;
            }
            figure:nth-child(55){
                grid-column: 2 ;
                grid-row: 2 ;
            }
        }
        &.typeD{
            figure:nth-child(1){
                grid-column: 1 ;
                grid-row: 1 ;
            }
            figure:nth-child(2){
                grid-column: 2 ;
                grid-row: 1 ;
            }
            figure:nth-child(3){
                grid-column: 3 ;
                grid-row: 1 ;
            }
            figure:nth-child(4){
                grid-column: 4 ;
                grid-row: 1 ;
            }
        }
        &.typeE{
            figure:nth-child(1){
                grid-column: 1 / span 2;
                grid-row: 1 / span 2 ;
            }
            figure:nth-child(2){
                grid-column: 3 / span 2;
                grid-row: 1 / span 2;
            }
        }
        &.typeF{
            figure:nth-child(1){
                grid-column: 1 ;
                grid-row: 1 / span 2 ;
            }
            figure:nth-child(2){
                grid-column: 2 / span 2;
                grid-row: 1 / span 2;
            }
            figure:nth-child(3){
                grid-column: 4 ;
                grid-row: 1 / span 2 ;
            }
        }
        &.typeG{
            figure:nth-child(1){
                grid-column: 1 ;
                grid-row: 1 / span 2 ;
            }
            figure:nth-child(2){
                grid-column: 2;
                grid-row: 1 / span 2;
            }
            figure:nth-child(3){
                grid-column: 3 ;
                grid-row: 1 / span 2 ;
            }
            figure:nth-child(3){
                grid-column: 4 ;
                grid-row: 1 / span 2 ;
            }
        }
        &.typeH{
            figure:nth-child(1){
                grid-column: 1 / span 2;
                grid-row: 1 / span 2;
            }
            figure:nth-child(2){
                grid-column: 3;
                grid-row: 1 / span 2;
            }
            figure:nth-child(3){
                grid-column: 4 ;
                grid-row: 1 / span 2 ;
            }

        }
        &.typeI{
            figure:nth-child(1){
                grid-column: 1 ;
                grid-row: 1 / span 2 ;
            }
            figure:nth-child(2){
                grid-column: 2;
                grid-row: 1 / span 2;
            }
            figure:nth-child(3){
                grid-column: 3 / span 2;
                grid-row: 1 / span 2;
            }

        }
        &.typeJ{

            grid-template-columns: 1fr 1fr 1fr;
            
            figure:nth-child(1){
                grid-column: 1 ;
                grid-row: 1 ;
            }
            figure:nth-child(2){
                grid-column: 2 ;
                grid-row: 1 ;
            }
            figure:nth-child(3){
                grid-column: 3 ;
                grid-row: 1 ;
            }
        }
    }
    
    /*.titleblock{

        //margin: 0 0 20px;
        
        h2{
            
            + h3{
                !*font-weight: 400;
                &:after{
                    content:none;
                }*!
            }
        }
        
        @include media-breakpoint-up(md) {
           // margin: 0 0 20px; 
        }
    }*/
    
    .notes{
        padding:30px 30px 0;
        font-size:12px;
        @include media-breakpoint-up(xl) {
            padding:30px 90px 0;
        }
    }
    
    .caption{
        color:$brand-gold;
        text-align: center;
        p{
            margin-bottom: 0;
        }
    }
    
}