#Home{
    
    #SalmonLake {
        border-top:10px solid white;
        background-size: cover;
        background-position: top center;
        text-align: center;
        position: relative;
        padding:400px 0 30px;
        z-index: 0;
        .meta{
            color:#fff;
            text-align: left;
            h2{
                font-size:2.786em; //39px
                color:#fff;
                //font-family:$font-title-sans;
                //font-weight:700;
                margin:5px 0 15px;
            }

        }
    }

    // 768 -> 991
    @include media-breakpoint-up(md) {
    //@media (min-width: $screen-sm-min) {
        
        #SalmonLake {
            //background: white url('/storage/static/home/salmon-lake-bg.jpg') no-repeat bottom center;
            background-size: cover;
            background-position: top center;
            padding:380px 0 40px;

            .meta{
                padding-top: 160px;
            }
        }

    }

    // 992px -> 1200px
    @include media-breakpoint-up(lg) {
    //@media (min-width: $screen-md-min) {

        #SalmonLake {
            padding:380px 0 40px;

            .meta{
                padding-top: 80px;
            }
        }

    }

    //1200px +
    @include media-breakpoint-up(xl) {
    //@media (min-width: $screen-lg-min) {

        #SalmonLake {
            padding:380px 0 40px;

            .meta{
                padding-top: 160px;
            }
        }

    }

    //1601px +
    @media (min-width: 1601px) {

        #SalmonLake {
            padding:470px 0 100px;

            .meta{
                padding-top: 200px;
            }
        }

    }
}