#ActivityFilter{
    
    display:flex;
    flex-direction: column;
    justify-content: center;
    padding-top:15px;
    .form-group{
        margin:0 5px 5px;
        &.reset{
            margin-top:15px;
            display: flex;
            justify-content: flex-end;
            label{
                display:none;
            }
        }
        @include media-breakpoint-up(lg) {
            margin:0 10px;

            &.reset{
                display: block;
                margin-left:20px;
                margin-top:0;
                label{
                    display:block;
                }
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        padding-top:30px;
    }
    
    label{
        color:#958b6b;
        margin-bottom:5px;
        font-weight: 400;
        @include media-breakpoint-up(lg) {
            margin-bottom:15px; 
        }
    }
    select{
        border:none;
        font-family:$headings-font-family;
        font-weight: $headings-font-weight;
        font-size:1.286em;
        color:black;
        height: 50px;
        padding: 10px 18px;
        @include media-breakpoint-up(lg) {
            height: 72px;
        }
    }
    
    button{
        display:block;
        background-color:transparent;
        border:2px solid #e4dfd1;
        font-family:$headings-font-family;
        font-weight: $headings-font-weight;
        font-size:1.286em;
        color: #6b6152;
        height: 50px;
        padding: 0 32px;
        text-transform: none;
        
        &:hover{
            color:black;
            border-color:$brand-gold;
        }

        @include media-breakpoint-up(lg) {
            height: 72px;
            padding: 0 12px;
        }
    }
    
}

#ActivityResults{
    min-height:500px;
    padding-bottom:20px;
    @include media-breakpoint-up(lg) {
        padding-bottom:50px; 
    }
    .results{
        display:flex;
        flex-wrap:wrap;
        justify-content: center;
        padding-top: 40px;

    }
    
    .empty{
        text-align: center;
        margin:40px auto;
        font-size:2em;
        max-width: 640px;
        i,svg{
            font-size: 2em;
            display:block;
            color: rgba(#a2916a,0.5);
        }
    }

}