.new-design-2019{
    
    .rate-table{
        
        tr{
            
            > th, > td{
                
                padding:15px;
                
            }
            
        }
        
    }
    
}