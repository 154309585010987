#Groups2{
    
    
    .accommodations{
        //background-color:$brand-gray-bg;
        padding-top:60px;
        padding-bottom:60px;
        border-bottom: 1px solid white;
        
        
    }
    
    .accomodation{
        
        display:flex;
        
        .shots{

            display: flex;
            
            .grid{
                display:grid;
                grid-template-columns: 1fr 1fr ;
                grid-template-rows: 1fr;
                grid-column-gap:10px;
                grid-row-gap:10px;
                
                img:nth-child(1){
                    grid-column: 1 ;
                    grid-row: 1 ;
                }
                img:nth-child(2){
                    grid-column: 2 ;
                    grid-row: 1 ;
                }
                img:nth-child(3){
                    grid-column: 1 / span 2;
                }

                @include media-breakpoint-up(md) {
                    grid-template-columns: 20vmin 20vmin auto;
                }

                @include media-breakpoint-up(lg) {

                }
                
                @include media-breakpoint-up(xl) {
                    grid-template-columns: 33vmin 33vmin auto;

                }
            }
            

            margin-bottom:20px;

            @include media-breakpoint-up(md) {
                margin-bottom:50px;
            }


            //max-width:850px;

            
            .meta{
                grid-column: 1 / span 2 ;
                grid-row: 3 / span 2;
                background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0.25) 100%);
                padding:15px;
                display:flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                //margin-left: 10px;
                h3{
                    font-size: 	1.286em;
                    margin:0;
                }
                h5{
                    font-size: 0.857em;
                }
                p{
                    font-size:0.857em;
                    line-height: 1.1;
                    margin-bottom:10px;
                }
                .btn{
                    padding: 6px 22px;
                }
                @include media-breakpoint-up(md) {
                    grid-column: 3 ;
                    grid-row: 1 / span 2;
                }

                @include media-breakpoint-up(lg) {
                    padding:20px;
                    h3{
                        font-size: 1.571em;
                    }
                    h5{
                        font-size: 0.857em;
                    }
                    p{
                        font-size:1em;
                        margin-bottom:15px;
                    }
                }

                @include media-breakpoint-up(xl) {
                    padding:30px;
                    h3{
                        font-size:1.750em;
                    }
                    h5{
                        font-size:1em;
                    }
                    p{
                        font-size:1.143em;
                        line-height: 1.2;
                        margin-bottom:20px;
                    }

                }
            }

        }

        &:nth-child(2n){
            
            .shots{
                flex-direction: row-reverse;
            }
            
            .grid{
                grid-template-columns: 1fr 1fr ;

                img:nth-child(1){
                    grid-column: 1 ;
                    grid-row: 1 ;
                }
                img:nth-child(2){
                    grid-column: 2 ;
                    grid-row: 1 ;
                }
                img:nth-child(3){
                    grid-column: 1 / span 2;
                }
                @include media-breakpoint-up(md) {
                    grid-template-columns: auto 20vmin 20vmin ;
                    img:nth-child(1){
                        grid-column: 2 ;
                        grid-row: 1 ;
                    }
                    img:nth-child(2){
                        grid-column: 3 ;
                        grid-row: 1 ;
                    }
                    img:nth-child(3){
                        grid-column: 2 / span 2;
                    }
                }

                @include media-breakpoint-up(lg) {

                }

                @include media-breakpoint-up(xl) {
                    grid-template-columns: auto 33vmin 33vmin ;

                }
            }

            .meta{
                grid-column: 1 / span 2 ;
                grid-row: 3 / span 2;
                background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0.25) 100%);
                @include media-breakpoint-up(md) {
                    grid-column: 1;
                    grid-row: 1 / span 2;
                }

                @include media-breakpoint-up(lg) {

                }

                @include media-breakpoint-up(xl) {
                    
                }
            }
        }


    }
}