.image-block{
    
    background-color: white;
    text-align: center;
    &.block{
        
        .center-wrapper{
            margin:30px auto; 
        }
    }


    &.no-center{
        .center-wrapper{
            margin:10px 0 30px;
        }
        
    }

    img{
        margin-bottom:5px;

       
        
    }
    
    .image-caption{
        display:flex;
        align-items: center;
        justify-content: center;
        
        i,svg{
            margin-right:6px;
        }
        
        p{
            margin-bottom:0;
        }
    }

}