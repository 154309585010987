.category-template{

    // this is used on event overview page 
    .modern-page-nav nav.subnav{
        margin-top:0;
        /*padding-top: 10px;
        margin: 10px -15px 0;

        @include media-breakpoint-up(lg) {
            padding-top: 70px;
            margin: 10px 0 0;
            
        }*/
    }
    
    .expandable {
        
        
        @include media-breakpoint-up(lg) {
            padding:60px 0 0;


            .expandable{
                padding:0 0 0;
            }

        }
        
        .btn.plus{
            span{
                font-size: 24px;
                @include media-breakpoint-up(xl) {
                    font-size: 32px;
                }
            }
        }
    }
    
    section{
        max-width: none;
        //background-color: $brand-gray-bg;
        //background-size: contain;
        //background-repeat: repeat;
        

        @include media-breakpoint-up(md) {
            padding-left:15px;
            padding-right:15px;
            overflow: hidden;
            //background-color: transparent;
        }
        @media (min-width: 1600px) {
            padding-left: 0;
            padding-right: 0;
        }
        /*.past-events{
            background-color:transparent;
            margin-bottom:0;
            margin-left:0;
            margin-right:0;
        }*/
        
        .bin{
            //box-shadow: 0 4px 5px rgba(163,157,133,0.09);
        }
        
    }
    
    .intro{

        margin:30px 0;

        h1{
            font-size:2.0em;
            text-align: center;
            @include media-breakpoint-up(md) {
                font-size:2.25em;
            }
            @include media-breakpoint-up(lg) {
                font-size:2.857em;
                margin-bottom:30px;
            }
        }
        img{
            display:block;
            width:34px; height:37px;
            margin: 10px auto;
        }
        p{
            font-size:	1.0em;
            letter-spacing: 0.8px;
            @include media-breakpoint-up(md) {
                font-size: 1.071em;
            }
        }

    }

    main{
        h2{
            font-size:3.286em;
            text-align:center;
            letter-spacing: 1px;
            color:#444a4f;
        }
    }
    .back-to-top{
        margin:20px 0;
        padding:0 10px;
        text-align: center;
        z-index: 1;
        position: relative;
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
    }
    
/*    > section {
        padding-top:30px;
        padding-bottom:50px;

        > div {
            display:flex;
            justify-content: center;
            align-content: flex-start;
            flex-wrap:wrap;
            margin:0 -15px;
        }
    }*/
    
    .event-bin{

        //margin: 0 2.5px 10px;

        &:hover{
            h3,p{
                color:inherit;
            }
        }
        @include media-breakpoint-up(md) {
            margin: 0 5px 10px;
        }
        @include media-breakpoint-up(lg) {

            .imgWrap{
                //width:320px;
                img{
                    width:320px; 
                    //height:320px;
                }
            }
  
        }

    }
    
    .wrap-section.gray{
        background-color:transparent;
    }
    $pastEVentBinMobileThumbWidth:120px;
    .past-events{
        
        margin-bottom: 0;
        padding-bottom:15px;
        
        @include media-breakpoint-up(lg) {
            margin-left:-15px;
            margin-right:-15px;
            padding-bottom:60px;
        }

        .event-bin{

            //margin: 0 2.5px 10px;

            .imgWrap{

                @include media-breakpoint-up(lg) {
                    width:$pastEVentBinMobileThumbWidth;
                    display:block;
                    img{
                        width:$pastEVentBinMobileThumbWidth;
                        height:$pastEVentBinMobileThumbWidth;
                    }
                }
            }

            
            
            @include media-breakpoint-up(md) {
                //margin: 0 5px 10px;
            }
            @include media-breakpoint-up(lg) {

               
            }

        }
    }

    
    // TODO: this is pulled from the old category page scss. If activity pages and the .content div are gone, we can remove this:
    .content{
        position: relative;
        background-color:$body-bg;
        .cat-icon{
            text-align: center;
            color:$brand-gold;
            font-size:2.571em;

            + h2{
                margin-top:2px;
            }
        }
    }
}