.hero-slider{
    display:flex;
    flex-direction: column;
    //padding-top:55px;
    padding-left:8px; padding-right:8px;
    background-color:#fff;
    margin-bottom: 8px;

    // 576 -> 767
    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-top:0;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom:0;
    }

    .featured{
        margin-bottom:10px;

        @include media-breakpoint-up(md) {
            width:67%;
            margin-bottom:0;
            padding-top: 33%;
            position: relative;
            height:0;
            overflow: hidden;
            .slides{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

        }

        .meta{
            
            
            
            
            
            
            //font-family: $font-title-sans;
            font-size:8px;
            color:#2a363c;

            &.with-right-logo{
                @include media-breakpoint-up(md) {
                    padding-right:100px;
                }
                @include media-breakpoint-up(lg) {
                    padding-right:150px;
                }
            }

            .title{
                font-size: 1.0rem;
                @include media-breakpoint-up(md) {
                    font-size:0.875rem;
                }
                @include media-breakpoint-up(lg) {
                    font-size:1.0rem;
                }
                @include media-breakpoint-up(xl) {
                    font-size:2.0em; 
                }
                &.medium{

                    @include media-breakpoint-up(md) {
                        font-size:1.9em;
                        line-height: 1.0;
                    }
                }

                &.larger{
                    font-size:3.00em; //42px
                    @include media-breakpoint-up(md) {
                        font-size:2.286em;
                    }
                }
                &.smaller{
                    font-size:2.143em; //30px
                    @include media-breakpoint-up(md) {
                        font-size:1.714em;
                        line-height: 1.0;
                    }
                }
                &.smaller-mobile{
                    font-size:2.143em;
                    @include media-breakpoint-up(md) {
                        font-size:3.000em;
                    }
                }
                &.m-smaller{
                    font-size:1.8em; //30px
                    @include media-breakpoint-up(md) {

                    }
                }
            }

            .sub-title{
                //font-family: $headings-font-family;

                font-size:1.125em; // 1.5em;
                line-height: 1;
                margin-top:8px;

                @include media-breakpoint-up(md) {
                    font-size:1.125em;
                    line-height: 1;
                    margin-top:5px;
                }
            }

            .range{
                font-size:1.5em;
                line-height: 1;

                @include media-breakpoint-up(md) {
                    font-size:1.286em;
                    line-height: 1;
                }
            }

            .btn.btn-mobile-dark{
                border: 1px solid #39331E;
                color: #39331E;
                background:none;
            }

            .logo{
                position: absolute;
                bottom:100px;
                width:90px;
                height:auto;
                &.right{
                    right:10px;
                }
                @include media-breakpoint-up(md) {
                    width:90px;
                    bottom:20px;
                }
                @include media-breakpoint-up(lg) {
                    bottom:20px;
                    width:140px;
                    height:auto;
                    &.right{
                        right:20px;
                    }
                }
            }

            @include media-breakpoint-up(md) {


                &.black-medium{
                    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
                }
                &.white{
                    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                    color:#2a363c;

                }
                &.blue{
                    background: linear-gradient(to bottom, rgba(#21799e,0) 0%,rgba(#21799e,1) 100%);
                }
                &.clear{
                    background:none;
                }
                
            }


        }

    }

    .sub-featured{

        .meta{
            position:static;
            font-family: $font-title-sans;
            font-size:12px;
            font-weight:$headings-font-weight;
            line-height:1;
            padding-top:1em;
            .title{
                font-size:1.5em;
                @include media-breakpoint-up(sm) {
                    font-size:1.0em;
                }
                @include media-breakpoint-up(md) {
                    color:#fff;

                }
                @include media-breakpoint-up(lg) {
                    font-size:1.5em;
                }
            }
            .btn{
                margin-top:15px;

                &:hover{
                    color:#fff;
                    background-color:$brand-gold;
                }

                i,svg{
                    margin-left:5px;
                }
            }
            @include media-breakpoint-up(md) {
                position:absolute;
                font-size:14px;
                padding-top:8em;
            }
        }

        /*
        .btn{

            @include media-breakpoint-up(md) {
                margin-top:20px;
            }
        }
        */


        /*            .top{
                        margin-bottom:2px;
                        position:relative;
                        
                        .slide{
                            position: relative;
                        }
        
        
                        @include media-breakpoint-up(md) {
                            margin-bottom:-5px;
                        }
                    }*/

        /*.bottom{
            margin-bottom: 50px;

            .meta{
                .btn{
                    margin-top:0;
                    @include media-breakpoint-up(md) {
                        margin-top:20px;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }*/

        @include media-breakpoint-up(md) {
            // width:33%;
            width: calc(33% - 8px);
            padding-top: 33%;
            position: relative;
            height:0;
            overflow: hidden;

            .slides{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            //border-left: 8px solid white;
        }
    }

    .slick-dots{
        bottom: 114px;
        li{

            margin: 0 3px;

            button{
                &:before{
                    text-shadow:none;
                    color: #fff;
                    @include media-breakpoint-up(md) {
                        color:#fff;
                    }
                }
            }

        }
        @include media-breakpoint-up(md) {
            bottom: 14px;
        }
    }

    .slide, .slick-slide{

        position: relative;
        > a{
            position: relative;
            display: block;
        }

        &.slick-current{
            .meta{
                transform: translate3d(0,0,0);
                opacity:1;
            }
        }

        
        .meta{
            position:static;
            padding: 2em 0.1em 0.5em;
            transform: translate3d(0,20px,0);
            transition:opacity 1s linear, transform 1.5s ease-out;
            opacity:0;
            text-align: center;
            
            @include media-breakpoint-up(md) {
                position:absolute;
                left: 0; bottom:0;
                padding: 7em 2em 3em ;
                width: 100%;
                font-size:12px;
                color:#fff;
                text-align: left;
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
                &.black-light{
                    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
                }
                
                .btn-white.btn-mobile-dark{
                    border-color:inherit;
                    color:inherit;
                    &:hover{
                        border-color:#ffe284;
                        color:#ffe284;
                        background-color:rgba(0,0,0,0.7);
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 7em 2em 3em ;
                font-size:14px;
            }

            .title{
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                line-height: 1;
               
            }
        }



        @include media-breakpoint-up(lg) {
            //padding:0;
        }
    }

    
}

.hero-slider-slide-chooser{
    height:50px;
    display:none;
    background-color:$body-bg;
    position: relative;
    padding:0 8px;
    top: -7px;
    //opacity:0;
    transition:opacity 0.5s;
    &.enable{
        opacity:1;
    }

    > a {
        display:block;
        flex-grow:1;
        text-align: center;
        font-size: 0.6875rem;
        color:$brand-text-brown;
        padding:10px 0;
        border-top:4px solid rgba(0,0,0,0);
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        transition:all 0.5s ease;
        background-color:$body-bg;

        @include media-breakpoint-up(lg) {
            font-size: 0.8125rem;
        }
        &.active{
            border-color:$brand-gold;
            background:lighten($body-bg,10%);
        }
    }

    @include media-breakpoint-up(lg) {
        display:flex;
    }
}