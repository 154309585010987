#Groups2 {


    .catering-gallery {
        //background-color: $brand-gray-bg;
        
        padding-top:60px;
        padding-bottom:60px;
        
        
        .grid{
            display:grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            grid-column-gap:10px;
            grid-row-gap:10px;
            margin:0 -10px;
            
            div{
                //margin:5px;
                //width:calc(33% - 10px);
            }
        }
    }

}